import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      common: {
        buyNow: "Buy Now",
        contactUs: "Contact Us",
        loading: "loading...",
        checkout: "Checkout",
        products: "products"
      },
      header: {
        home: "Home",
        about: "About Us",
        contact: "Contact Us",
        reseller: "Become a reseller",
      },
      homePage: {
        seo: {
          pageTitle: "Welcome to Vitadote®",
          metaTitle: "Discover Vitadote® Shilajit Resin – vitadote",
          metaDescription:
            "Pure power from Altai and Himalaya. Rich in minerals, a natural supplement for your healthy lifestyle.",
        },
        hero: {
          accentHeading:
            "Experience the Purity of the Mountains with Vitadote<sup>®</sup> Shilajit Resin",
          mainHeading:
            "Discover the Power of Nature with Vitadote<sup>®</sup> Shilajit Resin",
          details:
            "Explore the Primal Elements of Nature with Vitadote<sup>®</sup> Shilajit Resin. Your connection to authentic Shilajit, carefully sourced from the majestic rock formations of the Altai and Himalayan mountains. Our Shilajit naturally contains minerals such as iron, calcium, magnesium, selenium, and manganese, as well as fulvic acid, and is processed with respect for nature and ancient traditions.",
        },
        nutrition: {
          heading: "The Nutritional Value of Shilajit",
          accentHeading:
            "Contribution to the RDA from 3 servings of 0.3 grams each",
          details:
            "Vitadote<sup>®</sup> Shilajit Resin also has a natural composition of minerals. With a careful selection of our Shilajit, we ensure you enjoy, among other benefits:",
          cards: {
            iron: {
              title: "Iron",
              description:
                "Contributes to the normal formation of red blood cells and haemoglobin. Our Shilajit provides 30.87% of the Recommended Daily Allowance (RDA) of iron per daily dosage of 0.9 grams.",
            },
            calcium: {
              title: "Calcium",
              description:
                "Important for normal muscle function and plays a role in the process of cell division and specialization. Our Shilajit Resin contains 72.06% of the RDA of calcium per daily dosage of 0.9 grams.",
            },
            magnesium: {
              title: "Magnesium",
              description:
                "Important for the maintenance of normal bones and teeth, and contributes to normal protein synthesis. Our product provides 108.24% of the RDA of magnesium per daily dosage of 0.9 grams.",
            },
            selenium: {
              title: "Selenium",
              description:
                "Contributes to the normal functioning of the immune system and the protection of cells from oxidative stress. With our Shilajit Resin, you receive 22.92% of the RDA of selenium per daily dosage of 0.9 grams.",
            },
            manganese: {
              title: "Manganese",
              description:
                "Contributes to the normal formation of connective tissue and the protection of cells from oxidative stress. Our Shilajit Resin contains 33.75% of the RDA of manganese per daily dosage of 0.9 grams.",
            },
          },
        },
        whatisShilajit: {
          heading: "DISCOVER THE ORIGIN",
          paragraph1:
            "Let Vitadote<sup>®</sup> Shilajit Resin be a supplement to your good routine. A good routine consists of a healthy diet and exercise pattern. Vitadote<sup>®</sup> Shilajit can supplement this, directly from the pristine nature, connecting you to a tradition that has stood the test of time.",
          paragraph2: {
            bold: "Shilajit as a Supplement to Your Diet",
            text: "Vitadote<sup>®</sup> aims to complement your dietary choices with Shilajit, a traditionally valued natural product. Consult a doctor if in doubt and always adhere to the advice on the packaging.",
          },
          paragraph3: {
            bold: "Fulvic Acid in Vitadote<sup>®</sup> Shilajit Resin",
            text1:
              "Vitadote<sup>®</sup> Shilajit Resin primarily contains fulvic acid, in addition to humic acid and minerals.",
            text2:
              "for specific information about the amount of fulvic acid, humic acid, and minerals in our product or visit our ",
            productPage: "product page",
          },
        },
        instructions: {
          heading: "INSTRUCTIONS FOR USE",
          details:
            "We advise taking a small amount of 0.3 grams of Vitadote<sup>®</sup> Shilajit resin three times daily. Dissolve it directly under the tongue or in a glass of non-chlorinated water for optimal absorption.",
          step1: "Use the spatula to take the recommended dosage from the jar.",
          step2:
            "Dissolve in non-chlorinated water, herbal tea, or milk that is warm (not exceeding 39°C), and drink. Alternatively, it may also be dissolved under the tongue.",
          step3:
            "To achieve optimal results, we recommend using Shilajit resin regularly.",
        },
        FAQS: {
          heading: "FREQUENTLY ASKED QUESTIONS",
          heading1: "Responsible and Quality-Oriented",
          paragraph1:
            "At Vitadote<sup>®</sup>, we strive for the highest quality standards for our Shilajit. We meticulously follow the applicable regulations for dietary supplements in the Netherlands. Our products undergo thorough quality controls to meet the required food safety standards. We are proud to substantiate our careful approach with relevant documentation. We aim to ensure the purity and quality of every batch of our product. Extensive information about the composition and quality characteristics of our product can be found on our website under product specifications.",
          heading3:
            "For more information and the complete list of frequently asked questions, visit our ",
          faqsPage: "FAQ Page",
          faqs: {
            question1: "What is Vitadote<sup>®</sup> Shilajit Resin?",
            answer1:
              "Vitadote<sup>®</sup> Shilajit Resin is a natural resinous substance harvested from rock formations in the Altai and Himalayan mountains. It contains a unique composition of naturally occurring minerals: iron, calcium, magnesium, selenium, and manganese, as well as organic compounds like fulvic acid and humic acid. Shilajit has been used for centuries in traditional practices across various cultures.",
            question2:
              "How do I integrate and use Vitadote<sup>®</sup> Shilajit Resin as a supplement to my daily routine?",
            answer2:
              "A healthy daily routine consists of balanced nutrition and regular exercise. Vitadote<sup>®</sup> Shilajit Resin can supplement this. If you choose to use Shilajit, we recommend taking a portion of 0.3 grams (about the size of a grain of rice to a pea) three times daily. You can dissolve Vitadote<sup>®</sup> Shilajit Resin under your tongue or mix it in a glass of non-chlorinated water or another beverage of choice (up to 39°C).",
            question3: "How do I store Vitadote<sup>®</sup> Shilajit Resin?",
            answer3: "Store it in a cool, dry place away from direct sunlight.",
            question4:
              "Where does Vitadote<sup>®</sup> Shilajit Resin come from?",
            answer4:
              "Our Shilajit is harvested from the rock formations of the Altai and Himalayan mountains.",
            question5:
              "Can you combine Vitadote<sup>®</sup> Shilajit Resin with other supplements?",
            answer5:
              "Consult a doctor if in doubt and always adhere to the advice on the packaging.",
          },
          readMore: "READ MORE FAQ",
        },
        subscription: {
          heading: "Join the Movement",
          details:
            "Discover the distinctive properties of Vitadote<sup>®</sup> Shilajit Resin for yourself and become part of our community that recognizes the value of natural ingredients.",
          joinNow: "Join Now",
          placeholder: "Enter your email",
        },
      },
      aboutPage: {
        seo: {
          metaDescription:
            "Discover Vitadote®: committed to pure Shilajit from Altai and Himalaya. Learn about our mission and sustainable harvesting practices.",
        },
        banner: {
          mainHeading: "Vitadote<sup>®</sup>: Pioneers of Pure Shilajit Resin",
          subHeading: "Our Mission: Purity from the Mountains to You",
          details:
            "At Vitadote<sup>®</sup>, we are dedicated to delivering Shilajit Resin in its most authentic form. Inspired by ancient traditions, each jar of Vitadote<sup>®</sup> Shilajit Resin provides a connection to the natural origin of this unique substance.",
        },
        infoSection: {
          heading: "From the Mountains to Your Daily Routine",
          details:
            "A good routine consists of a healthy diet and exercise pattern. Vitadote<sup>®</sup> can supplement this. Our Shilajit comes from the high-altitude regions of the Altai and Himalayan mountains, areas known for their pristine nature. We carefully bring this natural substance, with its unique composition of minerals and organic compounds like fulvic acid, to your home.",
          commitmentHeading: "Commitment to Quality and Care",
          commitments1: "At Vitadote<sup>®</sup>, we place great value on the quality and purity of our Shilajit. Our products are carefully monitored to meet applicable food safety standards. We strive to be transparent about our processes and quality controls.",
          commitments2: "While all dietary supplements must comply with legal standards, we are committed to demonstrating our careful approach. This reflects our dedication to authenticity and transparency.",
          commitments3: "For detailed information about the specific properties of our product, we invite you to refer to the product specifications on our website."
        },
        featuresSection: {
          feature1: {
            icon: "GppGoodIcon",
            heading: "Sustainable Practices, Responsible Harvesting",
            details:
              "Sustainability and respect for the environment are central to our harvesting methods. This is our commitment, not only to the product but also to the earth and its inhabitants.",
          },
          feature2: {
            icon: "fa-handshake",
            heading: "Our Team",
            details:
              "We are happy to share our insights into the natural characteristics of Shilajit and offer advice for its integration into a balanced daily regimen. A good regimen consists of a healthy diet and exercise pattern. The use of Vitadote® Shilajit Resin can supplement this.",
          },
          feature3: {
            icon: "GroupsIcon",
            heading: "The Vitadote<sup>®</sup> Promise",
            details:
              "Every package of Vitadote® Shilajit Resin stands for our promise of quality and clarity. We aim to bring you closer to the natural origin of our carefully harvested ingredients.",
          }
        }
      },

      faqsPage: {
        seo: {
          metaDescription:
            "Frequently asked questions about Vitadote® Shilajit Resin. Learn about usage, benefits, and safety of our natural supplement.",
        },
        banner: {
          heading:
            "Welcome to the Vitadote<sup>®</sup> Shilajit Resin FAQ Page",
          description1:
            "Are you curious about Vitadote<sup>®</sup> Shilajit Resin and how it can complement your daily routine? This page is dedicated to answering all your questions about this exceptional natural supplement. From its unique composition of minerals and organic compounds to storage tips and compatibility with other supplements, we cover it all.",
          description2:
            "Discover how Vitadote<sup>®</sup> Shilajit Resin is carefully sourced, lab-tested, and designed to meet your wellness needs. Whether you're looking for detailed usage instructions, safety information, or product features, our FAQ page provides the clarity and confidence you need.",
        },
        faqSection: {
          title: "FREQUENTLY ASKED QUESTIONS",
          faqs: [
            {
              question: "What is Shilajit?",
              answer:
                "Shilajit is a natural resinous substance known for its content of iron, calcium, magnesium, selenium, manganese, fulvic acid, and humic acid. It has been valued for centuries in various cultures for its unique composition and comes from the rock formations of the Altai and Himalayan mountains.",
            },
            {
              question: "How do I use Vitadote® Shilajit Resin?",
              answer:
                "We recommend taking a daily amount of 0.3 grams (a grain of rice to pea-sized portion) three times. You can dissolve Vitadote® Shilajit Resin under your tongue or in a glass of non-chlorinated water or another beverage of your choice (up to 39°C).",
            },
            {
              question:
                "Can I combine Vitadote® Shilajit Resin with other supplements?",
              answer:
                "While Shilajit is often combined with other supplements, consult a doctor if in doubt and always adhere to the advice on the packaging.",
            },
            {
              question:
                "Are there known side effects of Vitadote® Shilajit Resin?",
              answer:
                "Vitadote® Shilajit Resin is generally well tolerated when used according to the recommended dosage. Individual sensitivity may vary. Consult a doctor if in doubt and always adhere to the advice on the packaging.",
            },
            {
              question: "How do I store Vitadote® Shilajit Resin?",
              answer:
                "It should be stored in a cool, dry place away from direct sunlight and out of reach of children.",
            },
            {
              question:
                "Can I use Vitadote® Shilajit Resin during pregnancy or breastfeeding?",
              answer:
                "If you are pregnant or breastfeeding, it is important to be cautious with dietary supplements. We strongly advise consulting a healthcare provider before starting to use Vitadote® Shilajit Resin.",
            },
            {
              question:
                "Is Vitadote® Shilajit Resin suitable for vegetarians and vegans?",
              answer:
                "Yes, Vitadote® Shilajit Resin is suitable for both vegetarians and vegans.",
            },
            {
              question:
                "How long can I use Vitadote® Shilajit Resin after opening?",
              answer:
                "As long as it is stored properly, Vitadote® Shilajit Resin can be used for a long time after opening. Always check the expiry date on the packaging.",
            },
            {
              question: "Is Vitadote® Shilajit Resin Halal?",
              answer:
                "Vitadote® Shilajit Resin is a plant-based product and contains no animal products, making it suitable for those following a halal diet.",
            },
            {
              question:
                "Does Vitadote® Shilajit Resin contain allergens or gluten?",
              answer:
                "Vitadote® Shilajit Resin is free from known allergens and gluten. The production process ensures that our Shilajit is free from most common allergens, including nuts, gluten, lactose, and soy. For the most current allergen information, we recommend checking the label or contacting us directly.",
            },
            {
              question:
                "Can Vitadote® Shilajit Resin cause an allergic reaction in people with a coconut allergy?",
              answer:
                "Although Vitadote® Shilajit Resin does not contain ingredients generally recognized as allergens, it contains a small amount of coconut oil, which may cause an allergic reaction in individuals with a specific coconut allergy. If you have a coconut allergy, it is advisable to exercise caution and consult your healthcare provider before using Vitadote® Shilajit Resin.",
            },
            {
              question:
                "Can Vitadote® Shilajit Resin be used in combination with medications?",
              answer:
                "We recommend consulting your doctor or pharmacist before combining Vitadote® Shilajit Resin with medication.",
            },
          ],
          contact: "IF YOU HAVE ANY FURTHER QUESTIONS",
          contactLink: "CONTACT US",
        },
      },

      contactPage: {
        seo: {
          metaDescription:
            "Contact Vitadote® for questions or support about our products. Reach us via form, email, or phone. We're here to assist you!",
        },
        banner: {
          heading: "CONTACT US",
          subHeading: "We’re Here to Help!",
          description:
            "Do you have any questions, need assistance, or want to learn more about Vitadote® and our products? Contact us today! You can reach us via email, phone, or by filling out the contact form below. Our team is ready to assist you with any inquiries and provide the information you need.",
        },
        infoSection: {
          infoHeading: "INFO",
          addressHeading: "Address",
          address: "Albert Huismanpad 166, 1502EK Zaandam, The Netherlands",
          phoneHeading: "Phone",
          emailHeading: "Email",
          formFields: {
            nameLabel: "Name",
            emailLabel: "Email",
            phoneLabel: "Phone",
            messageLabel: "Message",
            submitButton: "SUBMIT NOW",
          },
        },
      },
      resellerPage: {
        seo: {
          metaDescription:
            "Become an exclusive reseller of Vitadote® Shilajit, the premium natural supplement. Receive sales rights, high margins, and marketing support.",
        },
        banner: {
          heading: "BECOME A VITADOTE<sup>®</sup> SHILAJIT RESELLER IN EUROPE",
          subHeading: "Are you looking for a unique and high-quality product to expand your assortment?",
          description:
            "Vitadote®, a leading producer and brand owner of premium Shilajit, is seeking enthusiastic resellers and Shilajit distributors throughout Europe. Our exclusive reseller program offers opportunities for wholesale in natural supplements.",
        },
        whysellHeading: "WHY SELL VITADOTE<sup>®</sup> SHILAJIT?",
        whySell: [
          {
            "icon": "/icons/authentication.svg",
            "feature": "Authentic product",
            "description": "Directly harvested from the Altai and Himalayan mountains"
          },
          {
            "icon": "/icons/high-quality.svg",
            "feature": "High quality",
            "description": "Strict quality controls and compliance with EU regulations"
          },
          {
            "icon": "/icons/growing-business.svg",
            "feature": "Growing market",
            "description": "Increasing demand for natural food supplements"
          },
          {
            "icon": "/icons/proposition.svg",
            "feature": "Distinctive offering",
            "description": "Unique composition of minerals and fulvic acid"
          },
          {
            "icon": "/icons/brand-identity.svg",
            "feature": "Strong brand identity",
            "description": "Professional appearance and marketing support"
          }
        ],
        benefitsHeading: "Benefits of becoming a Vitadote<sup>®</sup> reseller ",
        benefits: [
          {
            feature: "Exclusive sales rights as a Shilajit distributor in your region"
          },
          {
            feature: "Attractive profit margins for resellers and wholesalers"
          },
          {
            feature: "Access to our extensive reseller program with marketing materials and product training"
          },
          {
            feature: "Ongoing support from our experienced team"
          },
          {
            feature: "Opportunity to capitalize on the growing interest in natural supplements"
          }
        ],
        lookingForHeading: "Who are we looking for?",
        lookingFor: [
          {
            title: "Established retailers and Shilajit distributors in the health sector",
          },
          {
            title: "Online sellers and wholesalers focusing on natural food supplements",
          },
          {
            title: "Distributors of natural products",
          },
          {
            title: "Wellness centers and health practices",
          }
        ],
        footerHeading: "Interested in a Vitadote<sup>®</sup> resellership?",
        footerDescription: "Contact us today and discover how you can become a Shilajit distributor or exclusive reseller through our unique reseller program. Join the Vitadote® network and benefit from the growing demand for premium Shilajit in Europe."
      },

      "products": {
        "badges": {
          "topSelling": "Top Selling",
          "save8": "Save €8",
          "save27": "Save €27"
        },
        "images": [
          "/images/products-en/04. shilajit fulvic acid content comparison.jpg",
          "/images/products-en/05. shilajit for anti-aging and skin health.jpg",
          "/images/products-en/11. Vitadote Shilajit Resin Ingredients.jpg",
          "/images/products-en/06. organic shilajit capsules vs resin effectiveness.jpg",
          "/images/product-picture-3.jpg",
          "/images/products-en/10. shilajit side effects and safety precautions.jpg",
          "/images/products-en/07. shilajit for testosterone boost and muscle growth.jpg",
          "/images/products-en/08. shilajit benefits for women's hormonal balance.jpg",
          "/images/products-en/09. shilajit for cognitive function and brain health.jpg",
        ],
        "ourProducts": "Our Products",
        "productName1": "1x PURE SHILAJIT RESIN",
        "productName2": "2x PURE SHILAJIT RESIN",
        "productName3": "3x PURE SHILAJIT RESIN",
        "relatedProducts": "Related Products",
        "seo": {
          "metaTitle": "Pure Shilajit Resin | Altai & Himalayan Source | Fulvic Acid by Vitadote®",
          "metaDescription": "Discover Vitadote® Shilajit Resin from the Altai and Himalayan mountains. Lab-tested, UV-protected, and fulvic acid-rich for natural minerals.",
          "slug1": "1x-pure-shilajit-resin",
          "slug2": "2x-pure-shilajit-resin",
          "slug3": "3x-pure-shilajit-resin",
        },
        "banner": {
          "heading": "Pure Shilajit Resin | Altai & Himalayan Source | Fulvic Acid by Vitadote®",
          "keyQuality": "Key Qualities of Vitadote® Shilajit Resin",
          "benefits": [
            {
              "title": "Directly Sourced from Altai & Himalayas",
              "description": "Harvested from pristine mountain regions, where Shilajit accumulates its rich mineral composition."
            },
            {
              "title": "UV-Protected Glass Bottling",
              "description": "Packaged in UV glass to preserve potency by blocking harmful light rays."
            },
            {
              "title": "Lab-Tested Quality",
              "description": "Each batch is rigorously tested and HACCP certified, ensuring a high-grade, pure Shilajit product."
            },
            {
              "title": "KOAG/KAG & NVWA Certifications",
              "description": "These references confirm our Shilajit Resin meets strict safety and quality standards."
            }
          ]
        },
        "detailsTabs": {
          "description": {
            "title": "DESCRIPTION",
            "desciption": "<b>Vitadote® Shilajit Resin</b> - a premium, natural supplement sourced from the high-altitude <b>Altai and Himalayan mountains</b> — delivers a pure and unaltered form of Shilajit. Packed with essential minerals like <b>iron, calcium, magnesium, selenium, manganese, zinc</b>, and <b>fulvic acid</b>, this carefully sourced resin brings the power of nature into your wellness routine.",
            "features": [
              {
                "title": "How to Use",
                "description": "For optimal results, take <b>0.3 grams</b> (about the size of a grain of rice to a pea) three times daily. Dissolve under the tongue or mix in <b>non-chlorinated water</b> or a <b>beverage of your choice (up to 39°C).</b>"
              },
              {
                "title": "Pure and Reliable",
                "description": "<b>Vitadote®</b> is committed to authentic, responsibly sourced Shilajit Resin. We ensure each jar meets our high standards for quality and purity, delivering Shilajit as close to its natural form as possible."
              },
              {
                "title": "Explore the Essence of Shilajit",
                "description": "Choose <b>Vitadote® Shilajit Resin</b> to enhance your wellness journey with pure, unrefined Shilajit directly from the <b>Altai and Himalayan mountains</b>."
              }
            ]
          },
          "reviews": {
            "title": "REVIEWS",
            "customersReviews": "Customer's Reviews",
            "verifiedPurchases": "All from verified purchases",
            "allReviewsWithDetails": "All reviews with details",
            customerReviews: [
              {
                "clientName": "Hassan Iqbal Hashmi",
                "date": "2023-05-16",
                "profile_image": "https://lh3.googleusercontent.com/a-/ACB-R5Tsv5u4-Z7qiRmBTw5JSsm7JolJNfZNeVWlzZIefiY=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Finally find pure Shilajit Resins. It has done what was expected. Thank you."
              },
              {
                "clientName": "Rebecca Sperry",
                "date": "2023-05-03",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxZgq-bSMicabswNP3HG1r1k8p7M6ekBDc-DYVx_=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Shilajit is een super resin, ik neem elke ochtend op mij werk, en ja hoor het helpt met alles, mij houd en haar is veel beter al binnen 3 weken!"
              },
              {
                "clientName": "Doeke Leerink",
                "date": "2023-03-09",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxbqo1czKEDR9BiUlb0FgAUH2ekolUQruV6U-Kod=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Na veel zoeken hier het echte product gevonden. De website geeft uitgebreide informatie en er wordt snel geleverd. Sterk aan te raden."
              },
              {
                "clientName": "Alex Berkov",
                "date": "2023-03-07",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxYgIJuXgqYb5Hg505DuNAp9quCSFrs-AgkNJ34l=s120-c-c-rp-w64-h64-mo-ba3-br100",
                "stars": 5,
                "review": "Folosesc de aprox 1 luna shilajit de la Vitadote pot spune ca in prima faza am acoperit gustul shilajitului cu miere de albine 🙂 Efectele sale nu încetează sa tot apara, de la lipsa balonării pana la mai multa energie si focus/atentie. Mulțumesc."
              },
              {
                "clientName": "Maysoon Kelani",
                "date": "2023-02-21",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxaR9AJ46lfbFL_DYEXp8BiUns9E1HRaLVCpdJ6q=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Best product ever thanks alot!"
              },
              {
                "clientName": "Heba Khatib",
                "date": "2023-02-21",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxZksHg6l3jwZQAh6QZv4nkl7cZnzZqr3aRMka4y=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Super team and best product ever ❤️."
              },
              {
                "clientName": "Adrian Rosu Savinoiu",
                "date": "2023-02-21",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxbd7wCzON2KNosyp0gTka5yPGTdlh28C4hWJhmQ=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "L-am luat pentru sotia mea, prima imbunatatire pe care a simtit-o este ca nu mai are picioarele reci. E primul remediu incercat de ea care a reusit acest lucru. Gustul nu e atat de rau pe cat se spune mai ales daca e pus in ceai. Cutia si modul de prezentare i-au placut enorm. Produsul vine cu posta romana, deci nu ajunge chiar in 3 zile (nu din vina postei ci din cauza vamii). Vitadote a fost foarte profesionista din punctul de vedere al livrarii, link cu AWB atat posta din Olanda, am comunicat pe WhatsApp fara probleme."
              },
              {
                "clientName": "Daniela Răducănescu",
                "date": "2023-02-10",
                "profile_image": "https://lh3.googleusercontent.com/a-/ACB-R5RuEdC9WO1A6Y0OUlXUKO7s4z8jReTVqZekkR_2M1Q=s120-c-c-rp-w64-h64-mo-ba4-br100",
                "stars": 5,
                "review": "#shilajit @Vitadote Shilajitul este excelent! L-am și testat pentru autenticitate, iar la toate 3 testări rezultatele au arătat că este autentic. Am luat aseară un pahar de ceai cald in care am dizolvat shilajitul primit de la Vitadote. Nu am reușit să mai dorm până pe la ora 2 de noapte. Deci energizarea corpului este ca și cum aș fi băut o cană de ness. Comunicarea excelentă! Imediat ce au primit comanda, mi-au scris pe WhatsApp! Au grijă de client și s-au asigurat că am primit coletul. Minunat! Exact ce mi-am dorit! Mulțumesc, Vitadote!"
              },
              {
                "clientName": "dragos negrau",
                "date": "2023-02-08",
                "profile_image": "https://lh3.googleusercontent.com/a-/ACB-R5RKX3q3g1OFHa_YAA-D6Oa9M9cVSnW13vSDUABr=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Your product it’s life changer! Super team! Fast shipping..."
              }
            ]
          }
        }
      },
      "privacyPolicy": {
        "heading": "Privacy Policy",
        "seo": {
          "metaDescription": "how we collect, use, and protect your data. Information about your rights and our GDPR compliance."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Introduction",
            "description1": "At Vitadote®, we are committed to protecting your privacy and safeguarding your personal data. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you visit our website vitadote.com or use our services.",
            "description2": "eCom IT BV (\"eCom IT\", \"we\", \"us\", or \"our\"), operating under the trade name Vitadote®, is the data controller responsible for processing your personal data as described in this Privacy Policy."
          },
          "informationWeCollect": {
            "title": "2. Information We Collect",
            "description": "We may collect the following types of personal information:",
            "list": [
              "Identity and contact information (e.g., name, email address, phone number, postal address)",
              "Account information (e.g., username, password)",
              "Order and transaction data",
              "Payment information",
              "Communications with us (e.g., customer service inquiries)",
              "Usage data (e.g., how you use our website)",
              "Technical data (e.g., IP address, browser type)"
            ]
          },
          "howWeUse": {
            "title": "3. How We Use Your Information",
            "description": "We use your personal information for the following purposes:",
            "list": [
              "To process and deliver your orders",
              "To manage your account with us",
              "To provide customer support",
              "To inform you about important changes to our services",
              "To improve our website and services",
              "To prevent and detect fraud",
              "To comply with our legal obligations"
            ]
          },
          "legalBases": {
            "title": "4. Legal Bases for Processing",
            "description": "We process your personal data based on one or more of the following legal grounds:",
            "list": [
              "Performance of a contract with you",
              "Our legitimate business interests",
              "Compliance with a legal obligation",
              "Your consent (where required)"
            ],
            "extraInfo": "Vitadote® complies with the European Union's General Data Protection Regulation (GDPR). We take your privacy seriously and ensure that all our data processing activities are in line with the GDPR. This includes collecting only necessary data, implementing appropriate security measures, respecting your rights as a data subject, and being transparent about our data processing practices."
          },
          "sharingInfo": {
            "title": "5. Sharing Your Information",
            "description": "We may share your personal information with:",
            "list": [
              "Our service providers (e.g., hosting providers, payment processors)",
              "Delivery services",
              "Law enforcement or other government agencies (if legally required)"
            ],
            "note": "We do not sell your personal information to third parties."
          },
          "dataRetention": {
            "title": "6. Data Retention",
            "description": "We retain your personal information for as long as necessary to fulfill the purposes for which it was collected, or to comply with legal requirements. The specific retention periods vary depending on the type of data and the purpose of processing."
          },
          "dataSecurity": {
            "title": "7. Data Security",
            "description": "We have implemented appropriate technical and organizational measures to protect your personal information against unauthorized access, loss, misuse, or destruction."
          },
          "yourRights": {
            "title": "8. Your Rights",
            "description": "Under the GDPR and other applicable data protection laws, you have certain rights regarding your personal data, including:",
            "list": [
              "Right of access",
              "Right to rectification",
              "Right to erasure",
              "Right to restrict processing",
              "Right to data portability",
              "Right to object to processing"
            ],
            "extraInfo": "To exercise your rights, please contact us using the details provided at the end of this policy."
          },
          "cookies": {
            "title": "9. Cookies and Similar Technologies",
            "description": "We use cookies and similar technologies to enhance your experience on our website and to understand how our website is used. For more information about our use of cookies, please refer to our "
          },
          "internationalTransfers": {
            "title": "10. International Data Transfers",
            "description": "We may transfer and process your personal information in countries outside the European Economic Area (EEA). When we do so, we ensure appropriate safeguards are in place to protect your data, such as standard contractual clauses approved by the European Commission."
          },
          "policyChanges": {
            "title": "11. Changes to This Privacy Policy",
            "description": "We may update this Privacy Policy from time to time. The most current version will always be available on our website. We encourage you to review it periodically for any changes."
          },
          "contactUs": {
            "title": "12. Contact Us",
            text1: "If you have any questions, comments, or requests regarding this Privacy Policy or our privacy practices, please contact us at:",
            text2: "Email: ",
            text3: "Post: Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, The Netherlands",
            text4: "You also have the right to lodge a complaint with the Dutch Data Protection Authority (Autoriteit Persoonsgegevens) if you are not satisfied with how we have handled your personal data.",
            "lastUpdated": "Last updated: August 19, 2024"
          }
        }
      },
      "cookiePolicy": {
        "heading": "Cookie Policy",
        "seo": {
          "metaDescription": "Our use of cookies, their purpose, and how you can manage your preferences. Updated August 19, 2024."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Introduction",
            "description": "This Cookie Policy explains how Vitadote® (\"we\", \"us\", or \"our\"), operated by eCom IT BV, uses cookies and similar technologies on our website vitadote.com. This policy provides you with clear and comprehensive information about the cookies we use and the purposes for using them."
          },
          "whatAreCookies": {
            "title": "2. What Are Cookies?",
            "description": "Cookies are small text files that are placed on your device when you visit a website. They are widely used to make websites work more efficiently, as well as to provide information to the owners of the site. Cookies can be \"persistent\" or \"session\" cookies."
          },
          "howWeUseCookies": {
            "title": "3. How We Use Cookies",
            "description": "We use cookies for the following purposes:",
            "list": [
              {
                "name": "Essential Cookies",
                "purpose": "These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website or use a shopping cart."
              },
              {
                "name": "Analytical/Performance Cookies",
                "purpose": "These allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it."
              },
              {
                "name": "Functionality Cookies",
                "purpose": "These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences."
              },
              {
                "name": "Targeting Cookies",
                "purpose": "These cookies record your visit to our website, the pages you have visited and the links you have followed."
              }
            ]
          },
          "thirdPartyCookies": {
            "title": "4. Third-Party Cookies",
            "description": "In addition to our own cookies, we may also use various third-parties' cookies to report usage statistics of the Service, deliver advertisements on and through the Service, and so on."
          },
          "specificCookies": {
            "title": "5. What Specific Cookies Do We Use?",
            "description": "Here is a list of the main cookies we use on vitadote.com:",
            "table": [
              { "type": "Session Cookie", "name": "PHPSESSID", "purpose": "Preserves user session state across page requests", "duration": "Session" },
              { "type": "Authentication Cookie", "name": "auth_token", "purpose": "Keeps the user logged in", "duration": "30 days" },
              { "type": "Shopping Cart Cookie", "name": "cart_items", "purpose": "Remembers items in the user's shopping cart", "duration": "30 days" },
              { "type": "Language Preference Cookie", "name": "site_language", "purpose": "Remembers the user's language preference", "duration": "1 year" },
              { "type": "Google Analytics Cookies", "name": "_ga, _gid, _gat", "purpose": "Used to distinguish users and throttle request rate", "duration": "_ga: 2 years, _gid: 24 hours, _gat: 1 minute" },
              { "type": "Facebook Pixel Cookie", "name": "_fbp", "purpose": "Used by Facebook to deliver a series of advertisement products", "duration": "3 months" },
              { "type": "Consent Cookie", "name": "cookie_consent", "purpose": "Remembers if the user has accepted the cookie notice", "duration": "1 year" },
              { "type": "Product Recommendation Cookie", "name": "recent_views", "purpose": "Stores recently viewed products for recommendations", "duration": "30 days" },
              { "type": "Performance Cookie", "name": "page_load_time", "purpose": "Measures page load times to help improve website performance", "duration": "Session" },
              { "type": "A/B Testing Cookie", "name": "ab_test_variant", "purpose": "Remembers which version of a split test the user has seen", "duration": "30 days" }

            ],
            "description2": "Please note that third-party services, such as Google Analytics or social media platforms, may set additional cookies. For more information about these cookies, please refer to the respective privacy policies of these services.",
          },
          "choicesRegardingCookies": {
            "title": "6. Your Choices Regarding Cookies",
            "description": [
              "If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser.",
              "Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer."
            ]
          },
          "changesToPolicy": {
            "title": "7. Changes to Our Cookie Policy",
            "description": "We may update our Cookie Policy from time to time. We will notify you of any changes by posting the new Cookie Policy on this page and updating the \"Last Updated\" date at the top of this policy."
          },
          "contactUs": {
            "title": "8. Contact Us",
            "description": "If you have any questions about our use of cookies or this Cookie Policy, please contact us at:",
            "email": "info@vitadote.com",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, The Netherlands",
            "lastUpdated": "Last Updated: 19 August 2024"
          }
        }
      },
      "termsAndConditions": {
        "heading": "Terms and Conditions",
        "seo": {
          "metaDescription": "Your rights and obligations when using our website and services. Updated August 19, 2024."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Introduction",
            "description": "Welcome to Vitadote.com. These Terms and Conditions (\"Terms\") govern your use of the Vitadote® website and services (collectively, the \"Service\") operated by eCom IT BV (\"eCom IT\", \"we\", \"us\", or \"our\"). By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the Terms, you may not access the Service."
          },
          "definitions": {
            "title": "2. Definitions",
            "description": "\"You\" refers to the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable."
          },
          "accountRegistration": {
            "title": "3. Account Registration",
            "description": "When you create an account with us, you must provide accurate, complete, and up-to-date information. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service."
          },
          "useOfService": {
            "title": "4. Use of the Service",
            "description": "You agree to use the Service only for lawful purposes and in accordance with these Terms. You agree not to use the Service:",
            "list": [
              "In any way that violates any applicable national or international law or regulation.",
              "To transmit, or procure the sending of, any advertising or promotional material, including any \"junk mail\", \"chain letter,\" \"spam,\" or any other similar solicitation.",
              "To impersonate or attempt to impersonate eCom IT, a eCom IT employee, another user, or any other person or entity.",
              "In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity."
            ]
          },
          "intellectualProperty": {
            "title": "5. Intellectual Property",
            "description": "The Service and its original content, features, and functionality are and will remain the exclusive property of eCom IT and its licensors. The Service is protected by copyright, trademark, and other laws of both the Netherlands and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of eCom IT."
          },
          "userContent": {
            "title": "6. User Content",
            "description": "Our Service may allow you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material (\"Content\"). You are responsible for the Content that you post on or through the Service, including its legality, reliability, and appropriateness."
          },
          "linksToOtherWebsites": {
            "title": "7. Links to Other Websites",
            "description": "Our Service may contain links to third-party websites or services that are not owned or controlled by eCom IT. eCom IT has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that eCom IT shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services."
          },
          "termination": {
            "title": "8. Termination",
            "description": "We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. Upon termination, your right to use the Service will immediately cease."
          },
          "limitationOfLiability": {
            "title": "9. Limitation of Liability",
            "description": "In no event shall eCom IT, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service."
          },
          "disclaimer": {
            "title": "10. Disclaimer",
            "description": "Your use of the Service is at your sole risk. The Service is provided on an \"AS IS\" and \"AS AVAILABLE\" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance."
          },
          "governingLaw": {
            "title": "11. Governing Law",
            "description": "These Terms shall be governed and construed in accordance with the laws of the Netherlands, without regard to its conflict of law provisions."
          },
          "changes": {
            "title": "12. Changes",
            "description": "We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion."
          },
          "detailedDisclaimer": {
            "title": "13. Disclaimer",
            "list": [
              "<b>Information Accuracy:</b> While we strive to provide accurate and up-to-date information on Vitadote.com, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the information, products, services, or related graphics contained on the website for any purpose.",
              "<b>Use at Your Own Risk:</b> Any reliance you place on such information is therefore strictly at your own risk. In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.",
              "<b>Medical Disclaimer:</b> The information provided on Vitadote.com, including details about Shilajit and its potential benefits, is for informational purposes only and is not intended as a substitute for advice from your physician or other healthcare professional. You should not use the information on this website for diagnosing or treating a health problem or disease, or prescribing any medication or other treatment.",
              "<b>Product Use:</b> Our Shilajit products are dietary supplements and are not intended to diagnose, treat, cure, or prevent any disease. Always consult with a qualified healthcare professional prior to beginning any diet, exercise, or supplement program, before taking any medication, or if you have or suspect you might have a health problem.",
              "<b>Results May Vary:</b> The results described on this website regarding the use of Shilajit are individual experiences, reflecting real life experiences of those who have used our products. However, individual results may vary. We do not claim that they are typical results that consumers will generally achieve.",
              "<b>External Links:</b> Through this website, you may be able to link to other websites which are not under the control of Vitadote.com. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.",
              "<b>Website Availability:</b> Every effort is made to keep the website up and running smoothly. However, Vitadote.com takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.",
              "<b>Changes to Disclaimer:</b> We reserve the right to make changes to this disclaimer at any time. Any changes will be posted on this page with an updated revision date."
            ],
            "note": "By using Vitadote.com, you signify your assent to this disclaimer. If you do not agree to this disclaimer, please do not use our website."
          },
          "copyrightNotice": {
            "title": "14. Copyright Notice",
            "description1": "© 2024 Vitadote® / eCom IT BV.",
            "description2": "All rights reserved. The content, layout, design, data, databases, and graphics on this website are protected by Dutch and other international intellectual property laws and are owned by eCom IT BV or its licensors. No part of this website may be reproduced, distributed, or transmitted in any form or by any means, including photocopying, recording, or other electronic or mechanical methods, without the prior written permission of eCom IT BV."
          },
          "contactUs": {
            "title": "15. Contact Us",
            "description": "If you have any questions about these Terms, please contact us at:",
            "email": "info@vitadote.com",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, The Netherlands",
            "lastUpdated": "Last Updated: August 19, 2024"
          }
        }
      },
      "paymentPolicy": {
        "heading": "Payment Policy",
        "seo": {
          "metaDescription": "Secure methods, currency info, refunds, and recurring payments. Updated August 19, 2024."
        },
        "policyContent": {
          "paymentMethods": {
            "title": "Payment Methods",
            "description": "You can pay for your order using the following payment methods:",
            "list": [
              "American Express",
              "Apple Pay",
              "Google Pay",
              "iDEAL / Sofort",
              "Mastercard / Visa",
              "PayPal",
              "Shop Pay",
              "Union Pay"
            ],
            description2: "While we do not directly accept other credit cards, PayPal offers the option to link most common credit cards, allowing you to pay with these cards through PayPal on our website."
          },
          "paymentSecurity": {
            "title": "Payment Security",
            "description": "All transactions on our website are securely processed through our payment service provider, Mollie. All processes via Mollie adhere to the ISAE 3402 principle and criteria and are certified according to PCI DSS 3.2 Level 1 Service Provider standards. This certification is an internationally recognized security standard."
          },
          "orderVerification": {
            "title": "Order Verification",
            "description": "All payment methods are secured and validated. To protect our customers, some orders may undergo a payment verification process. If your order is placed on hold, we will contact you promptly to minimize any potential delays in your order."
          },
          "currency": {
            "title": "Currency",
            "description": "When shopping on our website Vitadote.com, all payments are processed in Euros (EUR)."
          },
          "refundsAndCancellations": {
            "title": "Refunds and Cancellations",
            "description": "For information about our refund and cancellation policies, please refer to our "
          },
          "recurringPayments": {
            "title": "Recurring Payments",
            "description": "If you have subscribed to any of our services that involve recurring payments, you will be billed automatically according to the frequency you have selected (e.g., monthly, annually). You can cancel your subscription at any time by logging into your account or contacting our customer support team."
          },
          "pricingAndTax": {
            "title": "Pricing and Tax",
            "description": "All prices displayed on our website are in Euros and include applicable taxes. Any additional customs or import duties for international orders are the responsibility of the customer."
          },
          "paymentIssues": {
            "title": "Payment Issues",
            "description": "If you experience any issues with your payment, please check the following:",
            "list": [
              "Ensure your payment details are entered correctly.",
              "Verify that your payment method has sufficient funds.",
              "Check if your bank has blocked the transaction for security reasons."
            ],
            "followUp": "If you continue to experience issues, please contact our customer support team."
          },
          "dataProtection": {
            "title": "Data Protection",
            "description": "We take your financial data security seriously. All payment information is encrypted and securely processed. We do not store your full credit card details on our servers. For more information, please refer to our "
          },
          "changesToThisPolicy": {
            "title": "Changes to this Policy",
            "description": "We may update this Payment Policy from time to time. We will notify you of any changes by posting the new Payment Policy on this page and updating the \"Last Updated\" date below."
          },
          "contactUs": {
            "title": "Contact Us",
            "description": "If you have any questions related to payments, please contact us at:",
            "email": "info@vitadote.com",
            "phone": "+31 20 244 3918",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Netherlands",
            "lastUpdated": "Last Updated: August 19, 2024"
          }
        }
      },
      "refundPolicy": {
        "heading": "Refund Policy",
        "seo": {
          "metaDescription": "Vitadote® Refund Policy: Your rights and procedures for refunds on our website and services. Updated on August 19, 2024."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Introduction",
            "description": "At Vitadote®, your satisfaction is our top priority. We're so confident in the quality of our products that we offer a unique and customer-friendly refund policy. This Refund Policy outlines the terms and conditions for refunds for products purchased from our website vitadote.com."
          },
          "keepItRefundGuarantee": {
            "title": "2. Our \"Keep-It\" Refund Guarantee",
            "description": "If you're not completely satisfied with your purchase, you can request a refund within 30 days of delivery - and you get to keep the product. We trust our customers and value your feedback, which helps us improve our products."
          },
          "refundPeriod": {
            "title": "3. Refund Period",
            "description": "You have 30 days from the date of delivery to request a refund if you're not satisfied with your purchase."
          },
          "refundEligibility": {
            "title": "4. Eligibility for Refund",
            "description": "To be eligible for our \"Keep-It\" Refund:",
            "list": [
              "You must request the refund within 30 days of delivery.",
              "You must provide a brief explanation of why you're not satisfied with the product."
            ]
          },
          "refundProcess": {
            "title": "5. Refund Process",
            "description": "To initiate a refund, please follow these steps:",
            "list": [
              "Contact our customer service team at <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a> within 30 days of delivery.",
              "Provide your order number and a brief explanation of why you're not satisfied with the product.",
              "Our team will review your request and process your refund."
            ]
          },
          "refundMethod": {
            "title": "6. Refund Method",
            "description": "Once your refund is approved, a credit will automatically be applied to your original method of payment within 10 business days."
          },
          "excessiveClaims": {
            "title": "7. Excessive Claims",
            "description": "While we trust our customers, we reserve the right to deny refunds in cases of excessive or abusive claims."
          },
          "damagedOrDefectiveItems": {
            "title": "8. Damaged or Defective Items",
            "description": "If you receive a damaged or defective item, please inform us immediately via email at <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a>. We will send you a replacement at no additional cost."
          },
          "lateOrMissingRefunds": {
            "title": "9. Late or Missing Refunds",
            "description": "If you haven't received a refund yet, first check your bank account again. Then contact your credit card company, it may take some time before your refund is officially posted. If you've done all of this and you still have not received your refund, please contact us at <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a>."
          },
          "changesToThisPolicy": {
            "title": "10. Changes to This Policy",
            "description": "We reserve the right to modify this Refund Policy at any time. Changes and clarifications will take effect immediately upon their posting on the website."
          },
          "contactUs": {
            "title": "11. Contact Us",
            "description": "If you have any questions about our Refund Policy, please contact us:",
            "email": "info@vitadote.com",
            "phone": "+31 20 244 3918",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, The Netherlands",
            "lastUpdated": "Last Updated: August 19, 2024"
          }
        }
      },
      "shippingPolicy": {
        "heading": "Shipping Policy",
        "seo": {
          "metaDescription": "Explore Vitadote®'s shipping policy. Free worldwide shipping, fast delivery, and clear delivery times. Learn about our shipping practices and customs information."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Introduction",
            "description": "At Vitadote.com, we ship our high-quality products from the Netherlands using secure shipping methods with tracking. This policy outlines our shipping practices, delivery times, and related information."
          },
          "freeShipping": {
            "title": "2. Free Shipping",
            "description": "We offer free shipping on all orders! Please note that free shipping does not include any potential customs fees."
          },
          "shippingAddress": {
            "title": "3. Shipping Address",
            "description": "<strong>Important:</strong> We do not ship to P.O. boxes. Please provide a physical delivery address."
          },
          "orderProcessing": {
            "title": "4. Order Processing",
            "description": "Orders are processed within 1-2 business days before they are shipped. The delivery times stated below are estimates from the shipping date."
          },
          "deliveryTimes": {
            "title": "5. Delivery Times",
            "description": "",
            "list": [
              "<strong>Netherlands and Belgium:</strong> For orders placed on business days before 4:00 PM, your Vitadote product will be delivered the next business day.",
              "<strong>Germany:</strong> For orders placed on business days before 4:00 PM, delivery usually occurs the next business day. In some regions, it may take 1-2 days longer due to logistical circumstances.",
              "<strong>Other EU countries:</strong> For orders placed on business days before 4:00 PM, delivery occurs within 2-4 business days.",
              "<strong>Outside the EU:</strong> For orders placed on business days before 4:00 PM, delivery occurs within 5-14 days, depending on flight connections.",
              "<strong>Asia:</strong> Delivery coming soon."
            ],
            "note": "<em>Please note:</em> We only ship on business days (Monday to Friday, excluding holidays). Orders received over the weekend will be processed on the following business day."
          },
          "damagedDeliveries": {
            "title": "6. Damaged Deliveries",
            "description": "If your Vitadote package arrives damaged, please inform us immediately via email at <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a>."
          },
          "shippingCostsAndDeliveryTimes": {
            "title": "7. Shipping Costs and Delivery Times by Country",
            "description": "",
            "table": {
              "headers": ["Country/Region", "Shipping Cost", "Delivery Time"],
              "rows": [
                ["The Netherlands", "FREE", "2 days"],
                ["Belgium", "FREE", "2-5 days"],
                ["Germany", "FREE", "2-5 days"],
                ["United Kingdom", "FREE", "2-5 days"],
                ["France", "FREE", "2-5 days"],
                ["Spain", "FREE", "7-10 days"],
                ["Italy", "FREE", "7-10 days"],
                ["Austria", "FREE", "2-5 days"],
                ["Poland", "FREE", "2-5 days"],
                ["Denmark", "FREE", "2-5 days"],
                ["Sweden", "FREE", "2-5 days"],
                ["Finland", "FREE", "2-5 days"],
                ["Ireland", "FREE", "2-5 days"],
                ["Portugal", "FREE", "5-7 days"],
                ["Greece", "FREE", "7-10 days"],
                ["Czech Republic", "FREE", "2-5 days"],
                ["Hungary", "FREE", "2-5 days"],
                ["Romania", "FREE", "4-7 days"],
                ["Bulgaria", "FREE", "8-10 days"],
                ["Switzerland", "FREE", "2-5 days"],
                ["Norway", "FREE", "2-5 days"]
              ]
            },
            "additionalInfo": "Delivery times to other countries not listed above are typically 20-30 days. All shipping is free of charge."
          },
          "customs": {
            "title": "8. Customs",
            "description": "Customs fees, taxes, or other charges are not included in the price.\n\nFor orders to non-EU countries, additional fees may apply upon arrival. Unfortunately, we cannot predict the amount of these costs. Please contact your local customs office for more accurate information."
          },
          "brexitNotice": {
            "title": "9. Brexit Notice for UK Orders",
            "description": "Due to Brexit, additional customs duties and clearance costs will be charged for all UK orders with a total value of €150 or more (depending on the current exchange rate). Please note that the value of goods assessed by UK customs may be higher than our selling price, as the original value of each product is used as a basis."
          },
          "contactUs": {
            "title": "10. Contact Us",
            "description": "If you have any questions about our Shipping Policy, please contact us at:",
            "email": "info@vitadote.com",
            "phone": "+31 20 244 3918",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, The Netherlands",
            "lastUpdated": "Last Updated: August 19, 2024"
          }
        }
      }
    },
  },
  nl: {
    translation: {
      header: {
        home: "Home",
        about: "Over ons",
        contact: "Contact",
        reseller: "Word een reseller",
      },
      common: {
        buyNow: "Koop nu",
        contactUs: "contact",
        products: "producten"
      },
      homePage: {
        seo: {
          pageTitle: "Welkom bij Vitadote®",
          metaTitle: "Ontdek Vitadote® Shilajit Resin – vitadote",
          metaDescription:
            "Ontdek Vitadote® Shilajit Resin: pure kracht uit Altai en Himalaya. Rijk aan mineralen, een natuurlijke aanvulling op uw gezonde levensstijl.",
        },
        hero: {
          accentHeading:
            "Ervaar de Zuiverheid van de Bergen met Vitadote<sup>®</sup> Shilajit Resin",
          mainHeading:
            "Ontdek de Kracht van de Natuur met Vitadote<sup>®</sup> Shilajit Resin",
          details:
            "Verken de Oorspronkelijke Elementen van de Natuur met Vitadote<sup>®</sup> Shilajit Resin. Uw verbinding met authentieke Shilajit, zorgvuldig afkomstig uit de imposante rotsformaties van het Altai- en Himalaya-gebergte. Onze Shilajit bevat natuurlijk voorkomende mineralen zoals ijzer, calcium, magnesium, selenium en mangaan, evenals fulvinezuur en is met aandacht voor de natuur en eeuwenoude tradities verwerkt.",
        },
        nutrition: {
          heading: "De Voedingswaarde van Shilajit",
          accentHeading: "Bijdrage aan de ADH uit 3 porties van elk 0,3 gram",
          details:
            "Vitadote<sup>®</sup> Shilajit Resin bevat ook een natuurlijke samenstelling van mineralen. Met een zorgvuldige selectie van onze Shilajit zorgen we ervoor dat u onder andere geniet van:",
          cards: {
            iron: {
              title: "IJzer",
              description:
                "Draagt bij tot de normale vorming van rode bloedcellen en hemoglobine. Onze Shilajit levert 30,87% Ijzer van de aanbevolen dagelijkse hoeveelheid (ADH) per dagdosering van 0,9 gram",
            },
            calcium: {
              title: "Calcium",
              description:
                "Belangrijk voor de normale spierfunctie en speelt een rol bij het proces van celdeling en -specialisatie. In onze Shilajit Resin zit 72.06% Calcium van de aanbevolen dagelijkse hoeveelheid (ADH) per dagdosering van 0,9 gram",
            },
            magnesium: {
              title: "Magnesium",
              description:
                "Belangrijk voor de instandhouding van normale botten en tanden, en draagt bij aan een normale eiwitsynthese. Ons product voorziet in 108.24% Magnesium van de ADH per dagdosering van 0,9 gram.",
            },
            selenium: {
              title: "Selenium",
              description:
                "Draagt bij aan de normale werking van het immuunsysteem en de bescherming van cellen tegen oxidatieve stress. Met onze Shilajit Resin krijgt u 22.92% Selenium van de ADH per dagdosering van 0,9 gram.",
            },
            manganese: {
              title: "Mangaan",
              description:
                "Draagt bij aan de normale vorming van bindweefsel en de bescherming van cellen tegen oxidatieve stress. In onze Shilajit Resin vindt u 33.75% Mangaan van de aanbevolen dagelijkse hoeveelheid (ADH) per dagdosering van 0,9 gram",
            },
          },
        },
        whatisShilajit: {
          heading: "ONTDEK DE HERKOMST",
          paragraph1:
            "Laat Vitadote<sup>®</sup> Shilajit Resin een aanvulling zijn op uw goede routine. Een goede routine bestaat uit een gezond eet- en beweegpatroon. Vitadote<sup>®</sup> Shilajit kan daarop een aanvulling zijn, rechtstreeks uit de ongerepte natuur, en sluit u aan bij de traditie die de tijd heeft doorstaan.",
          paragraph2: {
            bold: "Shilajit als aanvulling op Uw Voedingspatroon",
            text: "Vitadote<sup>®</sup> streeft ernaar om uw voedingskeuzes aan te vullen met Shilajit, een traditioneel gewaardeerd natuurproduct. Overleg bij twijfel met een arts en houd je altijd aan de adviezen op de verpakking.",
          },
          paragraph3: {
            bold: "Fulivinezuur in Vitadote<sup>®</sup> Shilajit Resin",
            text1:
              "Vitadote<sup>®</sup> Shilajit Resin bevat naast humuszuur en mineralen voornamelijk fulvinezuur.",
            text2:
              "met ons op voor specifieke informatie over de hoeveelheid fulvinezuur, humuszuur en mineralen in ons product of kijk op onze ",
            productPage: "productenpagina",
          },
        },
        instructions: {
          heading: "Gebruiksaanwijzing",
          details:
            "Wij adviseren dagelijks 3 keer een kleine hoeveelheid van 0,3 gram Vitadote<sup>®</sup> Shilajit Resin. Los het direct op onder de tong of in een glas niet-gechloreerd water voor optimale absorptie.",
          step1:
            "Gebruik het spateltje om de aanbevolen dosering uit het potje te nemen.",
          step2:
            "Los op in niet-gechloreerd water, kruidenthee of melk die warm is (niet warmer dan 39°C), en drink het op. Het kan ook onder de tong worden opgelost.",
          step3:
            "Voor optimale resultaten raden we aan om Shilajit-hars regelmatig te gebruiken.",
        },
        FAQS: {
          heading: "Veelgestelde Vragen",
          heading1: "Verantwoord en Kwaliteitsgericht",
          paragraph1:
            "Bij Vitadote<sup>®</sup> streven we naar de hoogste kwaliteitsstandaarden voor onze Shilajit. We zijn zorgvuldig in het volgen van de geldende regelgeving voor voedingssupplementen in Nederland. Onze producten worden onderworpen aan grondige kwaliteitscontroles om te voldoen aan de vereiste voedselveiligheidsstandaarden. We zijn trots dat we onze zorgvuldige werkwijze kunnen onderbouwen met relevante documentatie. We streven ernaar om de zuiverheid en kwaliteit van elke batch van ons product te waarborgen. Uitgebreide informatie over de samenstelling en kwaliteitskenmerken van ons product vindt u op onze website onder de productspecificaties.",
          heading3:
            "Voor meer informatie en de volledige lijst van veelgestelde vragen, bezoek onze ",
          faqsPage: "FAQ Pagina",
          faqs: {
            question1: "Wat is Vitadote<sup>®</sup> Shilajit Resin?",
            answer1:
              "Vitadote<sup>®</sup> Shilajit Resin is een natuurlijke harsachtige substantie die wordt geoogst uit rotsformaties in het Altai- en Himalaya-gebergte. Het bevat een unieke samenstelling van natuurlijk voorkomende mineralen: ijzer, calcium, magnesium, selenium en mangaan, evenals organische verbindingen zoals fulvinezuur en humuszuur. Shilajit wordt al eeuwenlang gebruikt in traditionele praktijken in verschillende culturen. ",
            question2:
              "Hoe integreer en gebruik ik Vitadote<sup>®</sup> Shilajit Resin als aanvulling op mijn dagelijkse routine?",
            answer2:
              "Een gezonde dagelijkse routine bestaat uit evenwichtige voeding en regelmatige lichaamsbeweging. Vitadote<sup>®</sup> Shilajit Resin kan hierop een aanvulling zijn. Als u ervoor kiest om Shilajit te gebruiken, raden we aan om dagelijks 3 keer een portie van 0,3 gram (ongeveer de grootte van een rijstkorrel tot een erwt) in te nemen. U kunt Vitadote<sup>®</sup> Shilajit Resin onder uw tong laten oplossen of mengen in een glas niet-gechloreerd water of een andere drank naar keuze (tot maximaal 39 °C).",
            question3: "Hoe bewaar ik Vitadote<sup>®</sup> Shilajit Resin?",
            answer3:
              "Bewaar het op een koele, droge plaats uit direct zonlicht.",
            question4: "Waar komt Vitadote<sup>®</sup> Shilajit Resin vandaan?",
            answer4:
              "Onze Shilajit wordt geoogst uit de rotsformaties van het Altai- en Himalaya-gebergte.",
            question5:
              "Kan je Vitadote<sup>®</sup> Shilajit Resin combineren met andere supplementen?",
            answer5:
              "Overleg bij twijfel met een arts en houd je altijd aan de adviezen op de verpakking.",
          },
          readMore: "LEES MEER Veelgestelde vragen",
        },
        subscription: {
          heading: "Sluit U Aan bij de Beweging",
          details:
            "Ontdek zelf de distinctieve eigenschappen van Vitadote<sup>®</sup> Shilajit Resin en maak deel uit van onze community die de waarde van natuurlijke ingrediënten erkent.",
          joinNow: "Meld je nu aan",
          placeholder: "Voer je e-mailadres in",
        },
      },
      aboutPage: {
        seo: {
          metaDescription:
            "Ontdek Vitadote®: toegewijd aan pure Shilajit uit Altai en Himalaya. Lees over onze missie en duurzame oogstmethoden.",
        },
        banner: {
          mainHeading: "Vitadote<sup>®</sup>: Pioniers van Pure Shilajit Resin",
          subHeading: "Onze Missie: Zuiverheid vanuit de Bergen tot U",
          details:
            "Bij Vitadote<sup>®</sup> zijn we toegewijd aan het leveren van Shilajit Resin in zijn meest authentieke vorm. Geïnspireerd door eeuwenoude tradities, biedt elke pot Vitadote<sup>®</sup> Shilajit Resin een verbinding met de natuurlijke oorsprong van deze unieke substantie.",
        },
        infoSection: {
          heading: "Van het Gebergte naar Uw Dagelijkse Routine",
          details:
            "Een goede routine bestaat uit een gezond eet- en beweegpatroon. Vitadote<sup>®</sup> kan daarop een aanvulling zijn. Onze Shilajit is afkomstig uit de hooggelegen gebieden van het Altai- en Himalaya-gebergte, regio's die bekend staan om hun ongerepte natuur. We brengen deze natuurlijke substantie, met zijn unieke samenstelling van mineralen en organische verbindingen zoals fulvinezuur, zorgvuldig bij u thuis.",
          commitmentHeading: "Toewijding aan Kwaliteit en Zorgvuldigheid",
          commitments1: "Bij Vitadote<sup>®</sup> hechten we grote waarde aan de kwaliteit en zuiverheid van onze Shilajit. Onze producten worden zorgvuldig gecontroleerd om te voldoen aan de geldende voedselveiligheidsstandaarden. We streven ernaar om transparant te zijn over onze processen en kwaliteitscontroles.",
          commitments2: "Hoewel alle voedingssupplementen aan de wettelijke normen moeten voldoen, zetten wij ons in om onze zorgvuldige werkwijze aantoonbaar te maken. Dit weerspiegelt onze toewijding aan authenticiteit en transparantie.",
          commitments3: "Voor gedetailleerde informatie over de specifieke eigenschappen van ons product verwijzen we u graag naar de productspecificaties op onze website.",
        },
        featuresSection: {
          feature1: {
            icon: "GppGoodIcon",
            heading: "Duurzame Praktijken, Verantwoorde Oogst",
            details:
              "Duurzaamheid en respect voor de omgeving staan centraal in onze oogstmethoden. Dit is onze toewijding, niet alleen aan het product, maar ook aan de aarde en haar bewoners.",
          },
          feature2: {
            icon: "fa-handshake",
            heading: "Ons Team",
            details:
              "Wij delen graag met u ons inzicht in de natuurlijke kenmerken van Shilajit en bieden advies voor de integratie ervan in een evenwichtig dagelijks regime. Een goed regime bestaat uit een gezond eet- en beweegpatroon. Het gebruik van Vitadote® Shilajit Resin kan daarop een aanvulling zijn.",
          },
          feature3: {
            icon: "GroupsIcon",
            heading: "De Vitadote<sup>®</sup> Belofte",
            details:
              "Elke verpakking Vitadote® Shilajit Resin staat voor onze belofte van kwaliteit en helderheid. Wij zijn erop gericht u dichter bij de natuurlijke oorsprong van onze zorgvuldig verzamelde ingrediënten te brengen.",
          },
        },
      },

      faqsPage: {
        seo: {
          metaDescription:
            "Veelgestelde vragen over Vitadote® Shilajit Resin. Leer over gebruik, voordelen en veiligheid van ons natuurlijke supplement.",
        },
        banner: {
          heading:
            "Welkom op de FAQ-pagina van Vitadote<sup>®</sup> Shilajit Resin",
          description1:
            "Benieuwd naar Vitadote<sup>®</sup> Shilajit Resin en hoe het jouw dagelijkse routine kan aanvullen? Deze pagina is speciaal ontworpen om al je vragen over dit uitzonderlijke natuurlijke supplement te beantwoorden. Van de unieke samenstelling van mineralen en organische verbindingen tot opslagtips en compatibiliteit met andere supplementen, alles komt aan bod.",
          description2:
            "Ontdek hoe Vitadote<sup>®</sup> Shilajit Resin zorgvuldig wordt gewonnen, in laboratoria wordt getest en is ontworpen om aan jouw welzijnsbehoeften te voldoen. Of je nu op zoek bent naar gedetailleerde gebruiksinstructies, veiligheidsinformatie of productkenmerken, onze FAQ-pagina biedt de duidelijkheid en het vertrouwen die je nodig hebt.",
        },
        faqSection: {
          title: "Veelgestelde Vragen over Vitadote® Shilajit Resin",
          faqs: [
            {
              question: "Wat is Shilajit?",
              answer:
                "Shilajit is een natuurlijke harsachtige substantie bekend om zijn inhoud van Ijzer, Calcium, Magnesium, Selenium, Mangaan, Fulvinezuur en Humuszuur. Het wordt al eeuwenlang in verschillende culturen gewaardeerd voor de bijzondere samenstelling en komt uit de rotsformaties van het Altai- en Himalaya-gebergte.",
            },
            {
              question: "Hoe gebruik ik Vitadote® Shilajit Resin?",
              answer:
                "Wij adviseren om dagelijks 3 keer een hoeveelheid van 0,3 gram (een rijstkorrel tot erwtgrootte portie) te nemen. U kunt Vitadote® Shilajit Resin oplossen onder de tong of in een glas niet-gechloreerd water of een andere (tot max 39 °C) drank naar keuze.",
            },
            {
              question:
                "Kan ik Vitadote® Shilajit Resin combineren met andere supplementen?",
              answer:
                "Hoewel Shilajit vaak gecombineerd wordt met andere supplementen. Overleg bij twijfel met een arts en houd je altijd aan de adviezen op de verpakking.",
            },
            {
              question:
                "Zijn er bekende neveneffecten van Vitadote® Shilajit Resin?",
              answer:
                "Vitadote® Shilajit Resin wordt over het algemeen goed verdragen wanneer het gebruikt wordt volgens de aanbevolen dosering. Individuele gevoeligheid kan variëren. Overleg bij twijfel met een arts en houd je altijd aan de adviezen op de verpakking.",
            },
            {
              question: "Hoe bewaar ik Vitadote® Shilajit Resin?",
              answer:
                "Het dient op een koele, droge plek uit de buurt van direct zonlicht bewaard te worden. Buiten bereik van kinderen houden.",
            },
            {
              question:
                "Kan ik Vitadote® Shilajit Resin gebruiken tijdens zwangerschap of borstvoeding?",
              answer:
                "Als u zwanger bent of borstvoeding geeft, is het belangrijk om voorzichtig te zijn met voedingssupplementen. Wij adviseren u ten zeerste om een zorgverlener te raadplegen voordat u begint met het gebruik van Vitadote® Shilajit Resin.",
            },
            {
              question:
                "Is Vitadote® Shilajit Resin geschikt voor vegetariërs en veganisten?",
              answer:
                "Ja, Vitadote® Shilajit Resin is geschikt voor zowel vegetariërs als veganisten.",
            },
            {
              question:
                "Hoe lang kan ik Vitadote® Shilajit Resin gebruiken na opening?",
              answer:
                "Zolang het op de juiste manier bewaard wordt, kan Vitadote® Shilajit Resin gedurende een lange periode na opening gebruikt worden. Controleer altijd de houdbaarheidsdatum op de verpakking.",
            },
            {
              question: "Is Vitadote® Shilajit Resin Halal?",
              answer:
                "Vitadote® Shilajit Resin is een plantaardig product en bevat geen dierlijke producten, waardoor het geschikt zou moeten zijn voor mensen die een halal dieet volgen.",
            },
            {
              question: "Bevat Vitadote® Shilajit Resin allergenen of gluten?",
              answer:
                "Vitadote® Shilajit Resin is vrij van bekende allergenen en gluten. Het productieproces garandeert dat ons Shilajit vrij is van de meeste algemene allergenen, waaronder noten, gluten, lactose, en soja. Voor de meest actuele informatie over allergenen raden we aan om de etiketinformatie te controleren of direct contact met ons op te nemen.",
            },
            {
              question:
                "Kan Vitadote® Shilajit Resin een allergische reactie veroorzaken bij mensen met een kokosallergie?",
              answer:
                "Hoewel Vitadote® Shilajit Resin geen ingrediënten bevat die algemeen bekend staan als allergenen, bevat het een kleine hoeveelheid kokosolie, die een allergische reactie kan veroorzaken bij mensen met een specifieke allergie voor kokos. Als u een kokosallergie heeft, is het raadzaam om voorzichtigheid te betrachten en te overleggen met uw gezondheidszorgaanbieder alvorens Vitadote® Shilajit Resin te gebruiken.",
            },
            {
              question:
                "Kan Vitadote® Shilajit Resin gebruikt worden in combinatie met medicijnen?",
              answer:
                "Wij raden aan om eerst met uw arts of apotheker te overleggen voordat u Vitadote® Shilajit Resin combineert met medicatie.",
            },
          ],
          contact: "Heeft u nog meer vragen?",
          contactLink: "Neem dan contact met ons op",
        },
      },
      contactPage: {
        seo: {
          metaDescription:
            "Neem contact op met Vitadote® voor vragen of ondersteuning over onze producten. Bereik ons via formulier, e-mail of telefoon. We staan voor je klaar!",
        },
        banner: {
          heading: "NEEM CONTACT MET ONS OP",
          subHeading: "We Staan Voor Je Klaar!",
          description:
            "Heb je vragen, behoefte aan hulp of wil je meer weten over Vitadote® en onze producten? Neem vandaag nog contact met ons op! Je kunt ons bereiken via e-mail, telefoon of door het contactformulier hieronder in te vullen. Ons team staat klaar om je te helpen met al je vragen en informatie.",
        },
        infoSection: {
          infoHeading: "INFORMATIE",
          addressHeading: "Adres",
          address: "Albert Huismanpad 166, 1502EK Zaandam, Nederland",
          phoneHeading: "Telefoon",
          emailHeading: "E-mail",
          formFields: {
            nameLabel: "Naam",
            emailLabel: "E-mail",
            phoneLabel: "Telefoon",
            messageLabel: "Bericht",
            submitButton: "VERSTUUR NU",
          },
        },
      },
      "resellerPage": {
        "seo": {
          "metaDescription": "Word exclusief reseller van Vitadote® Shilajit, premium natuurlijk supplement. Ontvang verkooprechten, hoge marges en marketingondersteuning."
        },
        "banner": {
          "heading": "WORD EEN VITADOTE<sup>®</sup> SHILAJIT RESELLER IN EUROPA",
          "subHeading": "Bent u op zoek naar een uniek en hoogwaardig product om uw assortiment uit te breiden?",
          "description": "Vitadote®, toonaangevende producent en merkeigenaar van premium Shilajit, zoekt enthousiaste resellers en Shilajit distributeurs in heel Europa. Ons exclusieve resellerprogramma biedt kansen voor groothandel in natuurlijke supplementen."
        },
        "whysellHeading": "WAAROM VITADOTE<sup>®</sup> SHILAJIT VERKOPEN?",
        "whySell": [
          {
            "icon": "/icons/authentication.svg",
            "feature": "Authentiek product",
            "description": "Rechtstreeks geoogst uit de Altai en Himalaya gebergten"
          },
          {
            "icon": "/icons/high-quality.svg",
            "feature": "Hoge kwaliteit",
            "description": "Strenge kwaliteitscontroles en naleving van EU-regelgeving"
          },
          {
            "icon": "/icons/growing-business.svg",
            "feature": "Groeiende markt",
            "description": "Toenemende vraag naar natuurlijke voedingssupplementen"
          },
          {
            "icon": "/icons/proposition.svg",
            "feature": "Onderscheidend aanbod",
            "description": "Unieke samenstelling van mineralen en fulvinezuur"
          },
          {
            "icon": "/icons/brand-identity.svg",
            "feature": "Sterke merkidentiteit",
            "description": "Professionele uitstraling en marketingondersteuning"
          }
        ],
        "benefitsHeading": "Voordelen van Vitadote® reseller worden",
        "benefits": [
          {
            "feature": "Exclusieve verkooprechten als Shilajit distributeur in uw regio"
          },
          {
            "feature": "Aantrekkelijke winstmarges voor resellers en groothandels"
          },
          {
            "feature": "Toegang tot ons uitgebreide resellerprogramma met marketingmateriaal en producttraining"
          },
          {
            "feature": "Doorlopende ondersteuning van ons ervaren team"
          },
          {
            "feature": "Mogelijkheid om in te spelen op de groeiende interesse in natuurlijke supplementen"
          }
        ],
        "lookingForHeading": "Wie zoeken wij?",
        "lookingFor": [
          {
            "title": "Gevestigde retailers en Shilajit distributeurs in de gezondheidssector"
          },
          {
            "title": "Online verkopers en groothandels met focus op natuurlijke voedingssupplementen"
          },
          {
            "title": "Distributeurs van natuurlijke producten"
          },
          {
            "title": "Wellnesscentra en gezondheidspraktijken"
          }
        ],
        "footerHeading": "Interesse in een Vitadote<sup>®</sup> resellership?",
        "footerDescription": "Neem vandaag nog contact met ons op en ontdek hoe u Shilajit distributeur of exclusieve reseller kunt worden via ons unieke resellerprogramma. Word deel van het Vitadote® netwerk en profiteer van de groeiende vraag naar premium Shilajit in Europa."
      },
      "products": {
        "badges": {
          "topSelling": "Bestseller",
          "save8": "Besparing €8",
          "save27": "Besparing €27"
        },
        "images": [
          "/images/products-nl/04. shilajit fulvinezuur gehalte vergelijking.jpg",
          "/images/products-nl/05. shilajit voor anti-aging en huidgezondheid.jpg",
          "/images/products-nl/11. Ingrediënten van Vitadote Shilajit Resin.jpg",
          "/images/products-nl/06. biologische shilajit capsules vs hars effectiviteit.jpg",
          "/images/product-picture-3.jpg",
          "/images/products-nl/10. shilajit bijwerkingen en veiligheidsmaatregelen.jpg",
          "/images/products-nl/07. shilajit voor testosteronboost en spiergroei.jpg",
          "/images/products-nl/08. shilajit voordelen voor hormonale balans bij vrouwen.jpg",
          "/images/products-nl/09. shilajit voor cognitieve functie en hersengezondheid.jpg",
        ],
        "productName1": "1x PURE SHILAJIT RESIN",
        "productName2": "2x PURE SHILAJIT RESIN",
        "productName3": "3x PURE SHILAJIT RESIN",
        "ourProducts": "Onze Producten",
        "relatedProducts": "Gerelateerde Producten",
        "seo": {
          "metaTitle": "Pure Shilajit Resin | Altai & Himalaya Bron | Fulvinezuur door Vitadote®",
          "metaDescription": "Ontdek Vitadote® Shilajit Resin uit de Altai- en Himalayagebergten. Lab-getest, UV-beschermd, en rijk aan fulvinezuur voor natuurlijke mineralen.",
          "slug1": "1x-pure-shilajit-resin",
          "slug2": "2x-pure-shilajit-resin",
          "slug3": "3x-pure-shilajit-resin",
        },
        "banner": {
          "heading": "Pure Shilajit Resin | Altai & Himalaya Bron | Fulvinezuur door Vitadote®",
          "keyQuality": "Belangrijke Kenmerken van Vitadote® Shilajit Resin",
          "benefits": [
            {
              "title": "Direct afkomstig uit Altai en Himalaya",
              "description": "Geoogst uit ongerepte berggebieden, waar Shilajit zijn rijke mineraalsamenstelling opbouwt."
            },
            {
              "title": "UV-beschermde glazen verpakking",
              "description": "Verpakt in UV-glas om de potentie te behouden door schadelijke lichtstralen te blokkeren."
            },
            {
              "title": "Lab-geteste Kwaliteit",
              "description": "Elke batch wordt rigoureus getest en is HACCP-gecertificeerd, wat garant staat voor een hoogwaardig, puur Shilajit-product."
            },
            {
              "title": "KOAG/KAG & NVWA Certificeringen",
              "description": "Deze referenties bevestigen dat onze Shilajit Resin voldoet aan strenge veiligheids- en kwaliteitsnormen."
            }
          ]
        },
        "detailsTabs": {
          "description": {
            "title": "BESCHRIJVING",
            "desciption": "<b>Vitadote® Shilajit Resin</b> - een premium, natuurlijk supplement afkomstig uit de hooggelegen gebieden van de <b>Altai- en Himalayagebergten</b> — biedt een pure en onbewerkte vorm van Shilajit. Rijk aan essentiële mineralen zoals <b>ijzer, calcium, magnesium, selenium, mangaan, zink</b>, en <b>fulvinezuur</b>, brengt deze zorgvuldig verkregen hars de kracht van de natuur in je dagelijkse welzijnsroutine.",
            "features": [
              {
                "title": "Gebruiksaanwijzing",
                "description": "Voor optimale resultaten, neem <b>0,3 gram</b> (ongeveer ter grootte van een rijstkorrel tot een erwt) drie keer per dag. Laat het onder de tong oplossen of meng het in <b>niet-gechloreerd water</b> of een <b>drank naar keuze (tot 39°C).</b>"
              },
              {
                "title": "Puur en Betrouwbaar",
                "description": "<b>Vitadote®</b> zet zich in voor authentieke, verantwoord verkregen Shilajit Resin. We zorgen ervoor dat elke pot voldoet aan onze hoge normen voor kwaliteit en zuiverheid, zodat je Shilajit zo dicht mogelijk bij zijn natuurlijke vorm kunt ervaren."
              },
              {
                "title": "Ontdek de Essentie van Shilajit",
                "description": "Kies <b>Vitadote® Shilajit Resin</b> om je welzijnsreis te verrijken met pure, onbewerkte Shilajit rechtstreeks uit de <b>Altai- en Himalayagebergten.</b>"
              }
            ]
          },
          "reviews": {
            "title": "BEOORDELINGEN",
            "customersReviews": "Klantbeoordelingen",
            "verifiedPurchases": "Alleen van geverifieerde aankopen",
            "allReviewsWithDetails": "Alle beoordelingen met details",
            customerReviews: [
              {
                "clientName": "Hassan Iqbal Hashmi",
                "date": "2023-05-16",
                "profile_image": "https://lh3.googleusercontent.com/a-/ACB-R5Tsv5u4-Z7qiRmBTw5JSsm7JolJNfZNeVWlzZIefiY=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Finally find pure Shilajit Resins. It has done what was expected. Thank you."
              },
              {
                "clientName": "Rebecca Sperry",
                "date": "2023-05-03",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxZgq-bSMicabswNP3HG1r1k8p7M6ekBDc-DYVx_=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Shilajit is een super resin, ik neem elke ochtend op mij werk, en ja hoor het helpt met alles, mij houd en haar is veel beter al binnen 3 weken!"
              },
              {
                "clientName": "Doeke Leerink",
                "date": "2023-03-09",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxbqo1czKEDR9BiUlb0FgAUH2ekolUQruV6U-Kod=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Na veel zoeken hier het echte product gevonden. De website geeft uitgebreide informatie en er wordt snel geleverd. Sterk aan te raden."
              },
              {
                "clientName": "Alex Berkov",
                "date": "2023-03-07",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxYgIJuXgqYb5Hg505DuNAp9quCSFrs-AgkNJ34l=s120-c-c-rp-w64-h64-mo-ba3-br100",
                "stars": 5,
                "review": "Folosesc de aprox 1 luna shilajit de la Vitadote pot spune ca in prima faza am acoperit gustul shilajitului cu miere de albine 🙂 Efectele sale nu încetează sa tot apara, de la lipsa balonării pana la mai multa energie si focus/atentie. Mulțumesc."
              },
              {
                "clientName": "Maysoon Kelani",
                "date": "2023-02-21",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxaR9AJ46lfbFL_DYEXp8BiUns9E1HRaLVCpdJ6q=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Best product ever thanks alot!"
              },
              {
                "clientName": "Heba Khatib",
                "date": "2023-02-21",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxZksHg6l3jwZQAh6QZv4nkl7cZnzZqr3aRMka4y=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Super team and best product ever ❤️."
              },
              {
                "clientName": "Adrian Rosu Savinoiu",
                "date": "2023-02-21",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxbd7wCzON2KNosyp0gTka5yPGTdlh28C4hWJhmQ=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "L-am luat pentru sotia mea, prima imbunatatire pe care a simtit-o este ca nu mai are picioarele reci. E primul remediu incercat de ea care a reusit acest lucru. Gustul nu e atat de rau pe cat se spune mai ales daca e pus in ceai. Cutia si modul de prezentare i-au placut enorm. Produsul vine cu posta romana, deci nu ajunge chiar in 3 zile (nu din vina postei ci din cauza vamii). Vitadote a fost foarte profesionista din punctul de vedere al livrarii, link cu AWB atat posta din Olanda, am comunicat pe WhatsApp fara probleme."
              },
              {
                "clientName": "Daniela Răducănescu",
                "date": "2023-02-10",
                "profile_image": "https://lh3.googleusercontent.com/a-/ACB-R5RuEdC9WO1A6Y0OUlXUKO7s4z8jReTVqZekkR_2M1Q=s120-c-c-rp-w64-h64-mo-ba4-br100",
                "stars": 5,
                "review": "#shilajit @Vitadote Shilajitul este excelent! L-am și testat pentru autenticitate, iar la toate 3 testări rezultatele au arătat că este autentic. Am luat aseară un pahar de ceai cald in care am dizolvat shilajitul primit de la Vitadote. Nu am reușit să mai dorm până pe la ora 2 de noapte. Deci energizarea corpului este ca și cum aș fi băut o cană de ness. Comunicarea excelentă! Imediat ce au primit comanda, mi-au scris pe WhatsApp! Au grijă de client și s-au asigurat că am primit coletul. Minunat! Exact ce mi-am dorit! Mulțumesc, Vitadote!"
              },
              {
                "clientName": "dragos negrau",
                "date": "2023-02-08",
                "profile_image": "https://lh3.googleusercontent.com/a-/ACB-R5RKX3q3g1OFHa_YAA-D6Oa9M9cVSnW13vSDUABr=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Your product it’s life changer! Super team! Fast shipping..."
              }
            ]
          }
        }
      },
      "privacyPolicy": {
        "heading": "Privacybeleid",
        "seo": {
          "metaDescription": "Lees het Vitadote® privacybeleid. Ontdek hoe we uw gegevens verzamelen, gebruiken en beschermen. Informatie over uw rechten en onze AVG-naleving."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Inleiding",
            "description1": "Bij Vitadote® zetten wij ons in voor de bescherming van uw privacy en de beveiliging van uw persoonsgegevens. Dit Privacybeleid legt uit hoe wij uw persoonlijke informatie verzamelen, gebruiken, delen en beschermen wanneer u onze website vitadote.com bezoekt of gebruik maakt van onze diensten.",
            "description2": "eCom IT BV (\"eCom IT\", \"wij\", \"ons\" of \"onze\"), handelend onder de naam Vitadote®, is de verwerkingsverantwoordelijke voor de verwerking van uw persoonsgegevens zoals beschreven in dit Privacybeleid."
          },
          "informationWeCollect": {
            "title": "2. Informatie die wij verzamelen",
            "description": "Wij kunnen de volgende soorten persoonlijke informatie verzamelen:",
            "list": [
              "Identiteits- en contactgegevens (bijv. naam, e-mailadres, telefoonnummer, postadres)",
              "Accountgegevens (bijv. gebruikersnaam, wachtwoord)",
              "Bestel- en transactiegegevens",
              "Betalingsgegevens",
              "Communicatie met ons (bijv. klantenservicevragen)",
              "Gebruiksgegevens (bijv. hoe u onze website gebruikt)",
              "Technische gegevens (bijv. IP-adres, browsertype)"
            ]
          },
          "howWeUse": {
            "title": "3. Hoe wij uw informatie gebruiken",
            "description": "Wij gebruiken uw persoonlijke informatie voor de volgende doeleinden:",
            "list": [
              "Om uw bestellingen te verwerken en te leveren",
              "Om uw account bij ons te beheren",
              "Om klantenondersteuning te bieden",
              "Om u te informeren over belangrijke wijzigingen in onze diensten",
              "Om onze website en diensten te verbeteren",
              "Om fraude te voorkomen en op te sporen",
              "Om te voldoen aan onze wettelijke verplichtingen"
            ]
          },
          "legalBases": {
            "title": "4. Rechtsgronden voor verwerking",
            "description": "Wij verwerken uw persoonsgegevens op basis van een of meer van de volgende rechtsgronden:",
            "list": [
              "Uitvoering van een overeenkomst met u",
              "Onze gerechtvaardigde bedrijfsbelangen",
              "Naleving van een wettelijke verplichting",
              "Uw toestemming (waar vereist)"
            ],
            "extraInfo": "Vitadote® voldoet aan de Algemene Verordening Gegevensbescherming (AVG) van de Europese Unie. Wij nemen uw privacy serieus en zorgen ervoor dat al onze gegevensverwerkingsactiviteiten in overeenstemming zijn met de AVG. Dit omvat het verzamelen van alleen noodzakelijke gegevens, het implementeren van passende beveiligingsmaatregelen, het respecteren van uw rechten als betrokkene, en het transparant zijn over onze gegevensverwerkingspraktijken."
          },
          "sharingInfo": {
            "title": "5. Het delen van uw informatie",
            "description": "Wij kunnen uw persoonlijke informatie delen met:",
            "list": [
              "Onze dienstverleners (bijv. hostingproviders, betalingsverwerkers)",
              "Bezorgdiensten",
              "Wetshandhavingsinstanties of andere overheidsinstanties (indien wettelijk vereist)"
            ],
            "note": "Wij verkopen uw persoonlijke informatie niet aan derden."
          },
          "dataRetention": {
            "title": "6. Gegevensbewaring",
            "description": "Wij bewaren uw persoonlijke informatie zo lang als nodig is om de doeleinden waarvoor deze is verzameld te vervullen, of om te voldoen aan wettelijke vereisten."
          },
          "dataSecurity": {
            "title": "7. Gegevensbeveiliging",
            "description": "Wij hebben passende technische en organisatorische maatregelen geïmplementeerd om uw persoonlijke informatie te beschermen tegen ongeautoriseerde toegang, verlies, misbruik of vernietiging."
          },
          "yourRights": {
            "title": "8. Uw rechten",
            "description": "Onder de AVG en andere toepasselijke wetgeving inzake gegevensbescherming heeft u bepaalde rechten met betrekking tot uw persoonsgegevens, waaronder:",
            "list": [
              "Recht op inzage",
              "Recht op rectificatie",
              "Recht op verwijdering",
              "Recht op beperking van de verwerking",
              "Recht op gegevensoverdraagbaarheid",
              "Recht om bezwaar te maken tegen verwerking"
            ],
            "extraInfo": "Om uw rechten uit te oefenen, kunt u contact met ons opnemen via de gegevens aan het einde van dit beleid."
          },
          "cookies": {
            "title": "9. Cookies en vergelijkbare technologieën",
            "description": "Wij gebruiken cookies en vergelijkbare technologieën om uw ervaring op onze website te verbeteren en om te begrijpen hoe onze website wordt gebruikt. Voor meer informatie over ons gebruik van cookies, raadpleeg ons "
          },
          "internationalTransfers": {
            "title": "10. Internationale gegevensoverdrachten",
            "description": "Wij kunnen uw persoonlijke informatie overdragen en verwerken in landen buiten de Europese Economische Ruimte (EER)."
          },
          "policyChanges": {
            "title": "11. Wijzigingen in dit Privacybeleid",
            "description": "Wij kunnen dit Privacybeleid van tijd tot tijd bijwerken. De meest recente versie zal altijd beschikbaar zijn op onze website."
          },
          "contactUs": {
            "title": "12. Contact met ons opnemen",
            "text1": "Als u vragen, opmerkingen of verzoeken heeft met betrekking tot dit Privacybeleid of onze privacypraktijken, neem dan contact met ons op via:",
            "text2": "E-mail: ",
            "text3": "Post: Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Nederland",
            "text4": "U heeft ook het recht om een klacht in te dienen bij de Nederlandse Autoriteit Persoonsgegevens.",
            "lastUpdated": "Laatst bijgewerkt: 19 augustus 2024"
          }
        }
      },
      "cookiePolicy": {
        "heading": "Cookiebeleid",
        "seo": {
          "metaDescription": "Vitadote® Cookiebeleid: ons gebruik van cookies, hun doel en hoe u uw voorkeuren kunt beheren. Bijgewerkt op 19 augustus 2024."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Inleiding",
            "description": "Dit Cookiebeleid legt uit hoe Vitadote® (\"wij\", \"ons\" of \"onze\"), beheerd door eCom IT BV, cookies en vergelijkbare technologieën gebruikt op onze website vitadote.com. Dit beleid biedt u duidelijke en uitgebreide informatie over de cookies die we gebruiken en de doeleinden waarvoor we ze gebruiken."
          },
          "whatAreCookies": {
            "title": "2. Wat zijn cookies?",
            "description": "Cookies zijn kleine tekstbestanden die op uw apparaat worden geplaatst wanneer u een website bezoekt. Ze worden veel gebruikt om websites efficiënter te laten werken en om informatie te verstrekken aan de eigenaren van de site. Cookies kunnen \"permanente\" of \"sessie\" cookies zijn."
          },
          "howWeUseCookies": {
            "title": "3. Hoe wij cookies gebruiken",
            "description": "Wij gebruiken cookies voor de volgende doeleinden:",
            "list": [
              {
                "name": "Essentiële cookies",
                "purpose": "Dit zijn cookies die nodig zijn voor het functioneren van onze website. Ze omvatten bijvoorbeeld cookies die u in staat stellen in te loggen op beveiligde delen van onze website of een winkelwagentje te gebruiken."
              },
              {
                "name": "Analytische/prestatiecookies",
                "purpose": "Deze stellen ons in staat om bezoekers te herkennen en te tellen en te zien hoe bezoekers zich op onze website bewegen wanneer ze deze gebruiken."
              },
              {
                "name": "Functionaliteitscookies",
                "purpose": "Deze worden gebruikt om u te herkennen wanneer u terugkeert naar onze website. Dit stelt ons in staat onze inhoud voor u te personaliseren en uw voorkeuren te onthouden."
              },
              {
                "name": "Targeting cookies",
                "purpose": "Deze cookies registreren uw bezoek aan onze website, de pagina's die u heeft bezocht en de links die u heeft gevolgd."
              }
            ]
          },
          "thirdPartyCookies": {
            "title": "4. Cookies van derden",
            "description": "Naast onze eigen cookies kunnen we ook cookies van verschillende derden gebruiken om gebruiksstatistieken van de Service te rapporteren, advertenties te leveren op en via de Service, enzovoort."
          },
          "specificCookies": {
            "title": "5. Welke specifieke cookies gebruiken we?",
            "description": "Hier is een lijst van de belangrijkste cookies die we gebruiken op vitadote.com:",
            "table": [
              { "type": "Sessiecookie", "name": "PHPSESSID", "purpose": "Bewaart de sessiestatus van de gebruiker over paginaverzoeken heen", "duration": "Sessie" },
              { "type": "Authenticatiecookie", "name": "auth_token", "purpose": "Houdt de gebruiker ingelogd", "duration": "30 dagen" },
              { "type": "Winkelwagencookie", "name": "cart_items", "purpose": "Onthoudt items in het winkelwagentje van de gebruiker", "duration": "30 dagen" },
              { "type": "Taalvoorkeurcookie", "name": "site_language", "purpose": "Onthoudt de taalvoorkeur van de gebruiker", "duration": "1 jaar" },
              { "type": "Google Analytics cookies", "name": "_ga, _gid, _gat", "purpose": "Gebruikt om gebruikers te onderscheiden en de aanvraagsnelheid te beperken", "duration": "_ga: 2 jaar, _gid: 24 uur, _gat: 1 minuut" },
              { "type": "Facebook Pixel cookie", "name": "_fbp", "purpose": "Gebruikt door Facebook om een reeks advertentieproducten te leveren", "duration": "3 maanden" },
              { "type": "Toestemmingscookie", "name": "cookie_consent", "purpose": "Onthoudt of de gebruiker de cookiemelding heeft geaccepteerd", "duration": "1 jaar" },
              { "type": "Productaanbevelingscookie", "name": "recent_views", "purpose": "Slaat recent bekeken producten op voor aanbevelingen", "duration": "30 dagen" },
              { "type": "Prestatiecookie", "name": "page_load_time", "purpose": "Meet laadtijden van pagina's om de websiteprestaties te helpen verbeteren", "duration": "Sessie" },
              { "type": "A/B-testcookie", "name": "ab_test_variant", "purpose": "Onthoudt welke versie van een splitstest de gebruiker heeft gezien", "duration": "30 dagen" }
            ],
            "description2": "Let op dat diensten van derden, zoals Google Analytics of sociale mediaplatforms, mogelijk aanvullende cookies plaatsen. Voor meer informatie over deze cookies verwijzen we u naar de respectievelijke privacybeleiden van deze diensten.",
          },
          "choicesRegardingCookies": {
            "title": "6. Uw keuzes met betrekking tot cookies",
            "description": [
              "Als u cookies wilt verwijderen of uw webbrowser wilt instrueren om cookies te verwijderen of te weigeren, bezoek dan de helppagina's van uw webbrowser.",
              "Houd er echter rekening mee dat als u cookies verwijdert of weigert te accepteren, u mogelijk niet alle functies die we aanbieden kunt gebruiken."
            ]
          },
          "changesToPolicy": {
            "title": "7. Wijzigingen in ons Cookiebeleid",
            "description": "We kunnen ons Cookiebeleid van tijd tot tijd bijwerken. We zullen u op de hoogte stellen van eventuele wijzigingen door het nieuwe Cookiebeleid op deze pagina te plaatsen en de datum van \"Laatst bijgewerkt\" bovenaan dit beleid bij te werken."
          },
          "contactUs": {
            "title": "8. Neem contact met ons op",
            "description": "Als u vragen heeft over ons gebruik van cookies of dit Cookiebeleid, neem dan contact met ons op via:",
            "email": "info@vitadote.com",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Nederland",
            "lastUpdated": "Laatst bijgewerkt: 19 augustus 2024"
          }
        }
      },
      "termsAndConditions": {
        "heading": "Algemene Voorwaarden",
        "seo": {
          "metaDescription": "Vitadote® Algemene Voorwaarden: uw rechten en plichten bij gebruik van onze website en diensten. Bijgewerkt op 19 augustus 2024."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Inleiding",
            "description": "Welkom bij Vitadote.com. Deze Algemene Voorwaarden (\"Voorwaarden\") zijn van toepassing op uw gebruik van de Vitadote® website en diensten (gezamenlijk de \"Dienst\") die worden beheerd door eCom IT BV (\"eCom IT\", \"wij\", \"ons\" of \"onze\"). Door de Dienst te gebruiken of er toegang toe te krijgen, gaat u akkoord met deze Voorwaarden. Als u het niet eens bent met enig onderdeel van de Voorwaarden, mag u geen gebruik maken van de Dienst."
          },
          "definitions": {
            "title": "2. Definities",
            "description": "\"U\" verwijst naar de persoon die toegang krijgt tot of gebruik maakt van de Dienst, of het bedrijf of andere juridische entiteit namens welke deze persoon toegang krijgt tot of gebruik maakt van de Dienst, indien van toepassing."
          },
          "accountRegistration": {
            "title": "3. Accountregistratie",
            "description": "Wanneer u bij ons een account aanmaakt, moet u nauwkeurige, volledige en actuele informatie verstrekken. Het niet naleven hiervan vormt een schending van de Voorwaarden, wat kan leiden tot onmiddellijke beëindiging van uw account op onze Dienst."
          },
          "useOfService": {
            "title": "4. Gebruik van de Dienst",
            "description": "U stemt ermee in de Dienst alleen te gebruiken voor legale doeleinden en in overeenstemming met deze Voorwaarden. U stemt ermee in de Dienst niet te gebruiken:",
            "list": [
              "Op een manier die in strijd is met toepasselijke nationale of internationale wet- of regelgeving.",
              "Om reclame- of promotiemateriaal te verzenden of te laten verzenden, inclusief \"junk mail\", \"kettingbrieven\", \"spam\" of andere vergelijkbare verzoeken.",
              "Om zich voor te doen als of te proberen zich voor te doen als eCom IT, een medewerker van eCom IT, een andere gebruiker, of enige andere persoon of entiteit.",
              "Op een manier die inbreuk maakt op de rechten van anderen, of op enige wijze illegaal, bedreigend, frauduleus of schadelijk is, of in verband met enig onwettig, illegaal, frauduleus of schadelijk doel of activiteit."
            ]
          },
          "intellectualProperty": {
            "title": "5. Intellectueel eigendom",
            "description": "De Dienst en zijn originele inhoud, functies en functionaliteit zijn en blijven het exclusieve eigendom van eCom IT en zijn licentiegevers. De Dienst is beschermd door auteursrecht, handelsmerk en andere wetten van zowel Nederland als andere landen. Onze handelsmerken en handelsnaam mogen niet worden gebruikt in verband met enig product of dienst zonder voorafgaande schriftelijke toestemming van eCom IT."
          },
          "userContent": {
            "title": "6. Gebruikersinhoud",
            "description": "Onze Dienst kan u toestaan om bepaalde informatie, tekst, afbeeldingen, video's of ander materiaal (\"Inhoud\") te plaatsen, te linken, op te slaan, te delen en anderszins beschikbaar te maken. U bent verantwoordelijk voor de Inhoud die u plaatst op of via de Dienst, inclusief de wettelijkheid, betrouwbaarheid en geschiktheid ervan."
          },
          "linksToOtherWebsites": {
            "title": "7. Links naar andere websites",
            "description": "Onze Dienst kan links bevatten naar websites of diensten van derden die niet eigendom zijn van of beheerd worden door eCom IT. eCom IT heeft geen controle over en aanvaardt geen verantwoordelijkheid voor de inhoud, het privacybeleid of de praktijken van websites of diensten van derden. U erkent en gaat ermee akkoord dat eCom IT niet verantwoordelijk of aansprakelijk zal zijn, direct of indirect, voor enige schade of verlies veroorzaakt of vermeend veroorzaakt door of in verband met het gebruik van of het vertrouwen op dergelijke inhoud, goederen of diensten beschikbaar op of via dergelijke websites of diensten."
          },
          "termination": {
            "title": "8. Beëindiging",
            "description": "Wij kunnen uw account onmiddellijk beëindigen of opschorten, zonder voorafgaande kennisgeving of aansprakelijkheid, om welke reden dan ook, inclusief zonder beperking als u de Voorwaarden schendt. Bij beëindiging vervalt onmiddellijk uw recht om de Dienst te gebruiken."
          },
          "limitationOfLiability": {
            "title": "9. Beperking van aansprakelijkheid",
            "description": "In geen geval zullen eCom IT, noch haar directeuren, werknemers, partners, agenten, leveranciers of gelieerde ondernemingen aansprakelijk zijn voor enige indirecte, incidentele, speciale, gevolg- of punitieve schade, inclusief zonder beperking, verlies van winst, gegevens, gebruik, goodwill of andere immateriële verliezen, voortvloeiend uit uw toegang tot of gebruik van of onvermogen om toegang te krijgen tot of gebruik te maken van de Dienst."
          },
          "disclaimer": {
            "title": "10. Disclaimer",
            "description": "Uw gebruik van de Dienst is op eigen risico. De Dienst wordt geleverd op een \"AS IS\" en \"AS AVAILABLE\" basis. De Dienst wordt geleverd zonder garanties van enige soort, hetzij expliciet of impliciet, inclusief, maar niet beperkt tot, impliciete garanties van verkoopbaarheid, geschiktheid voor een bepaald doel, niet-inbreuk of prestaties."
          },
          "governingLaw": {
            "title": "11. Toepasselijk recht",
            "description": "Deze Voorwaarden worden beheerst door en geïnterpreteerd in overeenstemming met de wetten van Nederland, zonder rekening te houden met de bepalingen inzake conflictenrecht."
          },
          "changes": {
            "title": "12. Wijzigingen",
            "description": "Wij behouden ons het recht voor om deze Voorwaarden naar eigen goeddunken op elk moment te wijzigen of te vervangen. Als een herziening belangrijk is, zullen we proberen ten minste 30 dagen van tevoren kennisgeving te doen voordat nieuwe voorwaarden van kracht worden. Wat een belangrijke wijziging vormt, zal naar ons eigen goeddunken worden bepaald."
          },
          "detailedDisclaimer": {
            "title": "13. Disclaimer",
            "list": [
              "<b>Nauwkeurigheid van informatie:</b> Hoewel wij ernaar streven om nauwkeurige en actuele informatie te verstrekken op Vitadote.com, doen wij geen toezeggingen of garanties van welke aard dan ook, expliciet of impliciet, over de volledigheid, nauwkeurigheid, betrouwbaarheid, geschiktheid of beschikbaarheid van de informatie, producten, diensten of gerelateerde afbeeldingen op de website voor enig doel.",
              "<b>Gebruik op eigen risico:</b> Elk vertrouwen dat u stelt in dergelijke informatie is daarom strikt op eigen risico.",
              "<b>Medische disclaimer:</b> De informatie op Vitadote.com, inclusief details over Shilajit en de potentiële voordelen ervan, is uitsluitend bedoeld voor informatieve doeleinden en is niet bedoeld als vervanging voor advies van uw arts of andere zorgprofessional.",
              "<b>Productgebruik:</b> Onze Shilajit-producten zijn voedingssupplementen en zijn niet bedoeld om ziekten te diagnosticeren, behandelen, genezen of voorkomen.",
              "<b>Resultaten kunnen variëren:</b> De beschreven resultaten weerspiegelen persoonlijke ervaringen en kunnen verschillen.",
              "<b>Externe links:</b> Via deze website kunt u mogelijk links naar andere websites vinden die niet onder de controle van Vitadote.com vallen. Wij hebben geen controle over de aard, inhoud en beschikbaarheid van die sites. De opname van links impliceert niet noodzakelijkerwijs een aanbeveling of onderschrijving van de daarin geuite standpunten.",
              "<b>Beschikbaarheid van de website:</b> Er wordt alles aan gedaan om de website soepel draaiende te houden. Vitadote.com aanvaardt echter geen verantwoordelijkheid voor, en is niet aansprakelijk voor, het tijdelijk niet beschikbaar zijn van de website als gevolg van technische problemen buiten onze controle.",
              "<b>Wijzigingen in disclaimer:</b> Wij behouden ons het recht voor om deze disclaimer op elk moment te wijzigen. Eventuele wijzigingen worden op deze pagina geplaatst met een bijgewerkte herzieningsdatum."
            ],
            "note": "Door gebruik te maken van Vitadote.com geeft u aan dat u instemt met deze disclaimer. Als u niet akkoord gaat met deze disclaimer, gebruik dan onze website niet."
          },
          "copyrightNotice": {
            "title": "14. Auteursrechtvermelding",
            "description1": "© 2024 Vitadote® / eCom IT BV.",
            "description2": "De inhoud, lay-out, ontwerp, gegevens, databases en afbeeldingen op deze website zijn beschermd door Nederlandse en andere internationale intellectuele eigendomswetten en zijn eigendom van eCom IT BV of haar licentiegevers."
          },
          "contactUs": {
            "title": "15. Contact",
            "description": "Als u vragen heeft over deze Voorwaarden, neem dan contact met ons op via:",
            "email": "info@vitadote.com",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Nederland",
            "lastUpdated": "Laatst bijgewerkt: 19 augustus 2024"
          }
        }
      },
      "paymentPolicy": {
        "heading": "Betalingsbeleid",
        "seo": {
          "metaDescription": "Vitadote® Betalingsbeleid: veilige methoden, valuta-info, terugbetalingen en terugkerende betalingen. Bijgewerkt op 19 augustus 2024."
        },
        "policyContent": {
          "paymentMethods": {
            "title": "Betaalmethoden",
            "description": "U kunt uw bestelling betalen met de volgende betaalmethoden:",
            "list": [
              "American Express",
              "Apple Pay",
              "Google Pay",
              "iDEAL / Sofort",
              "Mastercard / Visa",
              "PayPal",
              "Shop Pay",
              "Union Pay"
            ],
            "description2": "Hoewel we andere creditcards niet rechtstreeks accepteren, biedt PayPal de mogelijkheid om de meest voorkomende creditcards te koppelen, waardoor u met deze kaarten via PayPal op onze website kunt betalen."
          },
          "paymentSecurity": {
            "title": "Betalingsbeveiliging",
            "description": "Alle transacties op onze website worden veilig verwerkt via onze betalingsdienstaanbieder, Mollie. Alle processen via Mollie voldoen aan het ISAE 3402-principe en -criteria en zijn gecertificeerd volgens de PCI DSS 3.2 Level 1 Service Provider-standaarden. Deze certificering is een internationaal erkende veiligheidsstandaard."
          },
          "orderVerification": {
            "title": "Orderverificatie",
            "description": "Alle betaalmethoden zijn beveiligd en gevalideerd. Om onze klanten te beschermen, kunnen sommige bestellingen een betalingsverificatieproces ondergaan. Als uw bestelling in de wacht wordt gezet, zullen wij snel contact met u opnemen om eventuele vertragingen in uw bestelling tot een minimum te beperken."
          },
          "currency": {
            "title": "Valuta",
            "description": "Bij het winkelen op onze website Vitadote.com worden alle betalingen verwerkt in euro's (EUR)."
          },
          "refundsAndCancellations": {
            "title": "Terugbetalingen en annuleringen",
            "description": "Voor informatie over ons terugbetalings- en annuleringsbeleid verwijzen wij u naar onze "
          },
          "recurringPayments": {
            "title": "Terugkerende betalingen",
            "description": "Als u zich heeft geabonneerd op een van onze diensten met terugkerende betalingen, wordt u automatisch gefactureerd volgens de door u gekozen frequentie (bijv. maandelijks, jaarlijks). U kunt uw abonnement op elk moment opzeggen door in te loggen op uw account of door contact op te nemen met ons klantenserviceteam."
          },
          "pricingAndTax": {
            "title": "Prijzen en belastingen",
            "description": "Alle prijzen op onze website zijn in euro's en inclusief de toepasselijke belastingen. Eventuele aanvullende douane- of invoerrechten voor internationale bestellingen zijn de verantwoordelijkheid van de klant."
          },
          "paymentIssues": {
            "title": "Betalingsproblemen",
            "description": "Als u problemen ondervindt met uw betaling, controleer dan het volgende:",
            "list": [
              "Zorg ervoor dat uw betalingsgegevens correct zijn ingevoerd.",
              "Controleer of uw betaalmethode voldoende saldo heeft.",
              "Ga na of uw bank de transactie om veiligheidsredenen heeft geblokkeerd."
            ],
            "followUp": "Als u problemen blijft ondervinden, neem dan contact op met ons klantenserviceteam."
          },
          "dataProtection": {
            "title": "Gegevensbescherming",
            "description": "We nemen de beveiliging van uw financiële gegevens serieus. Alle betalingsinformatie wordt versleuteld en veilig verwerkt. We slaan uw volledige creditcardgegevens niet op onze servers op. Voor meer informatie verwijzen wij u naar ons "
          },
          "changesToThisPolicy": {
            "title": "Wijzigingen in dit beleid",
            "description": "We kunnen dit Betalingsbeleid van tijd tot tijd bijwerken. We zullen u op de hoogte stellen van eventuele wijzigingen door het nieuwe Betalingsbeleid op deze pagina te plaatsen en de datum van \"Laatst bijgewerkt\" hieronder bij te werken."
          },
          "contactUs": {
            "title": "Contact",
            "description": "Als u vragen heeft over betalingen, neem dan contact met ons op via:",
            "email": "info@vitadote.com",
            "phone": "+31 20 244 3918",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Nederland",
            "lastUpdated": "Laatst bijgewerkt: 19 augustus 2024"
          }
        }
      },
      "refundPolicy": {
        "heading": "Terugbetalingsbeleid",
        "seo": {
          "metaDescription": "Ontdek Vitadote®'s unieke \"Behoud-Het\" terugbetalingsgarantie. 30 dagen tevredenheidsgarantie op alle aankopen. Lees ons klantvriendelijk beleid."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Introductie",
            "description": "Bij Vitadote® staat uw tevredenheid voorop. We zijn zo overtuigd van de kwaliteit van onze producten dat we een uniek en klantvriendelijk terugbetalingsbeleid aanbieden. Dit Terugbetalingsbeleid beschrijft de voorwaarden voor terugbetalingen van producten gekocht via onze website vitadote.com."
          },
          "keepItRefundGuarantee": {
            "title": "2. Onze \"Behoud-Het\" Terugbetalingsgarantie",
            "description": "Als u niet volledig tevreden bent met uw aankoop, kunt u binnen 30 dagen na levering een terugbetaling aanvragen - en u mag het product houden. We vertrouwen onze klanten en waarderen uw feedback, die ons helpt onze producten te verbeteren."
          },
          "refundPeriod": {
            "title": "3. Terugbetalingsperiode",
            "description": "U heeft 30 dagen vanaf de leveringsdatum om een terugbetaling aan te vragen als u niet tevreden bent met uw aankoop."
          },
          "refundEligibility": {
            "title": "4. Voorwaarden voor Terugbetaling",
            "description": "Om in aanmerking te komen voor onze \"Behoud-Het\" Terugbetaling:",
            "list": [
              "Moet u de terugbetaling binnen 30 dagen na levering aanvragen.",
              "Moet u een korte uitleg geven waarom u niet tevreden bent met het product."
            ]
          },
          "refundProcess": {
            "title": "5. Terugbetalingsproces",
            "description": "Om een terugbetaling te starten, volgt u deze stappen:",
            "list": [
              "Neem binnen 30 dagen na levering contact op met ons klantenserviceteam via <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a>.",
              "Geef uw ordernummer en een korte uitleg waarom u niet tevreden bent met het product.",
              "Ons team zal uw verzoek beoordelen en uw terugbetaling verwerken."
            ]
          },
          "refundMethod": {
            "title": "6. Terugbetalingsmethode",
            "description": "Zodra uw terugbetaling is goedgekeurd, wordt binnen 10 werkdagen automatisch een tegoed toegepast op uw oorspronkelijke betaalmethode."
          },
          "excessiveClaims": {
            "title": "7. Overmatige Claims",
            "description": "Hoewel we onze klanten vertrouwen, behouden we ons het recht voor om terugbetalingen te weigeren in gevallen van overmatige of misbruik makende claims."
          },
          "damagedOrDefectiveItems": {
            "title": "8. Beschadigde of Defecte Items",
            "description": "Als u een beschadigd of defect item ontvangt, neem dan onmiddellijk contact met ons op via <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a>. We sturen u kosteloos een vervangend product."
          },
          "lateOrMissingRefunds": {
            "title": "9. Late of Ontbrekende Terugbetalingen",
            "description": "Als u nog geen terugbetaling heeft ontvangen, controleer dan eerst opnieuw uw bankrekening. Neem vervolgens contact op met uw creditcardmaatschappij, het kan enige tijd duren voordat uw terugbetaling officieel is verwerkt. Als u dit alles heeft gedaan en u heeft uw terugbetaling nog steeds niet ontvangen, neem dan contact met ons op via <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a>."
          },
          "changesToThisPolicy": {
            "title": "10. Wijzigingen in dit Beleid",
            "description": "We behouden ons het recht voor om dit Terugbetalingsbeleid op elk moment te wijzigen. Veranderingen en verduidelijkingen zullen onmiddellijk van kracht worden zodra ze op de website zijn geplaatst."
          },
          "contactUs": {
            "title": "11. Contact",
            "description": "Als u vragen heeft over ons Terugbetalingsbeleid, neem dan contact met ons op:",
            "email": "info@vitadote.com",
            "phone": "+31 20 244 3918",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Nederland",
            "lastUpdated": "Laatst bijgewerkt: 19 augustus 2024"
          }
        }
      },
      "shippingPolicy": {
        "heading": "Verzendbeleid",
        "seo": {
          "metaDescription": "Ontdek Vitadote®'s verzendbeleid. Gratis verzending wereldwijd, snelle levering en duidelijke levertijden. Lees over onze verzendpraktijken en douane-informatie."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Introductie",
            "description": "Bij Vitadote.com verzenden we onze hoogwaardige producten vanuit Nederland met behulp van veilige verzendmethoden met track & trace. Dit beleid beschrijft onze verzendpraktijken, levertijden en gerelateerde informatie."
          },
          "freeShipping": {
            "title": "2. Gratis verzending",
            "description": "We bieden gratis verzending op alle bestellingen! Houd er rekening mee dat gratis verzending geen eventuele douanekosten omvat."
          },
          "shippingAddress": {
            "title": "3. Verzendadres",
            "description": "<strong>Belangrijk:</strong> We verzenden niet naar postbussen. Geef alstublieft een fysiek afleveradres op."
          },
          "orderProcessing": {
            "title": "4. Orderverwerking",
            "description": "Bestellingen worden binnen 1-2 werkdagen verwerkt voordat ze worden verzonden. De hieronder vermelde levertijden zijn schattingen vanaf de verzenddatum."
          },
          "deliveryTimes": {
            "title": "5. Levertijden",
            "description": "",
            "list": [
              "<strong>Nederland en België:</strong> Voor bestellingen geplaatst op werkdagen vóór 16:00 uur wordt uw Vitadote-product de volgende werkdag geleverd.",
              "<strong>Duitsland:</strong> Voor bestellingen geplaatst op werkdagen vóór 16:00 uur vindt levering meestal de volgende werkdag plaats. In sommige regio's kan het 1-2 dagen langer duren vanwege logistieke omstandigheden.",
              "<strong>Andere EU-landen:</strong> Voor bestellingen geplaatst op werkdagen vóór 16:00 uur vindt levering plaats binnen 2-4 werkdagen.",
              "<strong>Buiten de EU:</strong> Voor bestellingen geplaatst op werkdagen vóór 16:00 uur vindt levering plaats binnen 5-14 dagen, afhankelijk van vliegverbindingen.",
              "<strong>Azië:</strong> Levering binnenkort beschikbaar."
            ],
            "note": "<em>Let op:</em> We verzenden alleen op werkdagen (maandag tot en met vrijdag, uitgezonderd feestdagen). Bestellingen die in het weekend worden ontvangen, worden de eerstvolgende werkdag verwerkt."
          },
          "damagedDeliveries": {
            "title": "6. Beschadigde leveringen",
            "description": "Als uw Vitadote-pakket beschadigd aankomt, informeer ons dan onmiddellijk via e-mail op <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a>."
          },
          "shippingCostsAndDeliveryTimes": {
            "title": "7. Verzendkosten en levertijden per land",
            "description": "",
            "table": {
              "headers": ["Land/Regio", "Verzendkosten", "Levertijd"],
              "rows": [
                ["Nederland", "GRATIS", "2 dagen"],
                ["België", "GRATIS", "2-5 dagen"],
                ["Duitsland", "GRATIS", "2-5 dagen"],
                ["Verenigd Koninkrijk", "GRATIS", "2-5 dagen"],
                ["Frankrijk", "GRATIS", "2-5 dagen"],
                ["Spanje", "GRATIS", "7-10 dagen"],
                ["Italië", "GRATIS", "7-10 dagen"],
                ["Oostenrijk", "GRATIS", "2-5 dagen"],
                ["Polen", "GRATIS", "2-5 dagen"],
                ["Denemarken", "GRATIS", "2-5 dagen"],
                ["Zweden", "GRATIS", "2-5 dagen"],
                ["Finland", "GRATIS", "2-5 dagen"],
                ["Ierland", "GRATIS", "2-5 dagen"],
                ["Portugal", "GRATIS", "5-7 dagen"],
                ["Griekenland", "GRATIS", "7-10 dagen"],
                ["Tsjechië", "GRATIS", "2-5 dagen"],
                ["Hongarije", "GRATIS", "2-5 dagen"],
                ["Roemenië", "GRATIS", "4-7 dagen"],
                ["Bulgarije", "GRATIS", "8-10 dagen"],
                ["Zwitserland", "GRATIS", "2-5 dagen"],
                ["Noorwegen", "GRATIS", "2-5 dagen"]
              ]
            },
            "additionalInfo": "Levertijden naar andere landen die hierboven niet vermeld staan, zijn doorgaans 20-30 dagen. Alle verzendingen zijn gratis."
          },
          "customs": {
            "title": "8. Douane",
            "description": "Douanekosten, belastingen of andere heffingen zijn niet inbegrepen in de prijs.\n\nVoor bestellingen naar niet-EU-landen kunnen bij aankomst extra kosten in rekening worden gebracht. Helaas kunnen we de hoogte van deze kosten niet voorspellen. Neem contact op met uw lokale douanekantoor voor meer accurate informatie."
          },
          "brexitNotice": {
            "title": "9. Brexit-kennisgeving voor bestellingen naar het VK",
            "description": "Vanwege Brexit worden er extra douanerechten en afhandelingskosten in rekening gebracht voor alle bestellingen naar het VK met een totale waarde van €150 of meer (afhankelijk van de huidige wisselkoers). Houd er rekening mee dat de waarde van goederen die door de Britse douane wordt beoordeeld hoger kan zijn dan onze verkoopprijs, aangezien de oorspronkelijke waarde van elk product als basis wordt gebruikt."
          },
          "contactUs": {
            "title": "10. Neem contact met ons op",
            "description": "Als u vragen heeft over ons Verzendbeleid, neem dan contact met ons op via:",
            "email": "info@vitadote.com",
            "phone": "+31 20 244 3918",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Nederland",
            "lastUpdated": "Laatst bijgewerkt: 19 augustus 2024"
          }
        }
      },
    },
  },
  es: {
    translation: {
      header: {
        home: "Inicio",
        about: "Sobre nosotros",
        contact: "contacto",
        reseller: "Conviértete en revendedor",
      },
      common: {
        buyNow: "Comprar ahora",
        contactUs: "Contáctenos",
        products: "productos"
      },
      homePage: {
        seo: {
          pageTitle: "Bienvenido a Vitadote®",
          metaTitle: "Descubra la resina Vitadote® Shilajit – vitadote",
          metaDescription:
            "Descubra la resina Shilajit Vitadote®: poder puro del Altai e Himalaya. Rica en minerales, un suplemento natural para su estilo de vida saludable.",
        },
        hero: {
          accentHeading:
            "Experimente la Pureza de las Montañas con la Resina Shilajit de Vitadote<sup>®</sup>",
          mainHeading:
            "Descubra el Poder de la Naturaleza con la Resina Shilajit de Vitadote<sup>®</sup>",
          details:
            "Explore los Elementos Primordiales de la Naturaleza con la Resina Shilajit de Vitadote<sup>®</sup>. Su conexión con el auténtico Shilajit, cuidadosamente obtenido de las imponentes formaciones rocosas de las montañas de Altai y el Himalaya. Nuestro Shilajit contiene minerales naturales como hierro, calcio, magnesio, selenio y manganeso, así como ácido fúlvico, y se procesa con respeto por la naturaleza y las tradiciones centenarias.",
        },
        nutrition: {
          heading: "El Valor Nutricional del Shilajit",
          accentHeading:
            "Contribución a la CDR de 3 porciones de 0,3 gramos cada una",
          details:
            "La Resina Shilajit de Vitadote<sup>®</sup> también tiene una composición natural de minerales. Con una cuidadosa selección de nuestro Shilajit, nos aseguramos de que disfrute, entre otros beneficios, de:",
          cards: {
            iron: {
              title: "Hierro",
              description:
                "Contribuye a la formación normal de glóbulos rojos y hemoglobina. Nuestro Shilajit proporciona el 30,87% de la cantidad diaria recomendada (CDR) de hierro por dosis diaria de 0,9 gramos.",
            },
            calcium: {
              title: "Calcio",
              description:
                "Importante para la función muscular normal y juega un papel en el proceso de división y especialización celular. Nuestra Resina Shilajit contiene el 72,06% de la CDR de calcio por dosis diaria de 0,9 gramos.",
            },
            magnesium: {
              title: "Magnesio",
              description:
                "Importante para el mantenimiento de huesos y dientes normales, y contribuye a la síntesis normal de proteínas. Nuestro producto proporciona el 108,24% de la CDR de magnesio por dosis diaria de 0,9 gramos.",
            },
            selenium: {
              title: "Selenio",
              description:
                "Contribuye al funcionamiento normal del sistema inmunológico y a la protección de las células contra el estrés oxidativo. Con nuestra Resina Shilajit, obtiene el 22,92% de la CDR de selenio por dosis diaria de 0,9 gramos.",
            },
            manganese: {
              title: "Manganeso",
              description:
                "Contribuye a la formación normal del tejido conectivo y a la protección de las células contra el estrés oxidativo. En nuestra Resina Shilajit encontrará el 33,75% de la CDR de manganeso por dosis diaria de 0,9 gramos.",
            },
          },
        },
        whatisShilajit: {
          heading: "DESCUBRA EL ORIGEN",
          paragraph1:
            "Deje que la Resina Shilajit de Vitadote<sup>®</sup> sea un complemento a su buena rutina. Una buena rutina consiste en un patrón saludable de alimentación y ejercicio. Vitadote<sup>®</sup> Shilajit puede ser un complemento a esto, directamente de la naturaleza virgen, y lo conecta con una tradición que ha resistido el paso del tiempo.",
          paragraph2: {
            bold: "Shilajit como complemento a Su Patrón Alimentario",
            text: "Vitadote<sup>®</sup> se esfuerza por complementar sus elecciones alimentarias con Shilajit, un producto natural tradicionalmente valorado. En caso de duda, consulte con un médico y siga siempre las recomendaciones del envase.",
          },
          paragraph3: {
            bold: "Ácido fúlvico en la Resina Shilajit de Vitadote<sup>®</sup>",
            text1:
              "La Resina Shilajit de Vitadote<sup>®</sup> contiene principalmente ácido fúlvico, además de ácido húmico y minerales.",
            text2:
              "información específica sobre la cantidad de ácido fúlvico, ácido húmico y minerales en nuestro producto o visite nuestra ",
            productPage: "página de productos",
          },
        },
        instructions: {
          heading: "Instrucciones de uso",
          details:
            "Recomendamos tomar 3 veces al día una pequeña cantidad de 0,3 gramos de Resina Shilajit de Vitadote<sup>®</sup>. Disuélvala directamente bajo la lengua o en un vaso de agua sin cloro para una absorción óptima.",
          step1:
            "Utiliza la espátula para tomar la dosis recomendada del frasco.",
          step2:
            "Disuélvelo en agua no clorada, té de hierbas o leche tibia (que no supere los 39°C), y bébelo. También se puede disolver bajo la lengua.",
          step3:
            "Para obtener resultados óptimos, recomendamos usar la resina de Shilajit regularmente.",
        },
        FAQS: {
          heading: "Preguntas Frecuentes",
          heading1: "Responsable y Orientado a la Calidad",
          paragraph1:
            "En Vitadote<sup>®</sup>, nos esforzamos por alcanzar los más altos estándares de calidad para nuestro Shilajit. Somos cuidadosos en seguir las regulaciones vigentes para suplementos dietéticos en los Países Bajos. Nuestros productos se someten a rigurosos controles de calidad para cumplir con los estándares de seguridad alimentaria requeridos. Estamos orgullosos de poder respaldar nuestro enfoque cuidadoso con documentación relevante. Nos esforzamos por garantizar la pureza y calidad de cada lote de nuestro producto. Puede encontrar información detallada sobre la composición y características de calidad de nuestro producto en nuestro sitio web bajo las especificaciones del producto.",
          heading3:
            "Para más información y la lista completa de preguntas frecuentes, visite nuestra ",
          faqsPage: "Página de FAQ",
          faqs: {
            question1: "¿Qué es la Resina Shilajit de Vitadote<sup>®</sup>?",
            answer1:
              "La Resina Shilajit de Vitadote<sup>®</sup> es una sustancia natural resinosa que se cosecha de las formaciones rocosas en las montañas de Altai y el Himalaya. Contiene una composición única de minerales naturales: hierro, calcio, magnesio, selenio y manganeso, así como compuestos orgánicos como ácido fúlvico y ácido húmico. El Shilajit se ha utilizado durante siglos en prácticas tradicionales en diversas culturas.",
            question2:
              "¿Cómo integro y uso la Resina Shilajit de Vitadote<sup>®</sup> como complemento a mi rutina diaria?",
            answer2:
              "Una rutina diaria saludable consiste en una nutrición equilibrada y ejercicio regular. La Resina Shilajit de Vitadote<sup>®</sup> puede ser un complemento a esto. Si elige usar Shilajit, recomendamos tomar 3 veces al día una porción de 0,3 gramos (aproximadamente del tamaño de un grano de arroz a un guisante). Puede disolver la Resina Shilajit de Vitadote<sup>®</sup> bajo su lengua o mezclarla en un vaso de agua sin cloro u otra bebida de su elección (hasta un máximo de 39 °C).",
            question3:
              "¿Cómo almaceno la Resina Shilajit de Vitadote<sup>®</sup>?",
            answer3:
              "Guárdela en un lugar fresco y seco, fuera de la luz solar directa.",
            question4:
              "¿De dónde proviene la Resina Shilajit de Vitadote<sup>®</sup>?",
            answer4:
              "Nuestro Shilajit se cosecha de las formaciones rocosas de las montañas de Altai y el Himalaya.",
            question5:
              "¿Se puede combinar la Resina Shilajit de Vitadote<sup>®</sup> con otros suplementos?",
            answer5:
              "En caso de duda, consulte con un médico y siga siempre las recomendaciones del envase.",
          },
          readMore: "LEER MÁS Preguntas frecuentes",
        },
        subscription: {
          heading: "Únase al Movimiento",
          details:
            "Descubra por sí mismo las propiedades distintivas de la Resina Shilajit de Vitadote<sup>®</sup> y forme parte de nuestra comunidad que reconoce el valor de los ingredientes naturales.",
          joinNow: "Regístrate ahora",
          placeholder: "Introduce tu correo electrónico",
        },
      },
      aboutPage: {
        seo: {
          metaDescription:
            "Descubra Vitadote®: comprometidos con Shilajit puro del Altai e Himalaya. Conozca nuestra misión y prácticas de cosecha sostenibles.",
        },
        banner: {
          mainHeading:
            "Vitadote<sup>®</sup>: Pioneros de la Resina Shilajit Pura",
          subHeading: "Nuestra Misión: Pureza desde las Montañas hasta Usted",
          details:
            "En Vitadote<sup>®</sup>, estamos dedicados a entregar la Resina Shilajit en su forma más auténtica. Inspirados por tradiciones centenarias, cada frasco de Resina Shilajit de Vitadote<sup>®</sup> ofrece una conexión con el origen natural de esta sustancia única.",
        },
        infoSection: {
          heading: "De la Montaña a Su Rutina Diaria",
          details:
            "Una buena rutina consiste en un patrón saludable de alimentación y ejercicio. Vitadote<sup>®</sup> puede ser un complemento a esto. Nuestro Shilajit proviene de las regiones altas de las montañas de Altai y el Himalaya, áreas conocidas por su naturaleza virgen. Llevamos cuidadosamente esta sustancia natural, con su composición única de minerales y compuestos orgánicos como el ácido fúlvico, a su hogar.",
          commitmentHeading: "Compromiso con la Calidad y el Cuidado",
          commitments1: "En Vitadote<sup>®</sup>, valoramos enormemente la calidad y pureza de nuestro Shilajit. Nuestros productos son cuidadosamente controlados para cumplir con los estándares de seguridad alimentaria vigentes. Nos esforzamos por ser transparentes sobre nuestros procesos y controles de calidad.",
          commitments2: "Aunque todos los suplementos dietéticos deben cumplir con las normas legales, nos comprometemos a hacer demostrable nuestro enfoque cuidadoso. Esto refleja nuestra dedicación a la autenticidad y la transparencia.",
          commitments3: "Para obtener información detallada sobre las propiedades específicas de nuestro producto, le invitamos a consultar las especificaciones del producto en nuestro sitio web.",
        },
        featuresSection: {
          feature1: {
            icon: "GppGoodIcon",
            heading: "Prácticas Sostenibles, Cosecha Responsable",
            details:
              "La sostenibilidad y el respeto por el medio ambiente son centrales en nuestros métodos de cosecha. Este es nuestro compromiso, no solo con el producto, sino también con la tierra y sus habitantes.",
          },
          feature2: {
            icon: "fa-handshake",
            heading: "Nuestro Equipo",
            details:
              "Compartimos con gusto nuestro conocimiento sobre las características naturales del Shilajit y ofrecemos asesoramiento para su integración en un régimen diario equilibrado. Un buen régimen consiste en un patrón saludable de alimentación y ejercicio. El uso de la Resina Shilajit de Vitadote<sup>®</sup> puede ser un complemento a esto.",
          },
          feature3: {
            icon: "GroupsIcon",
            heading: "La Promesa de Vitadote<sup>®</sup>",
            details:
              "Cada envase de Resina Shilajit de Vitadote<sup>®</sup> representa nuestra promesa de calidad y claridad. Nos esforzamos por acercarle al origen natural de nuestros ingredientes cuidadosamente recolectados.",
          },
        },
      },
      faqsPage: {
        seo: {
          metaDescription:
            "Preguntas frecuentes sobre la resina Shilajit Vitadote®. Aprenda sobre uso, beneficios y seguridad de nuestro suplemento natural.",
        },
        banner: {
          heading:
            "Bienvenido a la página de preguntas frecuentes de Vitadote<sup>®</sup> Shilajit Resin",
          description1:
            "¿Tienes curiosidad sobre Vitadote<sup>®</sup> Shilajit Resin y cómo puede complementar tu rutina diaria? Esta página está diseñada para responder a todas tus preguntas sobre este excepcional suplemento natural. Desde su composición única de minerales y compuestos orgánicos hasta consejos de almacenamiento y compatibilidad con otros suplementos, aquí lo encontrarás todo.",
          description2:
            "Descubre cómo Vitadote<sup>®</sup> Shilajit Resin es cuidadosamente obtenido, probado en laboratorio y diseñado para satisfacer tus necesidades de bienestar. Ya sea que busques instrucciones detalladas de uso, información de seguridad o características del producto, nuestra página de preguntas frecuentes te ofrece la claridad y confianza que necesitas.",
        },
        faqSection: {
          title: "Preguntas Frecuentes sobre la Resina Shilajit de Vitadote®",
          faqs: [
            {
              question: "¿Qué es el Shilajit?",
              answer:
                "El Shilajit es una sustancia natural resinosa conocida por su contenido de Hierro, Calcio, Magnesio, Selenio, Manganeso, Ácido Fúlvico y Ácido Húmico. Ha sido valorado durante siglos en diferentes culturas por su composición única y proviene de las formaciones rocosas de las montañas de Altai y el Himalaya.",
            },
            {
              question: "¿Cómo uso la Resina Shilajit de Vitadote®?",
              answer:
                "Recomendamos tomar 3 veces al día una cantidad de 0,3 gramos (una porción del tamaño de un grano de arroz a un guisante). Puede disolver la Resina Shilajit de Vitadote® bajo la lengua o en un vaso de agua sin cloro u otra bebida de su elección (hasta un máximo de 39 °C).",
            },
            {
              question:
                "¿Puedo combinar la Resina Shilajit de Vitadote® con otros suplementos?",
              answer:
                "Aunque el Shilajit a menudo se combina con otros suplementos, en caso de duda, consulte con un médico y siga siempre las recomendaciones del envase.",
            },
            {
              question:
                "¿Hay efectos secundarios conocidos de la Resina Shilajit de Vitadote®?",
              answer:
                "La Resina Shilajit de Vitadote® generalmente se tolera bien cuando se usa según la dosis recomendada. La sensibilidad individual puede variar. En caso de duda, consulte con un médico y siga siempre las recomendaciones del envase.",
            },
            {
              question: "¿Cómo almaceno la Resina Shilajit de Vitadote®?",
              answer:
                "Debe almacenarse en un lugar fresco y seco, lejos de la luz solar directa. Mantener fuera del alcance de los niños.",
            },
            {
              question:
                "¿Puedo usar la Resina Shilajit de Vitadote® durante el embarazo o la lactancia?",
              answer:
                "Si está embarazada o amamantando, es importante ser cautelosa con los suplementos dietéticos. Le recomendamos encarecidamente que consulte a un profesional de la salud antes de comenzar a usar la Resina Shilajit de Vitadote®.",
            },
            {
              question:
                "¿La Resina Shilajit de Vitadote® es adecuada para vegetarianos y veganos?",
              answer:
                "Sí, la Resina Shilajit de Vitadote® es adecuada tanto para vegetarianos como para veganos.",
            },
            {
              question:
                "¿Cuánto tiempo puedo usar la Resina Shilajit de Vitadote® después de abrirla?",
              answer:
                "Siempre que se almacene correctamente, la Resina Shilajit de Vitadote® puede usarse durante un largo período después de abrirla. Siempre verifique la fecha de caducidad en el envase.",
            },
            {
              question: "¿La Resina Shilajit de Vitadote® es Halal?",
              answer:
                "La Resina Shilajit de Vitadote® es un producto vegetal y no contiene productos animales, por lo que debería ser adecuada para personas que siguen una dieta halal.",
            },
            {
              question:
                "¿La Resina Shilajit de Vitadote® contiene alérgenos o gluten?",
              answer:
                "La Resina Shilajit de Vitadote® está libre de alérgenos conocidos y gluten. El proceso de producción garantiza que nuestro Shilajit esté libre de la mayoría de los alérgenos comunes, incluyendo nueces, gluten, lactosa y soja. Para obtener la información más actualizada sobre alérgenos, recomendamos verificar la información de la etiqueta o contactarnos directamente.",
            },
            {
              question:
                "¿Puede la Resina Shilajit de Vitadote® causar una reacción alérgica en personas con alergia al coco?",
              answer:
                "Aunque la Resina Shilajit de Vitadote® no contiene ingredientes que se consideren generalmente como alérgenos, contiene una pequeña cantidad de aceite de coco, que puede causar una reacción alérgica en personas con una alergia específica al coco. Si tiene alergia al coco, se recomienda tener precaución y consultar con su proveedor de atención médica antes de usar la Resina Shilajit de Vitadote®.",
            },
            {
              question:
                "¿Se puede usar la Resina Shilajit de Vitadote® en combinación con medicamentos?",
              answer:
                "Recomendamos consultar primero con su médico o farmacéutico antes de combinar la Resina Shilajit de Vitadote® con medicamentos.",
            },
          ],
          contact: "¿Tiene más preguntas?",
          contactLink: "Entonces contáctenos.",
        },
      },
      contactPage: {
        seo: {
          metaDescription:
            "Contacta a Vitadote® para preguntas o soporte sobre nuestros productos. Contáctanos por formulario, correo electrónico o teléfono. ¡Estamos aquí para ayudarte!",
        },
        banner: {
          heading: "CONTÁCTANOS",
          subHeading: "Estamos Aquí para Ayudarte",
          description:
            "¿Tienes preguntas, necesitas ayuda o quieres saber más sobre Vitadote® y nuestros productos? ¡Contáctanos hoy mismo! Puedes escribirnos por correo electrónico, llamarnos por teléfono o llenar el formulario de contacto a continuación. Nuestro equipo está listo para atenderte.",
        },
        infoSection: {
          infoHeading: "INFORMACIÓN",
          addressHeading: "Dirección",
          address: "Albert Huismanpad 166, 1502EK Zaandam, Países Bajos",
          phoneHeading: "Teléfono",
          emailHeading: "Correo Electrónico",
          formFields: {
            nameLabel: "Nombre",
            emailLabel: "Correo Electrónico",
            phoneLabel: "Teléfono",
            messageLabel: "Mensaje",
            submitButton: "ENVIAR AHORA",
          },
        },
      },
      "resellerPage": {
        "seo": {
          "metaDescription": "Sea revendedor exclusivo de Vitadote® Shilajit, suplemento natural premium. Obtenga derechos de venta, altos márgenes y apoyo de marketing."
        },
        "banner": {
          "heading": "CONVIÉRTASE EN REVENDEDOR DE VITADOTE<sup>®</sup> SHILAJIT EN EUROPA",
          "subHeading": "¿Está buscando un producto único y de alta calidad para ampliar su gama?",
          "description": "Vitadote®, productor líder y propietario de la marca de Shilajit premium, busca entusiastas revendedores y distribuidores de Shilajit en toda Europa. Nuestro programa exclusivo de revendedores ofrece oportunidades para la venta al por mayor de suplementos naturales."
        },
        "whysellHeading": "¿POR QUÉ VENDER VITADOTE<sup>®</sup> SHILAJIT?",
        "whySell": [
          {
            "icon": "/icons/authentication.svg",
            "feature": "Producto auténtico",
            "description": "Cosechado directamente de las montañas de Altai e Himalaya"
          },
          {
            "icon": "/icons/high-quality.svg",
            "feature": "Alta calidad",
            "description": "Controles de calidad estrictos y cumplimiento de las normativas de la UE"
          },
          {
            "icon": "/icons/growing-business.svg",
            "feature": "Mercado en crecimiento",
            "description": "Demanda creciente de suplementos alimenticios naturales"
          },
          {
            "icon": "/icons/proposition.svg",
            "feature": "Oferta distintiva",
            "description": "Composición única de minerales y ácido fúlvico"
          },
          {
            "icon": "/icons/brand-identity.svg",
            "feature": "Fuerte identidad de marca",
            "description": "Imagen profesional y apoyo de marketing"
          }
        ],
        "benefitsHeading": "Ventajas de convertirse en revendedor de Vitadote<sup>®</sup>",
        "benefits": [
          {
            "feature": "Derechos de venta exclusivos como distribuidor de Shilajit en su región"
          },
          {
            "feature": "Márgenes de beneficio atractivos para revendedores y mayoristas"
          },
          {
            "feature": "Acceso a nuestro extenso programa de revendedores con materiales de marketing y formación sobre productos"
          },
          {
            "feature": "Apoyo continuo de nuestro experimentado equipo"
          },
          {
            "feature": "Oportunidad de capitalizar el creciente interés en los suplementos naturales"
          }
        ],
        "lookingForHeading": "¿A quién buscamos?",
        "lookingFor": [
          {
            "title": "Minoristas establecidos y distribuidores de Shilajit en el sector de la salud"
          },
          {
            "title": "Vendedores en línea y mayoristas enfocados en suplementos alimenticios naturales"
          },
          {
            "title": "Distribuidores de productos naturales"
          },
          {
            "title": "Centros de bienestar y consultorios de salud"
          }
        ],
        "footerHeading": "¿Interesado en una asociación de reventa con Vitadote<sup>®</sup>?",
        "footerDescription": "Contáctenos hoy y descubra cómo puede convertirse en distribuidor de Shilajit o revendedor exclusivo a través de nuestro programa único de revendedores. Únase a la red Vitadote® y benefíciese de la creciente demanda de Shilajit premium en Europa."
      },
      "products": {
        "badges": {
          "topSelling": "Más vendido",
          "save8": "Ahorra €8",
          "save27": "Ahorra €27"
        },
        "images": [
          "/images/products-es/04. comparación del contenido de ácido fúlvico en shilajit.jpg",
          "/images/products-es/05. shilajit para antienvejecimiento y salud de la piel.jpg",
          "/images/products-es/11. Ingredientes de la resina Vitadote Shilajit.jpg",
          "/images/products-es/06. efectividad de cápsulas de shilajit orgánico vs resina.jpg",
          "/images/product-picture-3.jpg",
          "/images/products-es/10. efectos secundarios del shilajit y precauciones de seguridad.jpg",
          "/images/products-es/07. shilajit para aumento de testosterona y crecimiento muscular.jpg",
          "/images/products-es/08. beneficios del shilajit para el equilibrio hormonal femenino.jpg",
          "/images/products-es/09. shilajit para la función cognitiva y la salud cerebral.jpg",
        ],
        "ourProducts": "Nuestros productos",
        "productName1": "1x resina pura de shilajit",
        "productName2": "2x resina pura de shilajit",
        "productName3": "3x resina pura de shilajit",
        "relatedProducts": "Productos relacionados",
        "seo": {
          "metaTitle": "Resina Pura de Shilajit | Origen del Altái e Himalaya | Ácido Fúlvico por Vitadote®",
          "metaDescription": "Descubra la Resina de Shilajit Vitadote® de las montañas del Altái y el Himalaya. Probada en laboratorio, protegida por UV y rica en ácido fúlvico y minerales naturales.",
          "slug1": "1x-pure-shilajit-resin",
          "slug2": "2x-pure-shilajit-resin",
          "slug3": "3x-pure-shilajit-resin",
        },
        "banner": {
          "heading": "Resina Pura de Shilajit | Origen del Altái e Himalaya | Ácido Fúlvico por Vitadote®",
          "keyQuality": "Principales Cualidades de la Resina de Shilajit Vitadote®",
          "benefits": [
            {
              "title": "Directamente de las Montañas del Altái y el Himalaya",
              "description": "Cosechada en regiones montañosas vírgenes, donde el Shilajit adquiere su rica composición mineral."
            },
            {
              "title": "Envasada en Vidrio Protector UV",
              "description": "Empacada en vidrio ultravioleta para preservar la potencia del producto al bloquear los rayos de luz dañinos."
            },
            {
              "title": "Calidad Probada en Laboratorio:",
              "description": "Cada lote es rigurosamente probado y cuenta con certificación HACCP, garantizando un producto de Shilajit puro y de alta calidad."
            },
            {
              "title": "Certificaciones KOAG/KAG y NVWA",
              "description": "Estas referencias confirman que nuestra Resina de Shilajit cumple con estrictos estándares de seguridad y calidad."
            }
          ]
        },
        "detailsTabs": {
          "description": {
            "title": "Descripción del Producto",
              "desciption": "La <b>Resina de Shilajit Vitadote®</b> es un complemento natural de alta calidad, proveniente de las regiones de gran altitud de las <b>montañas del Altái y el Himalaya.</b> Ofrece una forma pura y auténtica de Shilajit, rica en minerales esenciales como <b>hierro, calcio, magnesio, selenio, manganeso, zinc</b>, y <b>ácido fúlvico</b>que lleva el poder de la naturaleza a su rutina diaria de bienestar.",
            "features": [
              {
                "title": "Modo de Uso",
                "description": "Para obtener resultados óptimos, tome <b>0.3 gramos</b> (aproximadamente del tamaño de un grano de arroz a un guisante) tres veces al día. Disuelva bajo la lengua o mezcle en <b>agua no clorada</b> o en una <b>bebida de su elección (hasta 39°C).</b>"
              },
              {
                "title": "Puro y Confiable",
                "description": "<b>Vitadote®</b> se compromete a ofrecer una resina de Shilajit auténtica y de origen responsable. Nos aseguramos de que cada frasco cumpla con nuestros altos estándares de calidad y pureza, para que pueda disfrutar de Shilajit lo más cercano posible a su forma natural."
              },
              {
                "title": "Descubra la Esencia de Shilajit con Vitadote®",
                "description": "Elija la <b>Resina de Shilajit Vitadote®</b> para enriquecer su viaje de bienestar con Shilajit puro y sin procesar, directamente desde las montañas del <b>Altái y el Himalaya.</b>"
              }
            ]
          },
          "reviews": {
            "title": "RESEÑAS",
            "customersReviews": "Comentarios de los clientes",
            "verifiedPurchases": "Todo de compras verificadas",
            "allReviewsWithDetails": "Todas las reseñas con detalles",
            customerReviews: [
              {
                "clientName": "Hassan Iqbal Hashmi",
                "date": "2023-05-16",
                "profile_image": "https://lh3.googleusercontent.com/a-/ACB-R5Tsv5u4-Z7qiRmBTw5JSsm7JolJNfZNeVWlzZIefiY=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Finally find pure Shilajit Resins. It has done what was expected. Thank you."
              },
              {
                "clientName": "Rebecca Sperry",
                "date": "2023-05-03",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxZgq-bSMicabswNP3HG1r1k8p7M6ekBDc-DYVx_=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Shilajit is een super resin, ik neem elke ochtend op mij werk, en ja hoor het helpt met alles, mij houd en haar is veel beter al binnen 3 weken!"
              },
              {
                "clientName": "Doeke Leerink",
                "date": "2023-03-09",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxbqo1czKEDR9BiUlb0FgAUH2ekolUQruV6U-Kod=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Na veel zoeken hier het echte product gevonden. De website geeft uitgebreide informatie en er wordt snel geleverd. Sterk aan te raden."
              },
              {
                "clientName": "Alex Berkov",
                "date": "2023-03-07",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxYgIJuXgqYb5Hg505DuNAp9quCSFrs-AgkNJ34l=s120-c-c-rp-w64-h64-mo-ba3-br100",
                "stars": 5,
                "review": "Folosesc de aprox 1 luna shilajit de la Vitadote pot spune ca in prima faza am acoperit gustul shilajitului cu miere de albine 🙂 Efectele sale nu încetează sa tot apara, de la lipsa balonării pana la mai multa energie si focus/atentie. Mulțumesc."
              },
              {
                "clientName": "Maysoon Kelani",
                "date": "2023-02-21",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxaR9AJ46lfbFL_DYEXp8BiUns9E1HRaLVCpdJ6q=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Best product ever thanks alot!"
              },
              {
                "clientName": "Heba Khatib",
                "date": "2023-02-21",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxZksHg6l3jwZQAh6QZv4nkl7cZnzZqr3aRMka4y=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Super team and best product ever ❤️."
              },
              {
                "clientName": "Adrian Rosu Savinoiu",
                "date": "2023-02-21",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxbd7wCzON2KNosyp0gTka5yPGTdlh28C4hWJhmQ=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "L-am luat pentru sotia mea, prima imbunatatire pe care a simtit-o este ca nu mai are picioarele reci. E primul remediu incercat de ea care a reusit acest lucru. Gustul nu e atat de rau pe cat se spune mai ales daca e pus in ceai. Cutia si modul de prezentare i-au placut enorm. Produsul vine cu posta romana, deci nu ajunge chiar in 3 zile (nu din vina postei ci din cauza vamii). Vitadote a fost foarte profesionista din punctul de vedere al livrarii, link cu AWB atat posta din Olanda, am comunicat pe WhatsApp fara probleme."
              },
              {
                "clientName": "Daniela Răducănescu",
                "date": "2023-02-10",
                "profile_image": "https://lh3.googleusercontent.com/a-/ACB-R5RuEdC9WO1A6Y0OUlXUKO7s4z8jReTVqZekkR_2M1Q=s120-c-c-rp-w64-h64-mo-ba4-br100",
                "stars": 5,
                "review": "#shilajit @Vitadote Shilajitul este excelent! L-am și testat pentru autenticitate, iar la toate 3 testări rezultatele au arătat că este autentic. Am luat aseară un pahar de ceai cald in care am dizolvat shilajitul primit de la Vitadote. Nu am reușit să mai dorm până pe la ora 2 de noapte. Deci energizarea corpului este ca și cum aș fi băut o cană de ness. Comunicarea excelentă! Imediat ce au primit comanda, mi-au scris pe WhatsApp! Au grijă de client și s-au asigurat că am primit coletul. Minunat! Exact ce mi-am dorit! Mulțumesc, Vitadote!"
              },
              {
                "clientName": "dragos negrau",
                "date": "2023-02-08",
                "profile_image": "https://lh3.googleusercontent.com/a-/ACB-R5RKX3q3g1OFHa_YAA-D6Oa9M9cVSnW13vSDUABr=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Your product it’s life changer! Super team! Fast shipping..."
              }
            ]
          }
        }
      },
      "privacyPolicy": {
        "heading": "Política de Privacidad",
        "seo": {
          "metaDescription": "Política de privacidad de Vitadote®: cómo recopilamos, usamos y protegemos sus datos. Información sobre sus derechos y cumplimiento del RGPD."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Introducción",
            "description1": "En Vitadote®, nos comprometemos a proteger su privacidad y salvaguardar sus datos personales. Esta Política de Privacidad explica cómo recopilamos, utilizamos, divulgamos y protegemos su información personal cuando visita nuestro sitio web vitadote.com o utiliza nuestros servicios.",
            "description2": "eCom IT BV (\"eCom IT\", \"nosotros\", \"nos\" o \"nuestro\"), operando bajo el nombre comercial Vitadote®, es el responsable del tratamiento de sus datos personales tal como se describe en esta Política de Privacidad."
          },
          "informationWeCollect": {
            "title": "2. Información que recopilamos",
            "description": "Podemos recopilar los siguientes tipos de información personal:",
            "list": [
              "Información de identidad y contacto (por ejemplo, nombre, dirección de correo electrónico, número de teléfono, dirección postal)",
              "Información de cuenta (por ejemplo, nombre de usuario, contraseña)",
              "Datos de pedidos y transacciones",
              "Información de pago",
              "Comunicaciones con nosotros (por ejemplo, consultas de servicio al cliente)",
              "Datos de uso (por ejemplo, cómo utiliza nuestro sitio web)",
              "Datos técnicos (por ejemplo, dirección IP, tipo de navegador)"
            ]
          },
          "howWeUse": {
            "title": "3. Cómo utilizamos su información",
            "description": "Utilizamos su información personal para los siguientes propósitos:",
            "list": [
              "Para procesar y entregar sus pedidos",
              "Para gestionar su cuenta con nosotros",
              "Para proporcionar atención al cliente",
              "Para informarle sobre cambios importantes en nuestros servicios",
              "Para mejorar nuestro sitio web y nuestros servicios",
              "Para prevenir y detectar fraudes",
              "Para cumplir con nuestras obligaciones legales"
            ]
          },
          "legalBases": {
            "title": "4. Bases legales para el procesamiento",
            "description": "Procesamos sus datos personales basándonos en uno o más de los siguientes fundamentos legales:",
            "list": [
              "Ejecución de un contrato con usted",
              "Nuestros intereses comerciales legítimos",
              "Cumplimiento de una obligación legal",
              "Su consentimiento (cuando sea necesario)"
            ],
            "extraInfo": "Vitadote® cumple con el Reglamento General de Protección de Datos (RGPD) de la Unión Europea. Nos tomamos en serio su privacidad y nos aseguramos de que todas nuestras actividades de procesamiento de datos estén en línea con el RGPD. Esto incluye recopilar solo los datos necesarios, implementar medidas de seguridad apropiadas, respetar sus derechos como interesado y ser transparentes sobre nuestras prácticas de procesamiento de datos."
          },
          "sharingInfo": {
            "title": "5. Compartir su información",
            "description": "Podemos compartir su información personal con:",
            "list": [
              "Nuestros proveedores de servicios (por ejemplo, proveedores de alojamiento, procesadores de pagos)",
              "Servicios de entrega",
              "Fuerzas del orden u otras agencias gubernamentales (si es legalmente requerido)"
            ],
            "note": "No vendemos su información personal a terceros."
          },
          "dataRetention": {
            "title": "6. Retención de datos",
            "description": "Conservamos su información personal durante el tiempo necesario para cumplir con los fines para los que fue recopilada, o para cumplir con los requisitos legales."
          },
          "dataSecurity": {
            "title": "7. Seguridad de los datos",
            "description": "Hemos implementado medidas técnicas y organizativas apropiadas para proteger su información personal contra el acceso no autorizado, la pérdida, el mal uso o la destrucción."
          },
          "yourRights": {
            "title": "8. Sus derechos",
            "description": "Bajo el RGPD y otras leyes de protección de datos aplicables, usted tiene ciertos derechos con respecto a sus datos personales, incluyendo:",
            "list": [
              "Derecho de acceso",
              "Derecho de rectificación",
              "Derecho de supresión",
              "Derecho a la limitación del tratamiento",
              "Derecho a la portabilidad de los datos",
              "Derecho de oposición al tratamiento"
            ],
            "extraInfo": "Para ejercer sus derechos, por favor contáctenos utilizando los detalles proporcionados al final de esta política."
          },
          "cookies": {
            "title": "9. Cookies y tecnologías similares",
            "description": "Utilizamos cookies y tecnologías similares para mejorar su experiencia en nuestro sitio web y para entender cómo se utiliza nuestro sitio web. Para obtener más información sobre nuestro uso de cookies, consulte nuestra Política de "
          },
          "internationalTransfers": {
            "title": "10. Transferencias internacionales de datos",
            "description": "Podemos transferir y procesar su información personal en países fuera del Espacio Económico Europeo (EEE)."
          },
          "policyChanges": {
            "title": "11. Cambios en esta Política de Privacidad",
            "description": "Podemos actualizar esta Política de Privacidad de vez en cuando. La versión más reciente siempre estará disponible en nuestro sitio web."
          },
          "contactUs": {
            "title": "12. Contáctenos",
            "text1": "Si tiene alguna pregunta, comentario o solicitud con respecto a esta Política de Privacidad o nuestras prácticas de privacidad, por favor contáctenos en:",
            "text2": "Correo electrónico: ",
            "text3": "Dirección postal: Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Países Bajos",
            "text4": "También tiene derecho a presentar una queja ante la Autoridad Holandesa de Protección de Datos.",
            "lastUpdated": "Última actualización: 19 de agosto de 2024"
          }
        }
      },
      "cookiePolicy": {
        "heading": "Política de Cookies",
        "seo": {
          "metaDescription": "Política de Cookies de Vitadote®: nuestro uso de cookies, su propósito y cómo gestionar sus preferencias. Actualizada el 19 de agosto de 2024."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Introducción",
            "description": "Esta Política de Cookies explica cómo Vitadote® (\"nosotros\", \"nos\" o \"nuestro\"), operado por eCom IT BV, utiliza cookies y tecnologías similares en nuestro sitio web vitadote.com. Esta política le proporciona información clara y completa sobre las cookies que utilizamos y los propósitos para los que las usamos."
          },
          "whatAreCookies": {
            "title": "2. ¿Qué son las cookies?",
            "description": "Las cookies son pequeños archivos de texto que se colocan en su dispositivo cuando visita un sitio web. Se utilizan ampliamente para hacer que los sitios web funcionen de manera más eficiente, así como para proporcionar información a los propietarios del sitio. Las cookies pueden ser \"persistentes\" o de \"sesión\"."
          },
          "howWeUseCookies": {
            "title": "3. Cómo utilizamos las cookies",
            "description": "Utilizamos cookies para los siguientes propósitos:",
            "list": [
              {
                "name": "Cookies esenciales",
                "purpose": "Son cookies necesarias para el funcionamiento de nuestro sitio web. Incluyen, por ejemplo, cookies que le permiten iniciar sesión en áreas seguras de nuestro sitio web o usar un carrito de compras."
              },
              {
                "name": "Cookies analíticas/de rendimiento",
                "purpose": "Nos permiten reconocer y contar el número de visitantes y ver cómo los visitantes se mueven por nuestro sitio web cuando lo utilizan."
              },
              {
                "name": "Cookies de funcionalidad",
                "purpose": "Se utilizan para reconocerle cuando regresa a nuestro sitio web. Esto nos permite personalizar nuestro contenido y recordar sus preferencias."
              },
              {
                "name": "Cookies de orientación",
                "purpose": "Estas cookies registran su visita a nuestro sitio web, las páginas que ha visitado y los enlaces que ha seguido."
              }
            ]
          },
          "thirdPartyCookies": {
            "title": "4. Cookies de terceros",
            "description": "Además de nuestras propias cookies, también podemos utilizar cookies de varios terceros para informar estadísticas de uso del Servicio, entregar anuncios en y a través del Servicio, y así sucesivamente."
          },
          "specificCookies": {
            "title": "5. ¿Qué cookies específicas utilizamos?",
            "description": "Aquí hay una lista de las principales cookies que utilizamos en vitadote.com:",
            "table": [
              { "type": "Cookie de sesión", "name": "PHPSESSID", "purpose": "Preserva el estado de la sesión del usuario a través de las solicitudes de página", "duration": "Sesión" },
              { "type": "Cookie de autenticación", "name": "auth_token", "purpose": "Mantiene al usuario conectado", "duration": "30 días" },
              { "type": "Cookie del carrito de compras", "name": "cart_items", "purpose": "Recuerda los artículos en el carrito de compras del usuario", "duration": "30 días" },
              { "type": "Cookie de preferencia de idioma", "name": "site_language", "purpose": "Recuerda la preferencia de idioma del usuario", "duration": "1 año" },
              { "type": "Cookies de Google Analytics", "name": "_ga, _gid, _gat", "purpose": "Se utilizan para distinguir usuarios y limitar la tasa de solicitudes", "duration": "_ga: 2 años, _gid: 24 horas, _gat: 1 minuto" },
              { "type": "Cookie de Facebook Pixel", "name": "_fbp", "purpose": "Utilizada por Facebook para entregar una serie de productos publicitarios", "duration": "3 meses" },
              { "type": "Cookie de consentimiento", "name": "cookie_consent", "purpose": "Recuerda si el usuario ha aceptado el aviso de cookies", "duration": "1 año" },
              { "type": "Cookie de recomendación de productos", "name": "recent_views", "purpose": "Almacena productos vistos recientemente para recomendaciones", "duration": "30 días" },
              { "type": "Cookie de rendimiento", "name": "page_load_time", "purpose": "Mide los tiempos de carga de las páginas para ayudar a mejorar el rendimiento del sitio web", "duration": "Sesión" },
              { "type": "Cookie de prueba A/B", "name": "ab_test_variant", "purpose": "Recuerda qué versión de una prueba dividida ha visto el usuario", "duration": "30 días" }
            ],
            "description2": "Tenga en cuenta que los servicios de terceros, como Google Analytics o las plataformas de redes sociales, pueden establecer cookies adicionales. Para obtener más información sobre estas cookies, consulte las políticas de privacidad respectivas de estos servicios.",

          },
          "choicesRegardingCookies": {
            "title": "6. Sus opciones con respecto a las cookies",
            "description": [
              "Si desea eliminar las cookies o indicar a su navegador web que elimine o rechace las cookies, visite las páginas de ayuda de su navegador web.",
              "Sin embargo, tenga en cuenta que si elimina las cookies o se niega a aceptarlas, es posible que no pueda utilizar todas las funciones que ofrecemos."
            ]
          },
          "changesToPolicy": {
            "title": "7. Cambios en nuestra Política de Cookies",
            "description": "Podemos actualizar nuestra Política de Cookies de vez en cuando. Le notificaremos cualquier cambio publicando la nueva Política de Cookies en esta página y actualizando la fecha de \"Última actualización\" en la parte superior de esta política."
          },
          "contactUs": {
            "title": "8. Contáctenos",
            "description": "Si tiene alguna pregunta sobre nuestro uso de cookies o esta Política de Cookies, póngase en contacto con nosotros en:",
            "email": "info@vitadote.com",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Países Bajos",
            "lastUpdated": "Última actualización: 19 de agosto de 2024"
          }
        }
      },
      "termsAndConditions": {
        "heading": "Términos y Condiciones",
        "seo": {
          "metaDescription": "Términos y Condiciones de Vitadote®: sus derechos y obligaciones al usar nuestro sitio web y servicios. Actualizado el 19 de agosto de 2024."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Introducción",
            "description": "Bienvenido a Vitadote.com. Estos Términos y Condiciones de Servicio (\"Términos\") rigen su uso del sitio web y los servicios de Vitadote® (colectivamente, el \"Servicio\") operados por eCom IT BV (\"eCom IT\", \"nosotros\", \"nos\" o \"nuestro\"). Al acceder o utilizar el Servicio, usted acepta estar sujeto a estos Términos. Si no está de acuerdo con alguna parte de los Términos, no podrá acceder al Servicio."
          },
          "definitions": {
            "title": "2. Definiciones",
            "description": "\"Usted\" se refiere al individuo que accede o utiliza el Servicio, o a la empresa u otra entidad legal en cuyo nombre dicho individuo está accediendo o utilizando el Servicio, según corresponda."
          },
          "accountRegistration": {
            "title": "3. Registro de cuenta",
            "description": "Cuando crea una cuenta con nosotros, debe proporcionar información precisa, completa y actualizada. No hacerlo constituye una violación de los Términos, lo que puede resultar en la terminación inmediata de su cuenta en nuestro Servicio."
          },
          "useOfService": {
            "title": "4. Uso del Servicio",
            "description": "Usted acepta utilizar el Servicio solo para fines lícitos y de acuerdo con estos Términos. Usted acepta no utilizar el Servicio:",
            "list": [
              "A. De cualquier manera que viole cualquier ley o regulación nacional o internacional aplicable.",
              "B. Para transmitir o procurar el envío de cualquier material publicitario o promocional, incluido cualquier \"correo basura\", \"cadena de cartas\", \"spam\" o cualquier otra solicitud similar.",
              "C. Para suplantar o intentar suplantar a eCom IT, a un empleado de eCom IT, a otro usuario o a cualquier otra persona o entidad.",
              "D. De cualquier manera que infrinja los derechos de otros, o que sea ilegal, amenazante, fraudulenta o dañina, o en relación con cualquier propósito o actividad ilegal, ilícita, fraudulenta o dañina."
            ]
          },
          "intellectualProperty": {
            "title": "5. Propiedad intelectual",
            "description": "El Servicio y su contenido original, características y funcionalidad son y seguirán siendo la propiedad exclusiva de eCom IT y sus licenciantes. El Servicio está protegido por derechos de autor, marcas comerciales y otras leyes tanto de los Países Bajos como de países extranjeros. Nuestras marcas comerciales y imagen comercial no pueden ser utilizadas en relación con ningún producto o servicio sin el consentimiento previo por escrito de eCom IT."
          },
          "userContent": {
            "title": "6. Contenido del usuario",
            "description": "Nuestro Servicio puede permitirle publicar, enlazar, almacenar, compartir y poner a disposición cierta información, texto, gráficos, videos u otro material (\"Contenido\"). Usted es responsable del Contenido que publique en o a través del Servicio, incluyendo su legalidad, fiabilidad y adecuación."
          },
          "linksToOtherWebsites": {
            "title": "7. Enlaces a otros sitios web",
            "description": "Nuestro Servicio puede contener enlaces a sitios web o servicios de terceros que no son propiedad ni están controlados por eCom IT. eCom IT no tiene control sobre, y no asume ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de cualquier sitio web o servicio de terceros. Usted además reconoce y acepta que eCom IT no será responsable, directa o indirectamente, por cualquier daño o pérdida causada o presuntamente causada por o en conexión con el uso o la confianza en cualquier contenido, bienes o servicios disponibles en o a través de cualquiera de dichos sitios web o servicios."
          },
          "termination": {
            "title": "8. Terminación",
            "description": "Podemos terminar o suspender su cuenta inmediatamente, sin previo aviso o responsabilidad, por cualquier motivo, incluyendo, sin limitación, si usted incumple los Términos. Tras la terminación, su derecho a utilizar el Servicio cesará inmediatamente."
          },
          "limitationOfLiability": {
            "title": "9. Limitación de responsabilidad",
            "description": "En ningún caso eCom IT, ni sus directores, empleados, socios, agentes, proveedores o afiliados, serán responsables por cualquier daño indirecto, incidental, especial, consecuente o punitivo, incluyendo, sin limitación, pérdida de beneficios, datos, uso, buena voluntad u otras pérdidas intangibles, resultantes de su acceso o uso o incapacidad de acceder o usar el Servicio."
          },
          "disclaimer": {
            "title": "10. Descargo de responsabilidad",
            "description": "Su uso del Servicio es bajo su propio riesgo. El Servicio se proporciona \"TAL CUAL\" y \"SEGÚN DISPONIBILIDAD\". El Servicio se proporciona sin garantías de ningún tipo, ya sean expresas o implícitas, incluyendo, pero no limitado a, garantías implícitas de comerciabilidad, idoneidad para un propósito particular, no infracción o curso de ejecución."
          },
          "governingLaw": {
            "title": "11. Ley aplicable",
            "description": "Estos Términos se regirán e interpretarán de acuerdo con las leyes de los Países Bajos, sin tener en cuenta sus disposiciones sobre conflictos de leyes."
          },
          "changes": {
            "title": "12. Cambios",
            "description": "Nos reservamos el derecho, a nuestra sola discreción, de modificar o reemplazar estos Términos en cualquier momento. Si una revisión es material, trataremos de proporcionar un aviso de al menos 30 días antes de que los nuevos términos entren en vigor. Lo que constituye un cambio material será determinado a nuestra sola discreción."
          },
          "detailedDisclaimer": {
            "title": "13. Aviso legal",
            "list": [
              "<b>Exactitud de la información:</b> Aunque nos esforzamos por proporcionar información precisa y actualizada en Vitadote.com, no hacemos representaciones ni garantías de ningún tipo, expresas o implícitas, sobre la integridad, precisión, confiabilidad, idoneidad o disponibilidad de la información, productos, servicios o gráficos relacionados contenidos en el sitio web para cualquier propósito.",
              "<b>Uso bajo su propio riesgo:</b> Cualquier confianza que deposite en dicha información es, por lo tanto, estrictamente bajo su propio riesgo. En ningún caso seremos responsables de ninguna pérdida o daño, incluyendo, sin limitación, pérdidas o daños indirectos o consecuentes, o cualquier pérdida o daño que surja de la pérdida de datos o beneficios derivados del uso de este sitio web o en conexión con él.",
              "<b>Aviso legal médico:</b> La información proporcionada en Vitadote.com, incluidos los detalles sobre el Shilajit y sus posibles beneficios, es solo para fines informativos y no pretende ser un sustituto del consejo de su médico u otro profesional de la salud. No debe utilizar la información de este sitio web para diagnosticar o tratar un problema de salud o enfermedad, ni para prescribir ningún medicamento u otro tratamiento.",
              "<b>Uso del producto:</b> Nuestros productos de Shilajit son suplementos dietéticos y no están destinados a diagnosticar, tratar, curar o prevenir ninguna enfermedad. Siempre consulte con un profesional de la salud calificado antes de comenzar cualquier programa de dieta, ejercicio o suplementos, antes de tomar cualquier medicamento o si tiene o sospecha que podría tener un problema de salud.",
              "<b>Los resultados pueden variar:</b> Los resultados descritos en este sitio web con respecto al uso de Shilajit son experiencias individuales, que reflejan las experiencias reales de quienes han utilizado nuestros productos. Sin embargo, los resultados individuales pueden variar. No afirmamos que sean resultados típicos que los consumidores generalmente lograrán.",
              "<b>Enlaces externos:</b> A través de este sitio web, es posible que pueda enlazar a otros sitios web que no están bajo el control de Vitadote.com. No tenemos control sobre la naturaleza, el contenido y la disponibilidad de esos sitios. La inclusión de cualquier enlace no implica necesariamente una recomendación ni respalda las opiniones expresadas en ellos.",
              "<b>Disponibilidad del sitio web:</b> Se hace todo lo posible para mantener el sitio web funcionando sin problemas. Sin embargo, Vitadote.com no asume ninguna responsabilidad por, y no será responsable de, la indisponibilidad temporal del sitio web debido a problemas técnicos fuera de nuestro control.",
              "<b>Cambios en el aviso legal:</b> Nos reservamos el derecho de hacer cambios a este aviso legal en cualquier momento. Cualquier cambio se publicará en esta página con una fecha de revisión actualizada."
            ],
            "note": "Al utilizar Vitadote.com, usted indica su aceptación de este aviso legal. Si no está de acuerdo con este aviso legal, por favor no utilice nuestro sitio web."
          },
          "copyrightNotice": {
            "title": "14. Aviso de derechos de autor",
            "description1": "© 2024 Vitadote® / eCom IT BV. Todos los derechos reservados.",
            "description2": "El contenido, diseño, datos, bases de datos y gráficos de este sitio web están protegidos por las leyes de propiedad intelectual holandesas y otras leyes internacionales, y son propiedad de eCom IT BV o sus licenciantes. Ninguna parte de este sitio web puede ser reproducida, distribuida o transmitida de ninguna forma o por ningún medio, incluyendo fotocopias, grabaciones u otros métodos electrónicos o mecánicos, sin el permiso previo por escrito de eCom IT BV, excepto en el caso de breves citas incorporadas en reseñas críticas y ciertos otros usos no comerciales permitidos por la ley de derechos de autor."
          },
          "contactUs": {
            "title": "15. Contáctenos",
            "description": "Si tiene alguna pregunta sobre estos Términos, por favor contáctenos en:",
            "email": "info@vitadote.com",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Países Bajos",
            "lastUpdated": "Última actualización: 19 de agosto de 2024"
          }
        }
      },

      "paymentPolicy": {
        "heading": "Política de Pagos",
        "seo": {
          "metaDescription": "Política de Pagos Vitadote®: métodos seguros, información de moneda, reembolsos y pagos recurrentes. Actualizada el 19 de agosto de 2024."
        },
        "policyContent": {
          "paymentMethods": {
            "title": "Métodos de pago",
            "description": "Puede pagar su pedido utilizando los siguientes métodos de pago:",
            "list": [
              "American Express",
              "Apple Pay",
              "Google Pay",
              "iDEAL / Sofort",
              "Mastercard / Visa",
              "PayPal",
              "Shop Pay",
              "Union Pay"
            ],
            "description2": "Aunque no aceptamos directamente otras tarjetas de crédito, PayPal ofrece la opción de vincular la mayoría de las tarjetas de crédito comunes, lo que le permite pagar con estas tarjetas a través de PayPal en nuestro sitio web."
          },
          "paymentSecurity": {
            "title": "Seguridad de pago",
            "description": "Todas las transacciones en nuestro sitio web se procesan de forma segura a través de nuestro proveedor de servicios de pago, Mollie. Todos los procesos a través de Mollie se adhieren al principio y criterios ISAE 3402 y están certificados según los estándares PCI DSS 3.2 Level 1 Service Provider. Esta certificación es un estándar de seguridad reconocido internacionalmente."
          },
          "orderVerification": {
            "title": "Verificación de pedidos",
            "description": "Todos los métodos de pago están asegurados y validados. Para proteger a nuestros clientes, algunos pedidos pueden someterse a un proceso de verificación de pago. Si su pedido se pone en espera, nos pondremos en contacto con usted rápidamente para minimizar cualquier posible retraso en su pedido."
          },
          "currency": {
            "title": "Moneda",
            "description": "Al comprar en nuestro sitio web Vitadote.com, todos los pagos se procesan en Euros (EUR)."
          },
          "refundsAndCancellations": {
            "title": "Reembolsos y cancelaciones",
            "description": "Para obtener información sobre nuestras políticas de reembolso y cancelación, consulte nuestra página de "
          },
          "recurringPayments": {
            "title": "Pagos recurrentes",
            "description": "Si se ha suscrito a alguno de nuestros servicios que implican pagos recurrentes, se le facturará automáticamente según la frecuencia que haya seleccionado (por ejemplo, mensual, anual). Puede cancelar su suscripción en cualquier momento iniciando sesión en su cuenta o contactando con nuestro equipo de atención al cliente."
          },
          "pricingAndTax": {
            "title": "Precios e impuestos",
            "description": "Todos los precios mostrados en nuestro sitio web están en Euros e incluyen los impuestos aplicables. Cualquier derecho de aduana o impuesto de importación adicional para pedidos internacionales es responsabilidad del cliente."
          },
          "paymentIssues": {
            "title": "Problemas de pago",
            "description": "Si experimenta algún problema con su pago, por favor compruebe lo siguiente:",
            "list": [
              "Asegúrese de que sus datos de pago se han introducido correctamente.",
              "Verifique que su método de pago tiene fondos suficientes.",
              "Compruebe si su banco ha bloqueado la transacción por razones de seguridad."
            ],
            "followUp": "Si continúa experimentando problemas, por favor contacte con nuestro equipo de atención al cliente."
          },
          "dataProtection": {
            "title": "Protección de datos",
            "description": "Nos tomamos muy en serio la seguridad de sus datos financieros. Toda la información de pago se encripta y se procesa de forma segura. No almacenamos los datos completos de su tarjeta de crédito en nuestros servidores. Para más información, consulte nuestra "
          },
          "changesToThisPolicy": {
            "title": "Cambios en esta política",
            "description": "Podemos actualizar esta Política de Pagos de vez en cuando. Le notificaremos cualquier cambio publicando la nueva Política de Pagos en esta página y actualizando la fecha de \"Última actualización\" que figura a continuación."
          },
          "contactUs": {
            "title": "Contáctenos",
            "description": "Si tiene alguna pregunta relacionada con los pagos, por favor contáctenos en :",
            "email": "info@vitadote.com",
            "phone": "+31 20 244 3918",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Países Bajos",
            "lastUpdated": "Última actualización: 19 de agosto de 2024"
          }
        }
      },
      "refundPolicy": {
        "heading": "Política de Reembolso",
        "seo": {
          "metaDescription": "Descubra la garantía única \"Quédeselo\" de Vitadote®. 30 días de garantía de satisfacción en todas las compras. Lea nuestra política."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Introducción",
            "description": "En Vitadote®, su satisfacción es nuestra máxima prioridad. Estamos tan seguros de la calidad de nuestros productos que ofrecemos una política de reembolso única y amigable para el cliente. Esta Política de Reembolso describe los términos y condiciones para los reembolsos de productos comprados en nuestro sitio web vitadote.com."
          },
          "keepItRefundGuarantee": {
            "title": "2. Nuestra Garantía de Reembolso \"Quédatelo\"",
            "description": "Si no está completamente satisfecho con su compra, puede solicitar un reembolso dentro de los 30 días posteriores a la entrega, y puede quedarse con el producto. Confiamos en nuestros clientes y valoramos sus comentarios, que nos ayudan a mejorar nuestros productos."
          },
          "refundPeriod": {
            "title": "3. Período de Reembolso",
            "description": "Tiene 30 días a partir de la fecha de entrega para solicitar un reembolso si no está satisfecho con su compra."
          },
          "refundEligibility": {
            "title": "4. Elegibilidad para el Reembolso",
            "description": "Para ser elegible para nuestro reembolso \"Quédatelo\":",
            "list": [
              "Debe solicitar el reembolso dentro de los 30 días posteriores a la entrega.",
              "Debe proporcionar una breve explicación de la razón por la cual no está satisfecho con el producto."
            ]
          },
          "refundProcess": {
            "title": "5. Proceso de Reembolso",
            "description": "Para iniciar un reembolso, siga estos pasos :",
            "list": [
              "Contacte a nuestro equipo de servicio al cliente en <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a> dentro de los 30 días posteriores a la entrega.",
              "Proporcione su número de pedido y una breve explicación de la razón por la cual no está satisfecho con el producto.",
              "Nuestro equipo revisará su solicitud y procesará su reembolso."
            ]
          },
          "refundMethod": {
            "title": "6. Método de Reembolso",
            "description": "Una vez que se apruebe su reembolso, se aplicará automáticamente un crédito a su método de pago original dentro de los 10 días hábiles."
          },
          "excessiveClaims": {
            "title": "7. Reclamaciones Excesivas",
            "description": "Aunque confiamos en nuestros clientes, nos reservamos el derecho de denegar reembolsos en casos de reclamaciones excesivas o abusivas."
          },
          "damagedOrDefectiveItems": {
            "title": "8. Artículos Dañados o Defectuosos",
            "description": "Si recibe un artículo dañado o defectuoso, contáctenos inmediatamente en <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a>. Le enviaremos un reemplazo sin costo adicional."
          },
          "lateOrMissingRefunds": {
            "title": "9. Reembolsos Tardíos o Faltantes",
            "description": "Si aún no ha recibido un reembolso, primero verifique nuevamente su cuenta bancaria. Luego, contacte a su compañía de tarjeta de crédito, ya que puede tomar algún tiempo antes de que su reembolso se publique oficialmente. Si ha hecho todo esto y aún no ha recibido su reembolso, contáctenos en <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a>."
          },
          "changesToThisPolicy": {
            "title": "10. Cambios en esta Política",
            "description": "Nos reservamos el derecho de modificar esta Política de Reembolso en cualquier momento. Los cambios y aclaraciones entrarán en vigencia inmediatamente después de su publicación en el sitio web."
          },
          "contactUs": {
            "title": "11. Contáctenos",
            "description": "Si tiene alguna pregunta relacionada con nuestra Política de Reembolso, contáctenos :",
            "email": "info@vitadote.com",
            "phone": "+31 20 244 3918",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Países Bajos",
            "lastUpdated": "Última actualización: 19 de agosto de 2024"
          }
        }
      },
      "shippingPolicy": {
        "heading": "Política de Envío",
        "seo": {
          "metaDescription": "Descubra la política de envío de Vitadote®. Envío gratuito mundial, entrega rápida y plazos claros. Conozca nuestras prácticas de envío e información aduanera."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Introducción",
            "description": "En Vitadote.com, enviamos nuestros productos de alta calidad desde los Países Bajos utilizando métodos de envío seguros con seguimiento. Esta política describe nuestras prácticas de envío, tiempos de entrega e información relacionada."
          },
          "freeShipping": {
            "title": "2. Envío gratuito",
            "description": "¡Ofrecemos envío gratuito en todos los pedidos! Tenga en cuenta que el envío gratuito no incluye posibles tasas de aduana."
          },
          "shippingAddress": {
            "title": "3. Dirección de envío",
            "description": "<strong>Importante :</strong> No enviamos a apartados de correos. Por favor, proporcione una dirección de entrega física."
          },
          "orderProcessing": {
            "title": "4. Procesamiento de pedidos",
            "description": "Los pedidos se procesan dentro de 1-2 días hábiles antes de ser enviados. Los tiempos de entrega indicados a continuación son estimaciones a partir de la fecha de envío."
          },
          "deliveryTimes": {
            "title": "5. Tiempos de entrega",
            "description": "",
            "list": [
              "<strong>Países Bajos y Bélgica :</strong> Para pedidos realizados en días hábiles antes de las 16:00 horas, su producto Vitadote será entregado al siguiente día hábil.",
              "<strong>Alemania :</strong> Para pedidos realizados en días hábiles antes de las 16:00 horas, la entrega generalmente ocurre al siguiente día hábil. En algunas regiones, puede tardar 1-2 días más debido a circunstancias logísticas.",
              "<strong>Otros países de la UE :</strong> Para pedidos realizados en días hábiles antes de las 16:00 horas, la entrega ocurre dentro de 2-4 días hábiles.",
              "<strong>Fuera de la UE :</strong> Para pedidos realizados en días hábiles antes de las 16:00 horas, la entrega ocurre dentro de 5-14 días, dependiendo de las conexiones de vuelo.",
              "<strong>Asia :</strong> Entrega próximamente disponible."
            ],
            "note": "<em>Tenga en cuenta :</em> Solo enviamos en días hábiles (de lunes a viernes, excluyendo festivos). Los pedidos recibidos durante el fin de semana se procesarán el siguiente día hábil."
          },
          "damagedDeliveries": {
            "title": "6. Entregas dañadas",
            "description": "Si su paquete Vitadote llega dañado, infórmenos inmediatamente por correo electrónico a <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a>."
          },
          "shippingCostsAndDeliveryTimes": {
            "title": "7. Costos de envío y tiempos de entrega por país",
            "description": "",
            "table": {
              "headers": ["País/Región", "Costo de envío", "Tiempo de entrega"],
              "rows": [
                ["Países Bajos", "GRATIS", "2 días"],
                ["Bélgica", "GRATIS", "2-5 días"],
                ["Alemania", "GRATIS", "2-5 días"],
                ["Reino Unido", "GRATIS", "2-5 días"],
                ["Francia", "GRATIS", "2-5 días"],
                ["España", "GRATIS", "7-10 días"],
                ["Italia", "GRATIS", "7-10 días"],
                ["Austria", "GRATIS", "2-5 días"],
                ["Polonia", "GRATIS", "2-5 días"],
                ["Dinamarca", "GRATIS", "2-5 días"],
                ["Suecia", "GRATIS", "2-5 días"],
                ["Finlandia", "GRATIS", "2-5 días"],
                ["Irlanda", "GRATIS", "2-5 días"],
                ["Portugal", "GRATIS", "5-7 días"],
                ["Grecia", "GRATIS", "7-10 días"],
                ["República Checa", "GRATIS", "2-5 días"],
                ["Hungría", "GRATIS", "2-5 días"],
                ["Rumanía", "GRATIS", "4-7 días"],
                ["Bulgaria", "GRATIS", "8-10 días"],
                ["Suiza", "GRATIS", "2-5 días"],
                ["Noruega", "GRATIS", "2-5 días"]
              ]
            },
            "additionalInfo": "Los tiempos de entrega a otros países no mencionados anteriormente son típicamente de 20-30 días. Todos los envíos son gratuitos."
          },
          "customs": {
            "title": "8. Aduanas",
            "description": "Los aranceles aduaneros, impuestos u otros cargos no están incluidos en el precio.\n\nPara pedidos a países fuera de la UE, es posible que se apliquen tarifas adicionales a la llegada. Desafortunadamente, no podemos predecir el monto de estos costos. Por favor, contacte a su oficina de aduanas local para obtener información más precisa."
          },
          "brexitNotice": {
            "title": "9. Aviso de Brexit para pedidos al Reino Unido",
            "description": "Debido al Brexit, se cobrarán aranceles aduaneros adicionales y costos de despacho para todos los pedidos al Reino Unido con un valor total de 150 € o más (dependiendo del tipo de cambio actual). Tenga en cuenta que el valor de los bienes evaluados por las aduanas del Reino Unido puede ser más alto que nuestro precio de venta, ya que se utiliza como base el valor original de cada producto."
          },
          "contactUs": {
            "title": "10. Contáctenos",
            "description": "Si tiene alguna pregunta relacionada con nuestra Política de Envío, por favor contáctenos en :",
            "email": "info@vitadote.com",
            "phone": "+31 20 244 3918",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Países Bajos",
            "lastUpdated": "Última actualización: 19 de agosto de 2024"
          }
        }
      }

    },
  },
  ptbr: {
    translation: {
      header: {
        home: "Início",
        about: "Sobre nós",
        contact: "CONTATO",
        reseller: "Torne-se um revendedor",
      },
      common: {
        buyNow: "Comprar agora",
        contactUs: "Contate-nos",
        products: "produtos"

      },
      homePage: {
        seo: {
          pageTitle: "Bem-vindo à Vitadote®",
          metaTitle: "Descubra a resina Shilajit Vitadote® – vitadote",
          metaDescription:
            "poder puro do Altai e Himalaia. Rica em minerais, um suplemento natural para seu estilo de vida saudável.",
        },
        hero: {
          accentHeading:
            "Experimente a Pureza das Montanhas com a Resina Shilajit Vitadote<sup>®</sup>",
          mainHeading:
            "Descubra o Poder da Natureza com a Resina Shilajit Vitadote<sup>®</sup>. ",
          details:
            "Explore os Elementos Primordiais da Natureza com a Resina Shilajit Vitadote<sup>®</sup>. Sua conexão com o Shilajit autêntico, cuidadosamente obtido das imponentes formações rochosas das montanhas Altai e Himalaia. Nosso Shilajit contém minerais naturais como ferro, cálcio, magnésio, selênio e manganês, além de ácido fúlvico, e é processado com respeito à natureza e às tradições centenárias.",
        },
        nutrition: {
          heading: "O Valor Nutricional do Shilajit",
          accentHeading:
            "Contribuição para a IDR de 3 porções de 0,3 gramas cada",
          details:
            "A Resina Shilajit Vitadote<sup>®</sup> também possui uma composição natural de minerais. Com uma seleção cuidadosa do nosso Shilajit, garantimos que você desfrute, entre outros benefícios, de:",
          cards: {
            iron: {
              title: "Ferro",
              description:
                "Contribui para a formação normal de glóbulos vermelhos e hemoglobina. Nosso Shilajit fornece 30,87% da Ingestão Diária Recomendada (IDR) de ferro por dose diária de 0,9 gramas.",
            },
            calcium: {
              title: "Cálcio",
              description:
                "Importante para a função muscular normal e desempenha um papel no processo de divisão e especialização celular. Nossa Resina Shilajit contém 72,06% da IDR de cálcio por dose diária de 0,9 gramas.",
            },
            magnesium: {
              title: "Magnésio",
              description:
                "Importante para a manutenção de ossos e dentes normais e contribui para a síntese normal de proteínas. Nosso produto fornece 108,24% da IDR de magnésio por dose diária de 0,9 gramas.",
            },
            selenium: {
              title: "Selênio",
              description:
                "Contribui para o funcionamento normal do sistema imunológico e para a proteção das células contra o estresse oxidativo. Com nossa Resina Shilajit, você obtém 22,92% da IDR de selênio por dose diária de 0,9 gramas.",
            },
            manganese: {
              title: "Manganês",
              description:
                "Contribui para a formação normal do tecido conjuntivo e para a proteção das células contra o estresse oxidativo. Em nossa Resina Shilajit, você encontrará 33,75% da IDR de manganês por dose diária de 0,9 gramas.",
            },
          },
        },
        whatisShilajit: {
          heading: "DESCUBRA A ORIGEM",
          paragraph1:
            "Deixe a Resina Shilajit Vitadote<sup>®</sup> ser um complemento à sua boa rotina. Uma boa rotina consiste em um padrão saudável de alimentação e exercícios. A Vitadote<sup>®</sup> Shilajit pode ser um complemento a isso, diretamente da natureza intocada, conectando você a uma tradição que resistiu ao teste do tempo.",
          paragraph2: {
            bold: "Shilajit como Complemento ao Seu Padrão Alimentar",
            text: "A Vitadote<sup>®</sup> se esforça para complementar suas escolhas alimentares com Shilajit, um produto natural tradicionalmente valorizado. Em caso de dúvida, consulte um médico e sempre siga as recomendações na embalagem.",
          },
          paragraph3: {
            bold: "Ácido Fúlvico na Resina Shilajit Vitadote<sup>®</sup>",
            text1:
              "A Resina Shilajit Vitadote<sup>®</sup> contém principalmente ácido fúlvico, além de ácido húmico e minerais.",
            text2:
              "Entre em contato conosco para obter informações específicas sobre a quantidade de ácido fúlvico, ácido húmico e minerais em nosso produto ou visite nossa ",
            productPage: "página de produtos",
          },
        },
        instructions: {
          heading: "Instruções de Uso",
          details:
            "Recomendamos tomar 3 vezes ao dia uma pequena quantidade de 0,3 gramas de Resina Shilajit Vitadote<sup>®</sup>. Dissolva-a diretamente sob a língua ou em um copo de água não clorada para uma absorção ideal.",
          step1: "Use a espátula para retirar a dosagem recomendada do frasco.",
          step2:
            "Dissolva em água não clorada, chá de ervas ou leite morno (não ultrapassando 39°C), e beba. Também pode ser dissolvido sob a língua.",
          step3:
            "Para resultados ideais, recomendamos o uso regular da resina de Shilajit.",
        },
        FAQS: {
          heading: "Perguntas Frequentes",
          heading1: "Responsável e Focado na Qualidade",
          paragraph1:
            "Na Vitadote<sup>®</sup>, buscamos os mais altos padrões de qualidade para nosso Shilajit. Somos cuidadosos em seguir as regulamentações vigentes para suplementos alimentares nos Países Baixos. Nossos produtos são submetidos a rigorosos controles de qualidade para atender aos padrões de segurança alimentar exigidos. Temos orgulho de poder fundamentar nossa abordagem cuidadosa com documentação relevante. Nos esforçamos para garantir a pureza e qualidade de cada lote do nosso produto. Informações detalhadas sobre a composição e características de qualidade do nosso produto podem ser encontradas em nosso site nas especificações do produto.",
          heading3:
            "Para mais informações e a lista completa de perguntas frequentes, visite nossa ",
          faqsPage: "Página de FAQ",
          faqs: {
            question1: "O que é a Resina Shilajit Vitadote<sup>®</sup>?",
            answer1:
              "A Resina Shilajit Vitadote<sup>®</sup> é uma substância natural resinosa colhida de formações rochosas nas montanhas Altai e Himalaia. Ela contém uma composição única de minerais naturais: ferro, cálcio, magnésio, selênio e manganês, bem como compostos orgânicos como ácido fúlvico e ácido húmico. O Shilajit tem sido usado por séculos em práticas tradicionais em várias culturas.",
            question2:
              "Como integro e uso a Resina Shilajit Vitadote<sup>®</sup> como complemento à minha rotina diária?",
            answer2:
              "Uma rotina diária saudável consiste em nutrição equilibrada e exercícios regulares. A Resina Shilajit Vitadote<sup>®</sup> pode ser um complemento a isso. Se você optar por usar Shilajit, recomendamos tomar 3 vezes ao dia uma porção de 0,3 gramas (aproximadamente do tamanho de um grão de arroz a uma ervilha). Você pode dissolver a Resina Shilajit Vitadote<sup>®</sup> sob sua língua ou misturar em um copo de água não clorada ou outra bebida de sua escolha (até no máximo 39°C).",
            question3: "Como armazeno a Resina Shilajit Vitadote<sup>®</sup>?",
            answer3:
              "Armazene-a em um local fresco e seco, longe da luz solar direta.",
            question4: "De onde vem a Resina Shilajit Vitadote<sup>®</sup>?",
            answer4:
              "Nosso Shilajit é colhido das formações rochosas das montanhas Altai e Himalaia.",
            question5:
              "Posso combinar a Resina Shilajit Vitadote<sup>®</sup> com outros suplementos?",
            answer5:
              "Em caso de dúvida, consulte um médico e sempre siga as recomendações na embalagem.",
          },
          readMore: "LEIA MAIS Perguntas Frequentes",
        },
        subscription: {
          heading: "Junte-se ao Movimento",
          details:
            "Descubra por si mesmo as propriedades distintivas da Resina Shilajit Vitadote<sup>®</sup> e faça parte de nossa comunidade que reconhece o valor dos ingredientes naturais.",
          joinNow: "Inscreva-se agora",
          placeholder: "Digite seu e-mail",
        },
      },
      aboutPage: {
        seo: {
          metaDescription:
            "Conheça a Vitadote®: comprometida com Shilajit puro do Altai e Himalaia. Saiba sobre nossa missão e práticas sustentáveis de.",
        },
        banner: {
          mainHeading:
            "Vitadote<sup>®</sup>: Pioneiros da Resina Shilajit Pura",
          subHeading: "Nossa Missão: Pureza das Montanhas até Você",
          details:
            "Na Vitadote<sup>®</sup>, somos dedicados a fornecer Resina Shilajit em sua forma mais autêntica. Inspirados por tradições centenárias, cada pote de Resina Shilajit Vitadote<sup>®</sup> oferece uma conexão com a origem natural desta substância única.",
        },
        infoSection: {
          heading: "Da Montanha para Sua Rotina Diária",
          details:
            "Uma boa rotina consiste em um padrão saudável de alimentação e exercícios. A Vitadote<sup>®</sup> pode ser um complemento a isso. Nosso Shilajit vem das regiões elevadas das montanhas Altai e Himalaia, áreas conhecidas por sua natureza intocada. Trazemos cuidadosamente esta substância natural, com sua composição única de minerais e compostos orgânicos como o ácido fúlvico, para sua casa.",
          commitmentHeading: "Compromisso com Qualidade e Cuidado",
          commitments1: "Na Vitadote<sup>®</sup>, valorizamos muito a qualidade e pureza do nosso Shilajit. Nossos produtos são cuidadosamente controlados para atender aos padrões de segurança alimentar vigentes. Nos esforçamos para ser transparentes sobre nossos processos e controles de qualidade.",
          commitments2: "Embora todos os suplementos alimentares devam cumprir as normas legais, nos empenhamos em tornar nossa abordagem cuidadosa demonstrável. Isso reflete nossa dedicação à autenticidade e transparência.",
          commitments3: "Para informações detalhadas sobre as propriedades específicas do nosso produto, convidamos você a consultar as especificações do produto em nosso site.",
        },
        featuresSection: {
          feature1: {
            icon: "GppGoodIcon",
            heading: "Práticas Sustentáveis, Colheita Responsável",
            details:
              "Sustentabilidade e respeito pelo meio ambiente são centrais em nossos métodos de colheita. Este é nosso compromisso, não apenas com o produto, mas também com a terra e seus habitantes.",
          },
          feature2: {
            icon: "fa-handshake",
            heading: "Nossa Equipe",
            details:
              "Compartilhamos com prazer nosso conhecimento sobre as características naturais do Shilajit e oferecemos conselhos para sua integração em um regime diário equilibrado. Um bom regime consiste em um padrão saudável de alimentação e exercícios. O uso da Resina Shilajit Vitadote<sup>®</sup> pode ser um complemento a isso.",
          },
          feature3: {
            icon: "GroupsIcon",
            heading: "A Promessa Vitadote<sup>®</sup>",
            details:
              "Cada embalagem de Resina Shilajit Vitadote<sup>®</sup> representa nossa promessa de qualidade e clareza. Nosso objetivo é aproximá-lo da origem natural de nossos ingredientes cuidadosamente coletados.",
          },
        },
      },

      faqsPage: {
        seo: {
          metaDescription:
            "Perguntas frequentes sobre a resina Shilajit Vitadote®. Saiba sobre uso, benefícios e segurança do nosso suplemento natural.",
        },
        banner: {
          heading:
            "Bem-vindo à página de perguntas frequentes do Vitadote<sup>®</sup> Shilajit Resin",
          description1:
            "Curioso sobre o Vitadote<sup>®</sup> Shilajit Resin e como ele pode complementar sua rotina diária? Esta página foi criada para responder a todas as suas perguntas sobre este suplemento natural excepcional. Desde sua composição única de minerais e compostos orgânicos até dicas de armazenamento e compatibilidade com outros suplementos, tudo está aqui.",
          description2:
            "Descubra como o Vitadote<sup>®</sup> Shilajit Resin é cuidadosamente obtido, testado em laboratório e desenvolvido para atender às suas necessidades de bem-estar. Seja para obter instruções detalhadas de uso, informações de segurança ou características do produto, nossa página de perguntas frequentes oferece a clareza e a confiança de que você precisa.",
        },
        faqSection: {
          title: "Perguntas Frequentes sobre a Resina Shilajit Vitadote®",
          faqs: [
            {
              question: "O que é Shilajit?",
              answer:
                "Shilajit é uma substância natural resinosa conhecida por seu conteúdo de Ferro, Cálcio, Magnésio, Selênio, Manganês, Ácido Fúlvico e Ácido Húmico. É valorizado há séculos em várias culturas por sua composição única e vem das formações rochosas das montanhas Altai e Himalaia.",
            },
            {
              question: "Como uso a Resina Shilajit Vitadote®?",
              answer:
                "Recomendamos tomar 3 vezes ao dia uma quantidade de 0,3 gramas (uma porção do tamanho de um grão de arroz a uma ervilha). Você pode dissolver a Resina Shilajit Vitadote® sob a língua ou em um copo de água não clorada ou outra bebida de sua escolha (até no máximo 39°C).",
            },
            {
              question:
                "Posso combinar a Resina Shilajit Vitadote® com outros suplementos?",
              answer:
                "Embora o Shilajit seja frequentemente combinado com outros suplementos, em caso de dúvida, consulte um médico e sempre siga as recomendações na embalagem.",
            },
            {
              question:
                "Existem efeitos colaterais conhecidos da Resina Shilajit Vitadote®?",
              answer:
                "A Resina Shilajit Vitadote® é geralmente bem tolerada quando usada conforme a dosagem recomendada. A sensibilidade individual pode variar. Em caso de dúvida, consulte um médico e sempre siga as recomendações na embalagem.",
            },
            {
              question: "Como armazeno a Resina Shilajit Vitadote®?",
              answer:
                "Deve ser armazenada em um local fresco e seco, longe da luz solar direta. Mantenha fora do alcance das crianças.",
            },
            {
              question:
                "Posso usar a Resina Shilajit Vitadote® durante a gravidez ou amamentação?",
              answer:
                "Se você está grávida ou amamentando, é importante ser cautelosa com suplementos alimentares. Recomendamos fortemente que você consulte um profissional de saúde antes de começar a usar a Resina Shilajit Vitadote®.",
            },
            {
              question:
                "A Resina Shilajit Vitadote® é adequada para vegetarianos e veganos?",
              answer:
                "Sim, a Resina Shilajit Vitadote® é adequada tanto para vegetarianos quanto para veganos.",
            },
            {
              question:
                "Por quanto tempo posso usar a Resina Shilajit Vitadote® após a abertura?",
              answer:
                "Desde que seja armazenada corretamente, a Resina Shilajit Vitadote® pode ser usada por um longo período após a abertura. Sempre verifique a data de validade na embalagem.",
            },
            {
              question: "A Resina Shilajit Vitadote® é Halal?",
              answer:
                "A Resina Shilajit Vitadote® é um produto vegetal e não contém produtos animais, o que deve torná-la adequada para pessoas que seguem uma dieta halal.",
            },
            {
              question:
                "A Resina Shilajit Vitadote® contém alergênicos ou glúten?",
              answer:
                "A Resina Shilajit Vitadote® é livre de alergênicos conhecidos e glúten. O processo de produção garante que nosso Shilajit seja livre da maioria dos alergênicos comuns, incluindo nozes, glúten, lactose e soja. Para obter as informações mais atualizadas sobre alergênicos, recomendamos verificar as informações do rótulo ou entrar em contato diretamente conosco.",
            },
            {
              question:
                "A Resina Shilajit Vitadote® pode causar uma reação alérgica em pessoas com alergia a coco?",
              answer:
                "Embora a Resina Shilajit Vitadote® não contenha ingredientes geralmente reconhecidos como alergênicos, ela contém uma pequena quantidade de óleo de coco, que pode causar uma reação alérgica em pessoas com uma alergia específica ao coco. Se você tem alergia ao coco, é aconselhável ter cautela e consultar seu profissional de saúde antes de usar a Resina Shilajit Vitadote®.",
            },
            {
              question:
                "A Resina Shilajit Vitadote® pode ser usada em combinação com medicamentos?",
              answer:
                "Recomendamos consultar primeiro seu médico ou farmacêutico antes de combinar a Resina Shilajit Vitadote® com medicamentos.",
            },
          ],
          contact: "Tem mais perguntas?",
          contactLink: "Entre em contato conosco.",
        },
      },
      contactPage: {
        seo: {
          metaDescription:
            "Entre em contato com a Vitadote® para dúvidas ou suporte sobre nossos produtos. Fale conosco via formulário, e-mail ou telefone. Estamos aqui para ajudar!",
        },
        banner: {
          heading: "FALE CONOSCO",
          subHeading: "Estamos Aqui para Ajudar!",
          description:
            "Tem dúvidas, precisa de ajuda ou quer saber mais sobre a Vitadote® e nossos produtos? Entre em contato conosco hoje mesmo! Você pode nos alcançar por e-mail, telefone ou preenchendo o formulário de contato abaixo. Nossa equipe está pronta para atender suas solicitações.",
        },
        infoSection: {
          infoHeading: "INFORMAÇÕES",
          addressHeading: "Endereço",
          address: "Albert Huismanpad 166, 1502EK Zaandam, Países Baixos",
          phoneHeading: "Telefone",
          emailHeading: "E-mail",
          formFields: {
            nameLabel: "Nome",
            emailLabel: "E-mail",
            phoneLabel: "Telefone",
            messageLabel: "Mensagem",
            submitButton: "ENVIAR AGORA",
          },
        },
      },
      "resellerPage": {
        "seo": {
          "metaDescription": "Seja revendedor exclusivo do Vitadote® Shilajit, suplemento natural premium. Obtenha direitos de venda, altas margens e suporte de marketing."
        },
        "banner": {
          "heading": "TORNE-SE UM REVENDEDOR VITADOTE<sup>®</sup> SHILAJIT NA EUROPA",
          "subHeading": "Está procurando um produto único e de alta qualidade para expandir seu sortimento?",
          "description": "A Vitadote®, produtora líder e proprietária da marca de Shilajit premium, está em busca de revendedores e distribuidores entusiasmados de Shilajit em toda a Europa. Nosso programa exclusivo de revendedores oferece oportunidades para o comércio atacadista de suplementos naturais."
        },
        "whysellHeading": "POR QUE VENDER VITADOTE<sup>®</sup> SHILAJIT?",
        "whySell": [
          {
            "icon": "/icons/authentication.svg",
            "feature": "Produto autêntico",
            "description": "Colhido diretamente das montanhas Altai e Himalaia"
          },
          {
            "icon": "/icons/high-quality.svg",
            "feature": "Alta qualidade",
            "description": "Controles rigorosos de qualidade e conformidade com as regulamentações da UE"
          },
          {
            "icon": "/icons/growing-business.svg",
            "feature": "Mercado em crescimento",
            "description": "Demanda crescente por suplementos alimentares naturais"
          },
          {
            "icon": "/icons/proposition.svg",
            "feature": "Oferta diferenciada",
            "description": "Composição única de minerais e ácido fúlvico"
          },
          {
            "icon": "/icons/brand-identity.svg",
            "feature": "Identidade de marca forte",
            "description": "Aparência profissional e suporte de marketing"
          }
        ],
        "benefitsHeading": "Vantagens de se tornar um revendedor Vitadote<sup>®</sup>",
        "benefits": [
          {
            "feature": "Direitos exclusivos de venda como distribuidor Shilajit em sua região"
          },
          {
            "feature": "Margens de lucro atraentes para revendedores e atacadistas"
          },
          {
            "feature": "Acesso ao nosso extenso programa de revendedores com materiais de marketing e treinamento de produtos"
          },
          {
            "feature": "Suporte contínuo de nossa equipe experiente"
          },
          {
            "feature": "Oportunidade de capitalizar o crescente interesse em suplementos naturais"
          }
        ],
        "lookingForHeading": "Quem estamos procurando?",
        "lookingFor": [
          {
            "title": "Varejistas estabelecidos e distribuidores de Shilajit no setor de saúde"
          },
          {
            "title": "Vendedores online e atacadistas com foco em suplementos alimentares naturais"
          },
          {
            "title": "Distribuidores de produtos naturais"
          },
          {
            "title": "Centros de bem-estar e clínicas de saúde"
          }
        ],
        "footerHeading": "Interessado em uma parceria de revenda Vitadote<sup>®</sup>?",
        "footerDescription": "Entre em contato conosco hoje e descubra como você pode se tornar um distribuidor Shilajit ou revendedor exclusivo através do nosso programa único de revendedores. Junte-se à rede Vitadote® e beneficie-se da crescente demanda por Shilajit premium na Europa."
      },
      "products": {
        "badges": {
          "topSelling": "Mais vendido",
          "save8": "Economize €8",
          "save27": "Economize €27"
        },
        "images": [
          "/images/products-pt-br/04. comparação do conteúdo de ácido fúlvico no shilajit.jpg",
          "/images/products-pt-br/05. shilajit para antienvelhecimento e saúde da pele.jpg",
          "/images/products-pt-br/11. PT-BR Ingredientes da resina Vitadote Shilajit.jpg",
          "/images/products-pt-br/06. eficácia de cápsulas de shilajit orgânico vs resina.jpg",
          "/images/product-picture-3.jpg",
          "/images/products-pt-br/10. efeitos colaterais do shilajit e precauções de segurança.jpg",
          "/images/products-pt-br/07. shilajit para aumento de testosterona e crescimento muscular.jpg",
          "/images/products-pt-br/08. benefícios do shilajit para o equilíbrio hormonal feminino.jpg",
          "/images/products-pt-br/09. shilajit para função cognitiva e saúde cerebral.jpg",
        ],
        "ourProducts": "Nossos produtos",
        "productName1": "1x resina pura de shilajit",
        "productName2": "2x resina pura de shilajit",
        "productName3": "3x resina pura de shilajit",
        "relatedProducts": "Produtos Relacionados",
        "seo": {
          "metaTitle": "Resina Pura de Shilajit | Fonte do Altai e Himalaia | Ácido Fúlvico por Vitadote®",
          "metaDescription": "Descubra a Resina de Shilajit Vitadote® das montanhas do Altai e do Himalaia. Testada em laboratório, protegida contra UV e rica em ácido fúlvico e minerais naturais.",
          "slug1": "1x-pure-shilajit-resin",
          "slug2": "2x-pure-shilajit-resin",
          "slug3": "3x-pure-shilajit-resin",
        },
        "banner": {
          "heading": "Resina Pura de Shilajit | Fonte do Altai e Himalaia | Ácido Fúlvico por Vitadote®",
          "keyQuality": "Principais Qualidades da Resina de Shilajit Vitadote®",
          "benefits": [
            {
              "title": "Diretamente das Montanhas Altai e Himalaia",
              "description": "Colhida em regiões montanhosas intocadas, onde o Shilajit adquire sua rica composição mineral."
            },
            {
              "title": "Embalada em Vidro com Proteção UV",
              "description": "Embalada em vidro ultravioleta para preservar a potência, bloqueando raios de luz prejudiciais."
            },
            {
              "title": "Qualidade Testada em Laboratório",
              "description": "Cada lote é rigorosamente testado e certificado HACCP, garantindo um produto de Shilajit puro e de alta qualidade."
            },
            {
              "title": "Certificações KOAG/KAG e NVWA",
              "description": "Essas certificações confirmam que nossa Resina de Shilajit atende a rigorosos padrões de segurança e qualidade."
            }
          ]
        },
        "detailsTabs": {
          "description": {
            "title": "Descrição do Produto",
              "desciption": "A <b>Resina de Shilajit Vitadote®</b> é um suplemento natural premium, originário das regiões de alta altitude das <b>montanhas Altai e Himalaia</b> Ela oferece uma forma pura e autêntica de Shilajit, rica em minerais essenciais como <b>ferro, cálcio, magnésio, selênio, manganês, zinco </b>e <b>ácido fúlvico</b>, trazendo o poder da natureza para sua rotina diária de bem-estar.",
            "features": [
              {
                "title": "Como Usar",
                "description": "Para melhores resultados, tome <b>0,3 gramas</b> (aproximadamente do tamanho de um grão de arroz ou de uma ervilha) três vezes ao dia. Dissolva sob a língua ou misture em <b>água não clorada</b> ou em uma <b>bebida de sua escolha (até 39°C).</b>"
              },
              {
                "title": "Puro e Confiável",
                "description": "<b>Vitadote®</b> se compromete a oferecer uma resina de Shilajit autêntica e de origem responsável. Garantimos que cada pote atenda aos nossos altos padrões de qualidade e pureza, para que você possa desfrutar do Shilajit em sua forma mais natural possível."
              },
              {
                "title": "Descubra a Essência do Shilajit com Vitadote®",
                "description": "Escolha a <b>Resina de Shilajit Vitadote®</b> para enriquecer sua jornada de bem-estar com Shilajit puro e não processado, diretamente <b>das montanhas do Altai e do Himalaia.</b>"
              }
            ]
          },
          "reviews": {
            "title": "AVALIAÇÕES",
            "customersReviews": "Avaliações do cliente",
            "verifiedPurchases": "Tudo de compras verificadas",
            "allReviewsWithDetails": "Todas as avaliações com detalhe",
            customerReviews: [
              {
                "clientName": "Hassan Iqbal Hashmi",
                "date": "2023-05-16",
                "profile_image": "https://lh3.googleusercontent.com/a-/ACB-R5Tsv5u4-Z7qiRmBTw5JSsm7JolJNfZNeVWlzZIefiY=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Finally find pure Shilajit Resins. It has done what was expected. Thank you."
              },
              {
                "clientName": "Rebecca Sperry",
                "date": "2023-05-03",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxZgq-bSMicabswNP3HG1r1k8p7M6ekBDc-DYVx_=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Shilajit is een super resin, ik neem elke ochtend op mij werk, en ja hoor het helpt met alles, mij houd en haar is veel beter al binnen 3 weken!"
              },
              {
                "clientName": "Doeke Leerink",
                "date": "2023-03-09",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxbqo1czKEDR9BiUlb0FgAUH2ekolUQruV6U-Kod=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Na veel zoeken hier het echte product gevonden. De website geeft uitgebreide informatie en er wordt snel geleverd. Sterk aan te raden."
              },
              {
                "clientName": "Alex Berkov",
                "date": "2023-03-07",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxYgIJuXgqYb5Hg505DuNAp9quCSFrs-AgkNJ34l=s120-c-c-rp-w64-h64-mo-ba3-br100",
                "stars": 5,
                "review": "Folosesc de aprox 1 luna shilajit de la Vitadote pot spune ca in prima faza am acoperit gustul shilajitului cu miere de albine 🙂 Efectele sale nu încetează sa tot apara, de la lipsa balonării pana la mai multa energie si focus/atentie. Mulțumesc."
              },
              {
                "clientName": "Maysoon Kelani",
                "date": "2023-02-21",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxaR9AJ46lfbFL_DYEXp8BiUns9E1HRaLVCpdJ6q=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Best product ever thanks alot!"
              },
              {
                "clientName": "Heba Khatib",
                "date": "2023-02-21",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxZksHg6l3jwZQAh6QZv4nkl7cZnzZqr3aRMka4y=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Super team and best product ever ❤️."
              },
              {
                "clientName": "Adrian Rosu Savinoiu",
                "date": "2023-02-21",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxbd7wCzON2KNosyp0gTka5yPGTdlh28C4hWJhmQ=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "L-am luat pentru sotia mea, prima imbunatatire pe care a simtit-o este ca nu mai are picioarele reci. E primul remediu incercat de ea care a reusit acest lucru. Gustul nu e atat de rau pe cat se spune mai ales daca e pus in ceai. Cutia si modul de prezentare i-au placut enorm. Produsul vine cu posta romana, deci nu ajunge chiar in 3 zile (nu din vina postei ci din cauza vamii). Vitadote a fost foarte profesionista din punctul de vedere al livrarii, link cu AWB atat posta din Olanda, am comunicat pe WhatsApp fara probleme."
              },
              {
                "clientName": "Daniela Răducănescu",
                "date": "2023-02-10",
                "profile_image": "https://lh3.googleusercontent.com/a-/ACB-R5RuEdC9WO1A6Y0OUlXUKO7s4z8jReTVqZekkR_2M1Q=s120-c-c-rp-w64-h64-mo-ba4-br100",
                "stars": 5,
                "review": "#shilajit @Vitadote Shilajitul este excelent! L-am și testat pentru autenticitate, iar la toate 3 testări rezultatele au arătat că este autentic. Am luat aseară un pahar de ceai cald in care am dizolvat shilajitul primit de la Vitadote. Nu am reușit să mai dorm până pe la ora 2 de noapte. Deci energizarea corpului este ca și cum aș fi băut o cană de ness. Comunicarea excelentă! Imediat ce au primit comanda, mi-au scris pe WhatsApp! Au grijă de client și s-au asigurat că am primit coletul. Minunat! Exact ce mi-am dorit! Mulțumesc, Vitadote!"
              },
              {
                "clientName": "dragos negrau",
                "date": "2023-02-08",
                "profile_image": "https://lh3.googleusercontent.com/a-/ACB-R5RKX3q3g1OFHa_YAA-D6Oa9M9cVSnW13vSDUABr=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Your product it’s life changer! Super team! Fast shipping..."
              }
            ]
          }
        }
      },
      "privacyPolicy": {
        "heading": "Política de Privacidade",
        "seo": {
          "metaDescription": "Política de Privacidade Vitadote®: como coletamos, usamos e protegemos seus dados. Informações sobre seus direitos e conformidade com a LGPD."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Introdução",
            "description1": "Na Vitadote®, estamos comprometidos em proteger sua privacidade e salvaguardar seus dados pessoais. Esta Política de Privacidade explica como coletamos, usamos, divulgamos e protegemos suas informações pessoais quando você visita nosso site vitadote.com ou usa nossos serviços.",
            "description2": "eCom IT BV (\"eCom IT\", \"nós\", \"nos\" ou \"nosso\"), operando sob o nome comercial Vitadote®, é o controlador de dados responsável pelo processamento de seus dados pessoais conforme descrito nesta Política de Privacidade."
          },
          "informationWeCollect": {
            "title": "2. Informações que coletamos",
            "description": "Podemos coletar os seguintes tipos de informações pessoais:",
            "list": [
              "Informações de identidade e contato (por exemplo, nome, endereço de e-mail, número de telefone, endereço postal)",
              "Informações da conta (por exemplo, nome de usuário, senha)",
              "Dados de pedidos e transações",
              "Informações de pagamento",
              "Comunicações conosco (por exemplo, consultas ao serviço ao cliente)",
              "Dados de uso (por exemplo, como você usa nosso site)",
              "Dados técnicos (por exemplo, endereço IP, tipo de navegador)"
            ]
          },
          "howWeUse": {
            "title": "3. Como usamos suas informações",
            "description": "Usamos suas informações pessoais para os seguintes propósitos:",
            "list": [
              "Para processar e entregar seus pedidos",
              "Para gerenciar sua conta conosco",
              "Para fornecer suporte ao cliente",
              "Para informá-lo sobre mudanças importantes em nossos serviços",
              "Para melhorar nosso site e serviços",
              "Para prevenir e detectar fraudes",
              "Para cumprir nossas obrigações legais"
            ]
          },
          "legalBases": {
            "title": "4. Bases legais para o processamento",
            "description": "Processamos seus dados pessoais com base em um ou mais dos seguintes fundamentos legais:",
            "list": [
              "Execução de um contrato com você",
              "Nossos interesses comerciais legítimos",
              "Cumprimento de uma obrigação legal",
              "Seu consentimento (quando necessário)"
            ],
            "extraInfo": "A Vitadote® está em conformidade com o Regulamento Geral sobre a Proteção de Dados (GDPR) da União Europeia e com a Lei Geral de Proteção de Dados (LGPD) do Brasil. Levamos sua privacidade a sério e garantimos que todas as nossas atividades de processamento de dados estejam de acordo com essas regulamentações."
          },
          "sharingInfo": {
            "title": "5. Compartilhamento de suas informações",
            "description": "Podemos compartilhar suas informações pessoais com:",
            "list": [
              "Nossos prestadores de serviços (por exemplo, provedores de hospedagem, processadores de pagamento)",
              "Serviços de entrega",
              "Autoridades policiais ou outras agências governamentais (se legalmente exigido)"
            ],
            "note": "Não vendemos suas informações pessoais a terceiros."
          },
          "dataRetention": {
            "title": "6. Retenção de dados",
            "description": "Mantemos suas informações pessoais pelo tempo necessário para cumprir os propósitos para os quais foram coletadas, ou para cumprir requisitos legais."
          },
          "dataSecurity": {
            "title": "7. Segurança de dados",
            "description": "Implementamos medidas técnicas e organizacionais apropriadas para proteger suas informações pessoais contra acesso não autorizado, perda, uso indevido ou destruição."
          },
          "yourRights": {
            "title": "8. Seus direitos",
            "description": "Sob o GDPR e outras leis de proteção de dados aplicáveis, você tem certos direitos em relação aos seus dados pessoais, incluindo:",
            "list": [
              "Direito de acesso",
              "Direito de retificação",
              "Direito de apagamento",
              "Direito à limitação do processamento",
              "Direito à portabilidade dos dados",
              "Direito de se opor ao processamento"
            ],
            "extraInfo": "Para exercer seus direitos, entre em contato conosco usando os detalhes fornecidos no final desta política."
          },
          "cookies": {
            "title": "9. Cookies e tecnologias similares",
            "description": "Usamos cookies e tecnologias similares para melhorar sua experiência em nosso site e para entender como nosso site é usado. Para obter mais informações sobre nosso uso de cookies, consulte nossa Política de"
          },
          "internationalTransfers": {
            "title": "10. Transferências internacionais de dados",
            "description": "Podemos transferir e processar suas informações pessoais em países fora do Espaço Econômico Europeu (EEE)."
          },
          "policyChanges": {
            "title": "11. Alterações nesta Política de Privacidade",
            "description": "Podemos atualizar esta Política de Privacidade de tempos em tempos. A versão mais recente estará sempre disponível em nosso site."
          },
          "contactUs": {
            "title": "12. Entre em contato conosco",
            "text1": "Se você tiver alguma dúvida, comentário ou solicitação em relação a esta Política de Privacidade ou nossas práticas de privacidade, entre em contato conosco em:",
            "text2": "E-mail: ",
            "text3": "Endereço postal: Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Países Baixos",
            "text4": "Você também tem o direito de apresentar uma reclamação à Autoridade Holandesa de Proteção de Dados.",
            "lastUpdated": "Última atualização: 19 de agosto de 2024"
          }
        }
      },
      "cookiePolicy": {
        "heading": "Política de Cookies",
        "seo": {
          "metaDescription": "Política de Cookies Vitadote®: nosso uso de cookies, sua finalidade e como gerenciar suas preferências. Atualizada em 19 de agosto de 2024."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Introdução",
            "description": "Esta Política de Cookies explica como a Vitadote® (\"nós\", \"nos\" ou \"nosso\"), operada pela eCom IT BV, usa cookies e tecnologias similares em nosso site vitadote.com. Esta política fornece informações claras e abrangentes sobre os cookies que usamos e as finalidades para as quais os usamos."
          },
          "whatAreCookies": {
            "title": "2. O que são cookies?",
            "description": "Cookies são pequenos arquivos de texto colocados em seu dispositivo quando você visita um site. Eles são amplamente utilizados para fazer os sites funcionarem de maneira mais eficiente, bem como para fornecer informações aos proprietários do site. Os cookies podem ser \"persistentes\" ou \"de sessão\"."
          },
          "howWeUseCookies": {
            "title": "3. Como usamos os cookies",
            "description": "Usamos cookies para os seguintes propósitos:",
            "list": [
              {
                "name": "Cookies essenciais",
                "purpose": "São cookies necessários para o funcionamento do nosso site. Incluem, por exemplo, cookies que permitem que você faça login em áreas seguras do nosso site ou use um carrinho de compras."
              },
              {
                "name": "Cookies analíticos/de desempenho",
                "purpose": "Permitem-nos reconhecer e contar o número de visitantes e ver como os visitantes se movem pelo nosso site quando o utilizam."
              },
              {
                "name": "Cookies de funcionalidade",
                "purpose": "São usados para reconhecê-lo quando você retorna ao nosso site. Isso nos permite personalizar nosso conteúdo e lembrar suas preferências."
              },
              {
                "name": "Cookies de direcionamento",
                "purpose": "Esses cookies registram sua visita ao nosso site, as páginas que você visitou e os links que seguiu."
              }
            ]
          },
          "thirdPartyCookies": {
            "title": "4. Cookies de terceiros",
            "description": "Além de nossos próprios cookies, também podemos usar vários cookies de terceiros para relatar estatísticas de uso do Serviço, entregar anúncios no e através do Serviço, e assim por diante."
          },
          "specificCookies": {
            "title": "5. Quais cookies específicos usamos?",
            "description": "Aqui está uma lista dos principais cookies que usamos em vitadote.com:",
            "table": [
              { "type": "Cookie de sessão", "name": "PHPSESSID", "purpose": "Preserva o estado da sessão do usuário entre as solicitações de página", "duration": "Sessão" },
              { "type": "Cookie de autenticação", "name": "auth_token", "purpose": "Mantém o usuário conectado", "duration": "30 dias" },
              { "type": "Cookie do carrinho de compras", "name": "cart_items", "purpose": "Lembra os itens no carrinho de compras do usuário", "duration": "30 dias" },
              { "type": "Cookie de preferência de idioma", "name": "site_language", "purpose": "Lembra a preferência de idioma do usuário", "duration": "1 ano" },
              { "type": "Cookies do Google Analytics", "name": "_ga, _gid, _gat", "purpose": "Usados para distinguir usuários e limitar a taxa de solicitações", "duration": "_ga: 2 anos, _gid: 24 horas, _gat: 1 minuto" },
              { "type": "Cookie do Facebook Pixel", "name": "_fbp", "purpose": "Usado pelo Facebook para entregar uma série de produtos publicitários", "duration": "3 meses" },
              { "type": "Cookie de consentimento", "name": "cookie_consent", "purpose": "Lembra se o usuário aceitou o aviso de cookies", "duration": "1 ano" },
              { "type": "Cookie de recomendação de produtos", "name": "recent_views", "purpose": "Armazena produtos visualizados recentemente para recomendações", "duration": "30 dias" },
              { "type": "Cookie de desempenho", "name": "page_load_time", "purpose": "Mede os tempos de carregamento das páginas para ajudar a melhorar o desempenho do site", "duration": "Sessão" },
              { "type": "Cookie de teste A/B", "name": "ab_test_variant", "purpose": "Lembra qual versão de um teste dividido o usuário viu", "duration": "30 dias" }
            ],
            "description2": "Observe que serviços de terceiros, como Google Analytics ou plataformas de mídia social, podem definir cookies adicionais. Para obter mais informações sobre esses cookies, consulte as respectivas políticas de privacidade desses serviços.",

          },
          "choicesRegardingCookies": {
            "title": "6. Suas escolhas em relação aos cookies",
            "description": [
              "Se você deseja excluir cookies ou instruir seu navegador da web a excluir ou recusar cookies, visite as páginas de ajuda do seu navegador da web.",
              "No entanto, observe que, se você excluir cookies ou se recusar a aceitá-los, pode não conseguir usar todos os recursos que oferecemos."
            ]
          },
          "changesToPolicy": {
            "title": "7. Alterações em nossa Política de Cookies",
            "description": "Podemos atualizar nossa Política de Cookies de tempos em tempos. Notificaremos você sobre quaisquer alterações publicando a nova Política de Cookies nesta página e atualizando a data de \"Última atualização\" no topo desta política."
          },
          "contactUs": {
            "title": "8. Entre em contato conosco",
            "description": "Se você tiver alguma dúvida sobre nosso uso de cookies ou esta Política de Cookies, entre em contato conosco em:",
            "email": "info@vitadote.com",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Países Baixos",
            "lastUpdated": "Última atualização: 19 de agosto de 2024"
          }
        }
      },
      "termsAndConditions": {
        "heading": "Termos e Condições",
        "seo": {
          "metaDescription": "Termos e Condições Vitadote®: seus direitos e obrigações ao usar nosso site e serviços. Atualizado em 19 de agosto de 2024."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Introdução",
            "description": "Bem-vindo ao Vitadote.com. Estes Termos de Serviço (\"Termos\") regem o seu uso do site e serviços Vitadote® (coletivamente, o \"Serviço\") operados pela eCom IT BV (\"eCom IT\", \"nós\", \"nos\" ou \"nosso\"). Ao acessar ou usar o Serviço, você concorda em estar vinculado a estes Termos. Se você não concordar com qualquer parte dos Termos, você não poderá acessar o Serviço."
          },
          "definitions": {
            "title": "2. Definições",
            "description": "\"Você\" refere-se ao indivíduo acessando ou usando o Serviço, ou à empresa ou outra entidade legal em nome da qual tal indivíduo está acessando ou usando o Serviço, conforme aplicável."
          },
          "accountRegistration": {
            "title": "3. Registro de Conta",
            "description": "Ao criar uma conta conosco, você deve fornecer informações precisas, completas e atualizadas. Não fazer isso constitui uma violação dos Termos, o que pode resultar no encerramento imediato de sua conta em nosso Serviço."
          },
          "useOfService": {
            "title": "4. Uso do Serviço",
            "description": "Você concorda em usar o Serviço apenas para fins lícitos e de acordo com estes Termos. Você concorda em não usar o Serviço:",
            "list": [
              "A. De qualquer maneira que viole qualquer lei ou regulamento nacional ou internacional aplicável.",
              "B. Para transmitir ou solicitar o envio de qualquer material publicitário ou promocional, incluindo qualquer \"lixo eletrônico\", \"corrente de cartas\", \"spam\" ou qualquer outra solicitação similar.",
              "C. Para se passar ou tentar se passar por eCom IT, um funcionário da eCom IT, outro usuário ou qualquer outra pessoa ou entidade.",
              "D. De qualquer maneira que infrinja os direitos de outros, ou que seja ilegal, ameaçadora, fraudulenta ou prejudicial, ou em conexão com qualquer propósito ou atividade ilegal, ilícita, fraudulenta ou prejudicial."
            ]
          },
          "intellectualProperty": {
            "title": "5. Propriedade Intelectual",
            "description": "O Serviço e seu conteúdo original, recursos e funcionalidades são e permanecerão propriedade exclusiva da eCom IT e seus licenciadores. O Serviço é protegido por direitos autorais, marcas registradas e outras leis dos Países Baixos e de países estrangeiros. Nossas marcas registradas e imagem comercial não podem ser usadas em conexão com qualquer produto ou serviço sem o consentimento prévio por escrito da eCom IT."
          },
          "userContent": {
            "title": "6. Conteúdo do Usuário",
            "description": "Nosso Serviço pode permitir que você poste, vincule, armazene, compartilhe e disponibilize de outra forma certas informações, textos, gráficos, vídeos ou outros materiais (\"Conteúdo\"). Você é responsável pelo Conteúdo que posta no ou através do Serviço, incluindo sua legalidade, fiabilidade e adequação."
          },
          "linksToOtherWebsites": {
            "title": "7. Enlaces a Outros Sites",
            "description": "Nosso Serviço pode conter enlaces a sites ou serviços de terceiros que não são de propriedade ou controlados pela eCom IT. A eCom IT não tem controle sobre e não assume nenhuma responsabilidade pelo conteúdo, as políticas de privacidade ou as práticas de quaisquer sites ou serviços de terceiros. Você reconhece e concorda ainda que a eCom IT não será responsável, direta ou indiretamente, por qualquer dano ou perda causada ou alegadamente causada por ou em conexão com o uso ou a confiança em qualquer conteúdo, bens ou serviços disponíveis em ou através de tais sites ou serviços."
          },
          "termination": {
            "title": "8. Rescisão",
            "description": "Podemos encerrar ou suspender sua conta imediatamente, sem aviso prévio ou responsabilidade, por qualquer motivo, incluindo, sem limitação, se você violar os Termos. Após a rescisão, seu direito de usar o Serviço cessará imediatamente."
          },
          "limitationOfLiability": {
            "title": "9. Limitação de Responsabilidade",
            "description": "Em nenhum caso eCom IT, nem seus diretores, funcionários, parceiros, agentes, fornecedores ou afiliados, serão responsáveis por quaisquer danos indiretos, incidentais, especiais, consequentes ou punitivos, incluindo, sem limitação, perda de lucros, dados, uso, goodwill ou outras perdas intangíveis, resultantes do seu acesso ou uso ou incapacidade de acessar ou usar o Serviço."
          },
          "disclaimer": {
            "title": "10. Isenção de Responsabilidade",
            "description": "Seu uso do Serviço é por sua conta e risco. O Serviço é fornecido \"COMO ESTÁ\" e \"SEGÚN DISPONÍVEL\". O Serviço é fornecido sem garantias de qualquer tipo, já sejam expressas ou implícitas, incluindo, mas não se limitando a, garantias implícitas de comerciabilidade, adequação a um propósito específico, não violação ou desempenho."
          },
          "governingLaw": {
            "title": "11. Lei Aplicável",
            "description": "Estes Termos serão regidos e interpretados de acordo com as leis dos Países Baixos, sem levar em conta suas disposições sobre conflitos de leis."
          },
          "changes": {
            "title": "12. Alterações",
            "description": "Reservamo-nos o direito, a nosso exclusivo critério, de modificar ou substituir estes Termos a qualquer momento. Se uma revisão for material, tentaremos fornecer um aviso de pelo menos 30 dias antes que quaisquer novos termos entrem em vigor. O que constitui uma mudança material será determinado a nosso exclusivo critério."
          },
          "detailedDisclaimer": {
            "title": "13. Aviso Legal",
            "list": [
              "<b>Precisão das informações:</b> Embora nos esforcemos para fornecer informações precisas e atualizadas no Vitadote.com, não fazemos representações ou garantias de qualquer tipo, expressas ou implícitas, sobre a integridade, precisão, confiabilidade, adequação ou disponibilidade das informações, produtos, serviços ou gráficos relacionados contidos no site para qualquer finalidade.",
              "<b>Uso por sua conta e risco:</b> Qualquer confiança que você deposita em tais informações é, portanto, estritamente por sua conta e risco. Em nenhum caso seremos responsáveis por qualquer perda ou dano, incluindo, sem limitação, perdas ou danos indiretos ou consequentes, ou qualquer perda ou dano decorrente da perda de dados ou lucros decorrentes do uso deste site.",
              "<b>Aviso Legal Médico:</b> As informações fornecidas no Vitadote.com, incluindo detalhes sobre o Shilajit e seus potenciais benefícios, são apenas para fins informativos e não se destinam a substituir o conselho de seu médico ou outro profissional de saúde. Você não deve usar as informações deste site para diagnosticar ou tratar um problema de saúde ou doença, ou para prescrever qualquer medicamento ou outro tratamento.",
              "<b>Uso do Produto:</b> Nossos produtos de Shilajit são suplementos dietéticos e não se destinam a diagnosticar, tratar, curar ou prevenir qualquer doença. Sempre consulte um profissional de saúde qualificado antes de iniciar qualquer programa de dieta, exercícios ou suplementação, antes de tomar qualquer medicamento ou se você tem ou suspeita que possa ter um problema de saúde.",
              "<b>Os resultados podem variar:</b> Os resultados descritos neste site em relação ao uso de Shilajit são experiências individuais, que refletem as experiências reais daqueles que utilizaram nossos produtos. No entanto, os resultados individuais podem variar. Não afirmamos que sejam resultados típicos que os consumidores geralmente alcançarão.",
              "<b>Enlaces Externos:</b> Através deste site, você pode ser capaz de vincular-se a outros sites que não estão sob o controle do Vitadote.com. Não temos controle sobre a natureza, o conteúdo e a disponibilidade desses sites. A inclusão de quaisquer enlaces não implica necessariamente uma recomendação ou endossa as opiniões expressas neles.",
              "<b>Disponibilidade do Site:</b> Todo esforço é feito para manter o site funcionando sem problemas. No entanto, o Vitadote.com não assume nenhuma responsabilidade por, e não será responsável pela indisponibilidade temporária do site devido a problemas técnicos além do nosso controle.",
              "<b>Alterações no Aviso Legal:</b> Reservamo-nos o direito de fazer alterações neste aviso legal a qualquer momento. Quaisquer alterações serão postadas nesta página com uma data de revisão atualizada."
            ],
            "note": "Ao usar o Vitadote.com, você indica sua concordância com este aviso legal. Se você não concorda com este aviso legal, por favor não use nosso site."
          },
          "copyrightNotice": {
            "title": "14. Aviso de Direitos Autorais",
            "description1": "© 2024 Vitadote® / eCom IT BV. Todos os direitos reservados.",
            "description2": "O conteúdo, design, dados, bancos de dados e gráficos neste site são protegidos pelas leis de propriedade intelectual holandesas e outras leis internacionais, e são de propriedade da eCom IT BV ou de seus licenciadores. Nenhuma parte deste site pode ser reproduzida, distribuída ou transmitida de qualquer forma ou por qualquer meio, incluindo fotocópia, gravação ou outros métodos eletrônicos ou mecânicos, sem a prévia permissão por escrito da eCom IT BV, exceto no caso de breves citações incorporadas em resenhas críticas e certos outros usos não comerciais permitidos pela lei de direitos autorais."
          },
          "contactUs": {
            "title": "15. Entre em Contato Conosco",
            "description": "Se você tiver alguma dúvida sobre estes Termos, entre em contato conosco em:",
            "email": "info@vitadote.com",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Países Baixos",
            "lastUpdated": "Última atualização: 19 de agosto de 2024"
          }
        }
      },

      "paymentPolicy": {
        "heading": "Política de Pagamento",
        "seo": {
          "metaDescription": "Política de Pagamento Vitadote®: métodos seguros, informações de moeda, reembolsos e pagamentos recorrentes. Atualizada em 19/08/2024."
        },
        "policyContent": {
          "paymentMethods": {
            "title": "Métodos de Pagamento",
            "description": "Você pode pagar seu pedido usando os seguintes métodos de pagamento:",
            "list": [
              "American Express",
              "Apple Pay",
              "Google Pay",
              "iDEAL / Sofort",
              "Mastercard / Visa",
              "PayPal",
              "Shop Pay",
              "Union Pay"
            ],
            "description2": "Embora não aceitemos diretamente outros cartões de crédito, o PayPal oferece a opção de vincular a maioria dos cartões de crédito comuns, permitindo que você pague com esses cartões através do PayPal em nosso site."
          },
          "paymentSecurity": {
            "title": "Segurança do Pagamento",
            "description": "Todas as transações em nosso site são processadas com segurança através do nosso provedor de serviços de pagamento, Mollie. Todos os processos via Mollie aderem ao princípio e critérios ISAE 3402 e são certificados de acordo com os padrões PCI DSS 3.2 Level 1 Service Provider. Esta certificação é um padrão de segurança reconhecido internacionalmente."
          },
          "orderVerification": {
            "title": "Verificação de Pedidos",
            "description": "Todos os métodos de pagamento são seguros e validados. Para proteger nossos clientes, alguns pedidos podem passar por um processo de verificação de pagamento. Se o seu pedido for colocado em espera, entraremos em contato com você prontamente para minimizar quaisquer possíveis atrasos em seu pedido."
          },
          "currency": {
            "title": "Moeda",
            "description": "Ao fazer compras em nosso site Vitadote.com, todos os pagamentos são processados em Euros (EUR)."
          },
          "refundsAndCancellations": {
            "title": "Reembolsos e Cancelamentos",
            "description": "Para obter informações sobre nossas políticas de reembolso e cancelamento, consulte nossa página de "
          },
          "recurringPayments": {
            "title": "Pagamentos Recorrentes",
            "description": "Se você se inscreveu em algum de nossos serviços que envolvem pagamentos recorrentes, você será cobrado automaticamente de acordo com a frequência que selecionou (por exemplo, mensal, anual). Você pode cancelar sua assinatura a qualquer momento fazendo login em sua conta ou contatando nossa equipe de suporte ao cliente."
          },
          "pricingAndTax": {
            "title": "Preços e Impostos",
            "description": "Todos os preços exibidos em nosso site estão em Euros e incluem os impostos aplicáveis. Quaisquer taxas adicionais de alfândega ou importação para pedidos internacionais são de responsabilidade do cliente."
          },
          "paymentIssues": {
            "title": "Problemas de Pagamento",
            "description": "Se você experimentar algum problema com seu pagamento, por favor verifique o seguinte:",
            "list": [
              "Certifique-se de que seus detalhes de pagamento foram inseridos corretamente.",
              "Verifique se seu método de pagamento tem fundos suficientes.",
              "Verifique se seu banco bloqueou a transação por motivos de segurança."
            ],
            "followUp": "Se você continuar experimentando problemas, entre em contato com nossa equipe de suporte ao cliente."
          },
          "dataProtection": {
            "title": "Proteção de Dados",
            "description": "Levamos a sério a segurança de seus dados financeiros. Todas as informações de pagamento são criptografadas e processadas com segurança. Não armazenamos os detalhes completos do seu cartão de crédito em nossos servidores. Para mais informações, consulte nossa "
          },
          "changesToThisPolicy": {
            "title": "Alterações nesta Política",
            "description": "Podemos atualizar esta Política de Pagamento de tempos em tempos. Notificaremos você sobre quaisquer alterações publicando a nova Política de Pagamento nesta página e atualizando a data de \"Última Atualização\" abaixo."
          },
          "contactUs": {
            "title": "Contate-nos",
            "description": "Se você tiver alguma dúvida relacionada a pagamentos, entre em contato conosco em:",
            "email": "info@vitadote.com",
            "phone": "+31 20 244 3918",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Países Baixos",
            "lastUpdated": "Última atualização: 19 de agosto de 2024"
          }
        }
      },
      "refundPolicy": {
        "heading": "Política de Reembolso",
        "seo": {
          "metaDescription": "Conheça a garantia exclusiva \"Fique com Ele\" da Vitadote®. 30 dias de garantia de satisfação em todas as compras. Leia nossa política."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Introdução",
            "description": "Na Vitadote®, sua satisfação é nossa principal prioridade. Estamos tão confiantes na qualidade de nossos produtos que oferecemos uma política de reembolso única e amigável ao cliente. Esta Política de Reembolso descreve os termos e condições para reembolsos de produtos comprados em nosso site vitadote.com."
          },
          "keepItRefundGuarantee": {
            "title": "2. Nossa Garantia de Reembolso \"Fique com o Produto\"",
            "description": "Se você não estiver completamente satisfeito com sua compra, pode solicitar um reembolso dentro de 30 dias após a entrega - e você fica com o produto. Confiamos em nossos clientes e valorizamos seus comentários, que nos ajudam a melhorar nossos produtos."
          },
          "refundPeriod": {
            "title": "3. Período de Reembolso",
            "description": "Você tem 30 dias a partir da data de entrega para solicitar um reembolso se não estiver satisfeito com sua compra."
          },
          "refundEligibility": {
            "title": "4. Elegibilidade para Reembolso",
            "description": "Para ser elegível ao nosso reembolso \"Fique com o Produto\":",
            "list": [
              "Você deve solicitar o reembolso dentro de 30 dias após a entrega.",
              "Você deve fornecer uma breve explicação de por que não está satisfeito com o produto."
            ]
          },
          "refundProcess": {
            "title": "5. Processo de Reembolso",
            "description": "Para iniciar um reembolso, siga estes passos:",
            "list": [
              "Entre em contato com nossa equipe de atendimento ao cliente em <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a> dentro de 30 dias após a entrega.",
              "Forneça seu número de pedido e uma breve explicação de por que você não está satisfeito com o produto.",
              "Nossa equipe revisará sua solicitação e processará seu reembolso."
            ]
          },
          "refundMethod": {
            "title": "6. Método de Reembolso",
            "description": "Assim que seu reembolso for aprovado, um crédito será aplicado automaticamente ao seu método de pagamento original dentro de 10 dias úteis."
          },
          "excessiveClaims": {
            "title": "7. Reivindicações Excessivas",
            "description": "Embora confiemos em nossos clientes, nos reservamos o direito de negar reembolsos em casos de reivindicações excessivas ou abusivas."
          },
          "damagedOrDefectiveItems": {
            "title": "8. Itens Danificados ou Defeituosos",
            "description": "Se você receber um item danificado ou defeituoso, entre em contato conosco imediatamente em <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a>. Enviaremos uma substituição sem custo adicional."
          },
          "lateOrMissingRefunds": {
            "title": "9. Reembolsos Atrasados ou Ausentes",
            "description": "Se você ainda não recebeu um reembolso, primeiro verifique novamente sua conta bancária. Em seguida, entre em contato com sua operadora de cartão de crédito, pois pode levar algum tempo até que seu reembolso seja oficialmente lançado. Se você fez tudo isso e ainda não recebeu seu reembolso, entre em contato conosco em <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a>."
          },
          "changesToThisPolicy": {
            "title": "10. Alterações nesta Política",
            "description": "Reservamo-nos o direito de modificar esta Política de Reembolso a qualquer momento. Alterações e esclarecimentos entrarão em vigor imediatamente após sua publicação no site."
          },
          "contactUs": {
            "title": "11. Contate-nos",
            "description": "Se você tiver alguma dúvida sobre nossa Política de Reembolso, entre em contato conosco:",
            "email": "info@vitadote.com",
            "phone": "+31 20 244 3918",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Países Baixos",
            "lastUpdated": "Última atualização: 19 de agosto de 2024"
          }
        }
      },
      "shippingPolicy": {
        "heading": "Política de Envio",
        "seo": {
          "metaDescription": "Conheça a política de envio da Vitadote®. Frete grátis mundial, entrega rápida e prazos claros. Saiba sobre nossas práticas de envio e informações alfandegárias."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Introdução",
            "description": "Na Vitadote.com, enviamos nossos produtos de alta qualidade dos Países Baixos usando métodos de envio seguros com rastreamento. Esta política descreve nossas práticas de envio, prazos de entrega e informações relacionadas."
          },
          "freeShipping": {
            "title": "2. Frete Grátis",
            "description": "Oferecemos frete grátis em todos os pedidos! Por favor, note que o frete grátis não inclui possíveis taxas alfandegárias."
          },
          "shippingAddress": {
            "title": "3. Endereço de Entrega",
            "description": "<strong>Importante:</strong> Não enviamos para caixas postais. Por favor, forneça um endereço de entrega físico."
          },
          "orderProcessing": {
            "title": "4. Processamento de Pedidos",
            "description": "Os pedidos são processados dentro de 1-2 dias úteis antes de serem enviados. Os prazos de entrega indicados abaixo são estimativas a partir da data de envio."
          },
          "deliveryTimes": {
            "title": "5. Prazos de Entrega",
            "description": "",
            "list": [
              "<strong>Países Baixos e Bélgica:</strong> Para pedidos feitos em dias úteis antes das 16:00, seu produto Vitadote será entregue no próximo dia útil.",
              "<strong>Alemanha:</strong> Para pedidos feitos em dias úteis antes das 16:00, a entrega geralmente ocorre no próximo dia útil. Em algumas regiões, pode levar 1-2 dias a mais devido a circunstâncias logísticas.",
              "<strong>Outros países da UE:</strong> Para pedidos feitos em dias úteis antes das 16:00, a entrega ocorre dentro de 2-4 dias úteis.",
              "<strong>Fora da UE:</strong> Para pedidos feitos em dias úteis antes das 16:00, a entrega ocorre dentro de 5-14 dias, dependendo das conexões de voo.",
              "<strong>Ásia:</strong> Entrega em breve disponível."
            ],
            "note": "<em>Tenga en cuenta:</em> Solo enviamos en días hábiles (de lunes a viernes, excluyendo festivos). Los pedidos recibidos durante el fin de semana serán procesados el siguiente día hábil."
          },
          "damagedDeliveries": {
            "title": "6. Entregas Danificadas",
            "description": "Se seu pacote Vitadote chegar danificado, informe-nos imediatamente por e-mail em <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a>."
          },
          "shippingCostsAndDeliveryTimes": {
            "title": "7. Custos de Envio e Prazos de Entrega por País",
            "description": "",
            "table": {
              "headers": ["País/Região", "Custo de Envio", "Prazo de Entrega"],
              "rows": [
                ["Países Baixos", "GRÁTIS", "2 dias"],
                ["Bélgica", "GRÁTIS", "2-5 dias"],
                ["Alemanha", "GRÁTIS", "2-5 dias"],
                ["Reino Unido", "GRÁTIS", "2-5 dias"],
                ["França", "GRÁTIS", "2-5 dias"],
                ["Espanha", "GRÁTIS", "7-10 dias"],
                ["Itália", "GRÁTIS", "7-10 dias"],
                ["Áustria", "GRÁTIS", "2-5 dias"],
                ["Polônia", "GRÁTIS", "2-5 dias"],
                ["Dinamarca", "GRÁTIS", "2-5 dias"],
                ["Suécia", "GRÁTIS", "2-5 dias"],
                ["Finlândia", "GRÁTIS", "2-5 dias"],
                ["Irlanda", "GRÁTIS", "2-5 dias"],
                ["Portugal", "GRÁTIS", "5-7 dias"],
                ["Grécia", "GRÁTIS", "7-10 dias"],
                ["República Tcheca", "GRÁTIS", "2-5 dias"],
                ["Hungria", "GRÁTIS", "2-5 dias"],
                ["Romênia", "GRÁTIS", "4-7 dias"],
                ["Bulgária", "GRÁTIS", "8-10 dias"],
                ["Suíça", "GRÁTIS", "2-5 dias"],
                ["Noruega", "GRÁTIS", "2-5 dias"]
              ]
            },
            "additionalInfo": "Os prazos de entrega para outros países não listados acima são tipicamente de 20-30 dias. Todos os envios são gratuitos."
          },
          "customs": {
            "title": "8. Alfândega",
            "description": "Taxas alfandegárias, impostos ou outros encargos não estão incluídos no preço.\n\nPara pedidos para países fora da UE, taxas adicionais podem ser aplicadas na chegada. Infelizmente, não podemos prever o valor desses custos. Por favor, entre em contato com seu escritório de alfândega local para obter informações mais precisas."
          },
          "brexitNotice": {
            "title": "9. Aviso de Brexit para Pedidos do Reino Unido",
            "description": "Devido ao Brexit, serão cobradas taxas alfandegárias adicionais e custos de desembaraço para todos os pedidos do Reino Unido com um valor total de 150 € ou mais (dependendo da taxa de câmbio atual). Por favor, note que o valor dos bens avaliados pela alfândega do Reino Unido pode ser maior que nosso preço de venda, pois o valor original de cada produto é usado como base."
          },
          "contactUs": {
            "title": "10. Contate-nos",
            "description": "Se você tiver alguma dúvida sobre nossa Política de Envio, por favor entre em contato conosco em:",
            "email": "info@vitadote.com",
            "phone": "+31 20 244 3918",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Países Baixos",
            "lastUpdated": "Última atualização: 19 de agosto de 2024"
          }
        }
      }

    },
  },
  de: {
    translation: {
      header: {
        home: "Startseite",
        about: "Über uns",
        contact: "Kontakt",
        reseller: "Werden Sie Wiederverkäufer",
      },
      common: {
        buyNow: "Jetzt kaufen",
        contactUs: "Kontaktieren",
        products: "produkte"
      },
      homePage: {
        seo: {
          pageTitle: "Willkommen bei Vitadote®. ",
          metaTitle: "Entdecken Sie Vitadote® Shilajit Harz – vitadote",
          metaDescription:
            "Entdecken Sie Vitadote® Shilajit Harz: Reine Kraft aus Altai und Himalaya. Reich an Mineralien, natürliche Ergänzung für Ihren gesunden Lebensstil.",
        },
        hero: {
          accentHeading:
            "Erleben Sie die Reinheit der Berge mit Vitadote<sup>®</sup> Shilajit Harz / Resin",
          mainHeading:
            "Entdecken Sie die Kraft der Natur mit Vitadote<sup>®</sup> Shilajit Harz",
          details:
            "Erforschen Sie die ursprünglichen Elemente der Natur mit Vitadote® Shilajit Harz. Ihre Verbindung zu authentischem Shilajit, sorgfältig gewonnen aus den imposanten Felsformationen des Altai- und Himalaya-Gebirges. Unser Shilajit enthält natürlich vorkommende Mineralien wie Eisen, Kalzium, Magnesium, Selen und Mangan sowie Fulvinsäure und wird mit Respekt für die Natur und alte Traditionen verarbeitet.",
        },
        nutrition: {
          heading: "Der Nährwert von Shilajit",
          accentHeading: "Beitrag zur empfohlenen Tagesdosis (ETD) aus 3 Portionen von je 0,3 Gramm",
          details:
            "Vitadote® Shilajit Harz hat auch eine natürliche Zusammensetzung von Mineralien. Mit einer sorgfältigen Auswahl unseres Shilajit stellen wir sicher, dass Sie unter anderem von Folgendem profitieren:",
          cards: {
            iron: {
              title: "Eisen",
              description:
                "Trägt zur normalen Bildung von roten Blutkörperchen und Hämoglobin bei. Unser Shilajit liefert 30,87% der empfohlenen Tagesdosis (ETD) an Eisen pro Tagesdosis von 0,9 Gramm",
            },
            calcium: {
              title: "Kalzium",
              description:
                "Wichtig für die normale Muskelfunktion und spielt eine Rolle im Prozess der Zellteilung und -spezialisierung. Unser Shilajit Harz enthält 72,06% der ETD an Kalzium pro Tagesdosis von 0,9 Gramm.",
            },
            magnesium: {
              title: "Magnesium",
              description:
                "Wichtig für die Erhaltung normaler Knochen und Zähne und trägt zur normalen Proteinsynthese bei. Unser Produkt liefert 108,24% der ETD an Magnesium pro Tagesdosis von 0,9 Gramm.",
            },
            selenium: {
              title: "Selen",
              description:
                "Trägt zur normalen Funktion des Immunsystems und zum Schutz der Zellen vor oxidativem Stress bei. Mit unserem Shilajit Harz erhalten Sie 22,92% der ETD an Selen pro Tagesdosis von 0,9 Gramm.",
            },
            manganese: {
              title: "Mangan",
              description:
                "Trägt zur normalen Bildung von Bindegewebe und zum Schutz der Zellen vor oxidativem Stress bei. Unser Shilajit Harz enthält 33,75% der ETD an Mangan pro Tagesdosis von 0,9 Gramm.",
            },
          },
        },
        whatisShilajit: {
          heading: "ENTDECKEN SIE DEN URSPRUNG",
          paragraph1:
            "Lassen Sie Vitadote<sup>®</sup> Shilajit Harz eine Ergänzung zu Ihrer guten Routine sein. Eine gute Routine besteht aus einem gesunden Ernährungs- und Bewegungsmuster. Vitadote<sup>®</sup> Shilajit kann dies ergänzen, direkt aus der unberührten Natur, und verbindet Sie mit einer Tradition, die die Zeit überdauert hat.",
          paragraph2: {
            bold: "Shilajit als Ergänzung zu Ihrer Ernährung",
            text: "Vitadote<sup>®</sup> zielt darauf ab, Ihre Ernährungsentscheidungen mit Shilajit, einem traditionell geschätzten Naturprodukt, zu ergänzen. Konsultieren Sie im Zweifelsfall einen Arzt und halten Sie sich immer an die Anweisungen auf der Verpackung.",
          },
          paragraph3: {
            bold: "Fulvinsäure in Vitadote<sup>®</sup> Shilajit Harz",
            text1:
              "Vitadote<sup>®</sup> Shilajit Harz enthält neben Huminsäure und Mineralien hauptsächlich Fulvinsäure.",
            text2:
              "für spezifische Informationen über die Menge an Fulvinsäure, Huminsäure und Mineralien in unserem Produkt oder besuchen Sie unsere ",
            productPage: "Produktseite",
          },
        },
        instructions: {
          heading: "Gebrauchsanweisung",
          details:
            "Wir empfehlen, dreimal täglich eine kleine Menge von 0,3 Gramm Vitadote<sup>®</sup> Shilajit Harz einzunehmen. Lösen Sie es direkt unter der Zunge oder in einem Glas nicht chloriertem Wasser für eine optimale Aufnahme.",
          step1:
            "Verwenden Sie den Spatel, um die empfohlene Dosierung aus dem Glas zu entnehmen.",
          step2:
            "Lösen Sie es in nicht-chloriertem Wasser, Kräutertee oder Milch auf, die warm ist (nicht über 39°C), und trinken Sie es. Alternativ kann es auch unter der Zunge aufgelöst werden.",
          step3:
            "Für optimale Ergebnisse empfehlen wir die regelmäßige Verwendung von Shilajit-Harz.",
        },
        FAQS: {
          heading: "Häufig gestellte Fragen",
          heading1: "Verantwortungsvoll und qualitätsorientiert",
          paragraph1:
            "Bei Vitadote<sup>®</sup> streben wir nach den höchsten Qualitätsstandards für unser Shilajit. Wir befolgen sorgfältig die geltenden Vorschriften für Nahrungsergänzungsmittel in den Niederlanden. Unsere Produkte unterliegen gründlichen Qualitätskontrollen, um die erforderlichen Lebensmittelsicherheitsstandards zu erfüllen. Wir sind stolz darauf, unseren sorgfältigen Ansatz mit relevanter Dokumentation zu untermauern. Wir zielen darauf ab, die Reinheit und Qualität jeder Charge unseres Produkts zu gewährleisten. Ausführliche Informationen über die Zusammensetzung und Qualitätsmerkmale unseres Produkts finden Sie auf unserer Website unter den Produktspezifikationen.",
          heading3:
            "Für weitere Informationen und die vollständige Liste häufig gestellter Fragen besuchen Sie bitte unsere ",
          faqsPage: "FAQ-Seite",
          faqs: {
            question1: "Was ist Vitadote<sup>®</sup> Shilajit Harz?",
            answer1:
              "Vitadote<sup>®</sup> Shilajit Harz ist eine natürliche harzartige Substanz, die aus Felsformationen im Altai- und Himalaya-Gebirge gewonnen wird. Es enthält eine einzigartige Zusammensetzung natürlich vorkommender Mineralien: Eisen, Kalzium, Magnesium, Selen und Mangan sowie organische Verbindungen wie Fulvinsäure und Huminsäure. Shilajit wird seit Jahrhunderten in traditionellen Praktiken verschiedener Kulturen verwendet.",
            question2:
              "Wie integriere und verwende ich Vitadote<sup>®</sup> Shilajit Harz als Ergänzung zu meiner täglichen Routine?",
            answer2:
              "Eine gesunde tägliche Routine besteht aus ausgewogener Ernährung und regelmäßiger Bewegung. Vitadote<sup>®</sup> Shilajit Harz kann diese ergänzen. Wenn Sie sich für die Verwendung von Shilajit entscheiden, empfehlen wir, dreimal täglich eine Portion von 0,3 Gramm (etwa die Größe eines Reiskorns bis zu einer Erbse) einzunehmen. Sie können Vitadote<sup>®</sup> Shilajit Harz unter Ihrer Zunge auflösen oder in einem Glas nicht chloriertem Wasser oder einem anderen Getränk Ihrer Wahl (bis zu 39°C) mischen.",
            question3:
              "Wie bewahre ich Vitadote<sup>®</sup> Shilajit Harz auf?",
            answer3:
              "Bewahren Sie es an einem kühlen, trockenen Ort vor direktem Sonnenlicht geschützt auf.",
            question4: "Woher kommt Vitadote<sup>®</sup> Shilajit Harz?",
            answer4:
              "Unser Shilajit wird aus den Felsformationen des Altai- und Himalaya-Gebirges gewonnen.",
            question5:
              "Kann man Vitadote<sup>®</sup> Shilajit Harz mit anderen Nahrungsergänzungsmitteln kombinieren?",
            answer5:
              "Konsultieren Sie im Zweifelsfall einen Arzt und halten Sie sich immer an die Anweisungen auf der Verpackung.",
          },
          readMore: "LESEN SIE WEITERE FAQ",
        },
        subscription: {
          heading: "Schließen Sie sich der Bewegung an",
          details:
            "Entdecken Sie selbst die besonderen Eigenschaften von Vitadote<sup>®</sup> Shilajit Harz und werden Sie Teil unserer Gemeinschaft, die den Wert natürlicher Zutaten erkennt.",
          joinNow: "Jetzt registrieren",
          placeholder: "Geben Sie Ihre E-Mail-Adresse ein",
        },
      },
      aboutPage: {
        seo: {
          metaDescription:
            "Entdecken Sie Vitadote®: Engagiert für reines Shilajit aus Altai und Himalaya. Erfahren Sie mehr über unsere Mission und nachhaltige Ernte.",
        },
        banner: {
          mainHeading:
            "Vitadote<sup>®</sup>: Pioniere des reinen Shilajit Harzes",
          subHeading: "Unsere Mission: Reinheit von den Bergen zu Ihnen",
          details:
            "Bei Vitadote<sup>®</sup> widmen wir uns der Lieferung von Shilajit Harz in seiner authentischsten Form. Inspiriert von jahrhundertealten Traditionen bietet jedes Glas Vitadote<sup>®</sup> Shilajit Harz eine Verbindung zum natürlichen Ursprung dieser einzigartigen Substanz.",
        },
        infoSection: {
          heading: "Vom Gebirge in Ihre tägliche Routine",
          details:
            "Eine gute Routine besteht aus einem gesunden Ernährungs- und Bewegungsmuster. Vitadote<sup>®</sup> kann dies ergänzen. Unser Shilajit stammt aus den Hochlagen des Altai- und Himalaya-Gebirges, Regionen, die für ihre unberührte Natur bekannt sind. Wir bringen diese natürliche Substanz mit ihrer einzigartigen Zusammensetzung von Mineralien und organischen Verbindungen wie Fulvinsäure sorgfältig zu Ihnen nach Hause.",
          commitmentHeading: "Engagement für Qualität und Sorgfalt",
          commitments1: "Bei Vitadote® legen wir großen Wert auf die Qualität und Reinheit unseres Shilajit. Unsere Produkte werden sorgfältig kontrolliert, um den geltenden Lebensmittelsicherheitsstandards zu entsprechen. Wir streben danach, transparent über unsere Prozesse und Qualitätskontrollen zu sein.",
          commitments2: "Obwohl alle Nahrungsergänzungsmittel den gesetzlichen Normen entsprechen müssen, setzen wir uns dafür ein, unsere sorgfältige Arbeitsweise nachweisbar zu machen. Dies spiegelt unser Engagement für Authentizität und Transparenz wider.",
          commitments3: "Für detaillierte Informationen über die spezifischen Eigenschaften unseres Produkts verweisen wir Sie gerne auf die Produktspezifikationen auf unserer Website.",
        },
        featuresSection: {
          feature1: {
            icon: "GppGoodIcon",
            heading: "Nachhaltige Praktiken, verantwortungsvolle Ernte",
            details:
              "Nachhaltigkeit und Respekt für die Umwelt stehen im Mittelpunkt unserer Erntemethoden. Dies ist unser Engagement, nicht nur für das Produkt, sondern auch für die Erde und ihre Bewohner.",
          },
          feature2: {
            icon: "fa-handshake",
            heading: "Unser Team",
            details:
              "Wir teilen gerne mit Ihnen unsere Einsichten in die natürlichen Eigenschaften von Shilajit und bieten Ratschläge für dessen Integration in ein ausgewogenes tägliches Regime. Ein gutes Regime besteht aus einem gesunden Ernährungs- und Bewegungsmuster. Die Verwendung von Vitadote<sup>®</sup> Shilajit Harz kann dies ergänzen.",
          },
          feature3: {
            icon: "GroupsIcon",
            heading: "Das Vitadote<sup>®</sup> Versprechen",
            details:
              "Jede Verpackung von Vitadote<sup>®</sup> Shilajit Harz steht für unser Versprechen von Qualität und Klarheit. Wir sind bestrebt, Sie näher an den natürlichen Ursprung unserer sorgfältig gesammelten Zutaten heranzuführen.",
          },
        },
      },

      faqsPage: {
        seo: {
          metaDescription:
            "Häufig gestellte Fragen zu Vitadote® Shilajit Harz. Erfahren Sie mehr über Anwendung, Vorteile und Sicherheit unseres Naturprodukts.",
        },
        banner: {
          heading:
            "Willkommen auf der FAQ-Seite von Vitadote<sup>®</sup> Shilajit Resin",
          description1:
            "Sind Sie neugierig auf Vitadote<sup>®</sup> Shilajit Resin und wie es Ihre tägliche Routine ergänzen kann? Diese Seite ist dafür da, all Ihre Fragen zu diesem außergewöhnlichen natürlichen Supplement zu beantworten. Von der einzigartigen Zusammensetzung aus Mineralien und organischen Verbindungen über Lagerungstipps bis hin zur Verträglichkeit mit anderen Supplements – hier finden Sie alles.",
          description2:
            "Erfahren Sie, wie Vitadote<sup>®</sup> Shilajit Resin sorgfältig gewonnen, im Labor getestet und entwickelt wurde, um Ihre Wellness-Bedürfnisse zu erfüllen. Egal ob Sie detaillierte Gebrauchsanweisungen, Sicherheitsinformationen oder Produkteigenschaften suchen – unsere FAQ-Seite gibt Ihnen die nötige Klarheit und Sicherheit.",
        },
        faqSection: {
          title: "Häufig gestellte Fragen zu Vitadote® Shilajit Harz",
          faqs: [
            {
              question: "Was ist Shilajit?",
              answer:
                "Shilajit ist eine natürliche harzartige Substanz, bekannt für ihren Gehalt an Eisen, Kalzium, Magnesium, Selen, Mangan, Fulvinsäure und Huminsäure. Es wird seit Jahrhunderten in verschiedenen Kulturen für seine einzigartige Zusammensetzung geschätzt und stammt aus den Felsformationen des Altai- und Himalaya-Gebirges.",
            },
            {
              question: "Wie verwende ich Vitadote® Shilajit Harz?",
              answer:
                "Wir empfehlen, dreimal täglich eine Menge von 0,3 Gramm (eine Portion von der Größe eines Reiskorns bis zu einer Erbse) einzunehmen. Sie können Vitadote® Shilajit Harz unter der Zunge auflösen oder in einem Glas nicht chloriertem Wasser oder einem anderen Getränk Ihrer Wahl (bis max. 39 °C) mischen.",
            },
            {
              question:
                "Kann ich Vitadote® Shilajit Harz mit anderen Nahrungsergänzungsmitteln kombinieren?",
              answer:
                "Obwohl Shilajit oft mit anderen Nahrungsergänzungsmitteln kombiniert wird, sollten Sie im Zweifelsfall einen Arzt konsultieren und sich immer an die Anweisungen auf der Verpackung halten.",
            },
            {
              question:
                "Gibt es bekannte Nebenwirkungen von Vitadote® Shilajit Harz?",
              answer:
                "Vitadote® Shilajit Harz wird im Allgemeinen gut vertragen, wenn es gemäß der empfohlenen Dosierung verwendet wird. Individuelle Empfindlichkeiten können variieren. Konsultieren Sie im Zweifelsfall einen Arzt und halten Sie sich immer an die Anweisungen auf der Verpackung.",
            },
            {
              question: "Wie bewahre ich Vitadote® Shilajit Harz auf?",
              answer:
                "Es sollte an einem kühlen, trockenen Ort außerhalb direkter Sonneneinstrahlung aufbewahrt werden. Außerhalb der Reichweite von Kindern aufbewahren.",
            },
            {
              question:
                "Kann ich Vitadote® Shilajit Harz während der Schwangerschaft oder Stillzeit verwenden?",
              answer:
                "Wenn Sie schwanger sind oder stillen, ist es wichtig, bei Nahrungsergänzungsmitteln vorsichtig zu sein. Wir empfehlen Ihnen dringend, vor der Verwendung von Vitadote® Shilajit Harz einen Arzt zu konsultieren.",
            },
            {
              question:
                "Ist Vitadote® Shilajit Harz für Vegetarier und Veganer geeignet?",
              answer:
                "Ja, Vitadote® Shilajit Harz ist sowohl für Vegetarier als auch für Veganer geeignet.",
            },
            {
              question:
                "Wie lange kann ich Vitadote® Shilajit Harz nach dem Öffnen verwenden?",
              answer:
                "Solange es richtig gelagert wird, kann Vitadote® Shilajit Harz über einen langen Zeitraum nach dem Öffnen verwendet werden. Überprüfen Sie immer das Haltbarkeitsdatum auf der Verpackung.",
            },
            {
              question: "Ist Vitadote® Shilajit Harz Halal?",
              answer:
                "Vitadote® Shilajit Harz ist ein pflanzliches Produkt und enthält keine tierischen Produkte, wodurch es für Menschen geeignet sein sollte, die eine Halal-Diät befolgen.",
            },
            {
              question:
                "Enthält Vitadote® Shilajit Harz Allergene oder Gluten?",
              answer:
                "Vitadote® Shilajit Harz ist frei von bekannten Allergenen und Gluten. Der Herstellungsprozess gewährleistet, dass unser Shilajit frei von den meisten gängigen Allergenen ist, einschließlich Nüssen, Gluten, Laktose und Soja. Für die aktuellsten Informationen zu Allergenen empfehlen wir, die Etikettinformationen zu überprüfen oder direkt mit uns Kontakt aufzunehmen.",
            },
            {
              question:
                "Kann Vitadote® Shilajit Harz eine allergische Reaktion bei Menschen mit einer Kokosnussallergie verursachen?",
              answer:
                "Obwohl Vitadote® Shilajit Harz keine Zutaten enthält, die allgemein als Allergene bekannt sind, enthält es eine kleine Menge Kokosöl, die bei Menschen mit einer spezifischen Allergie gegen Kokosnuss eine allergische Reaktion auslösen kann. Wenn Sie eine Kokosnussallergie haben, ist es ratsam, vorsichtig zu sein und vor der Verwendung von Vitadote® Shilajit Harz mit Ihrem Gesundheitsdienstleister zu sprechen.",
            },
            {
              question:
                "Kann Vitadote® Shilajit Harz in Kombination mit Medikamenten verwendet werden?",
              answer:
                "Wir empfehlen, zuerst mit Ihrem Arzt oder Apotheker zu sprechen, bevor Sie Vitadote® Shilajit Harz mit Medikamenten kombinieren.",
            },
          ],
          contact: "Haben Sie weitere Fragen?",
          contactLink: "Dann kontaktieren Sie uns bitte.",
        },
      },
      contactPage: {
        seo: {
          metaDescription:
            "Kontaktieren Sie Vitadote® bei Fragen oder Unterstützung zu unsere Produkte. Erreichen Sie uns per Formular, E-Mail oder Telefon. Wir helfen Ihnen gerne!",
        },
        banner: {
          heading: "KONTAKTIEREN SIE UNS",
          subHeading: "Wir sind für Sie da!",
          description:
            "Haben Sie Fragen, benötigen Sie Unterstützung oder möchten Sie mehr über Vitadote® und unsere Produkte erfahren? Kontaktieren Sie uns noch heute! Sie können uns per E-Mail, Telefon oder über das Kontaktformular unten erreichen. Unser Team hilft Ihnen gerne bei allen Anliegen weiter.",
        },
        infoSection: {
          infoHeading: "INFORMATIONEN",
          addressHeading: "Adresse",
          address: "Albert Huismanpad 166, 1502EK Zaandam, Niederlande",
          phoneHeading: "Telefon",
          emailHeading: "E-Mail",
          formFields: {
            nameLabel: "Name",
            emailLabel: "E-Mail",
            phoneLabel: "Telefon",
            messageLabel: "Nachricht",
            submitButton: "JETZT ABSENDEN",
          },
        },
      },
      "resellerPage": {
        "seo": {
          "metaDescription": "Werden Sie exklusiver Vitadote® Shilajit Wiederverkäufer. Premium-Nahrungsergänzungsmittel mit Verkaufsrechten, hohen Margen und Marketingsupport."
        },
        "banner": {
          "heading": "WERDEN SIE VITADOTE<sup>®</sup> SHILAJIT WIEDERVERKÄUFER IN EUROPA",
          "subHeading": "Suchen Sie nach einem einzigartigen und hochwertigen Produkt, um Ihr Sortiment zu erweitern?",
          "description": "Vitadote®, ein führender Hersteller und Markeninhaber von Premium-Shilajit, sucht engagierte Wiederverkäufer und Shilajit-Distributoren in ganz Europa. Unser exklusives Wiederverkäuferprogramm bietet Möglichkeiten für den Großhandel mit natürlichen Nahrungsergänzungsmitteln."
        },
        "whysellHeading": "WARUM VITADOTE<sup>®</sup> SHILAJIT VERKAUFEN?",
        "whySell": [
          {
            "icon": "/icons/authentication.svg",
            "feature": "Authentisches Produkt",
            "description": "Direkt aus den Altai- und Himalaya-Gebirgen geerntet"
          },
          {
            "icon": "/icons/high-quality.svg",
            "feature": "Hohe Qualität",
            "description": "Strenge Qualitätskontrollen und Einhaltung der EU-Vorschriften"
          },
          {
            "icon": "/icons/growing-business.svg",
            "feature": "Wachsender Markt",
            "description": "Steigende Nachfrage nach natürlichen Nahrungsergänzungsmitteln"
          },
          {
            "icon": "/icons/proposition.svg",
            "feature": "Einzigartiges Angebot",
            "description": "Besondere Zusammensetzung aus Mineralien und Fulvinsäure"
          },
          {
            "icon": "/icons/brand-identity.svg",
            "feature": "Starke Markenidentität",
            "description": "Professionelles Erscheinungsbild und Marketingunterstützung"
          }
        ],
        "benefitsHeading": "Vorteile als Vitadote<sup>®</sup> Wiederverkäufer",
        "benefits": [
          {
            "feature": "Exklusive Verkaufsrechte als Shilajit-Distributor in Ihrer Region"
          },
          {
            "feature": "Attraktive Gewinnspannen für Wiederverkäufer und Großhändler"
          },
          {
            "feature": "Zugang zu unserem umfangreichen Wiederverkäuferprogramm mit Marketingmaterialien und Produktschulungen"
          },
          {
            "feature": "Kontinuierliche Unterstützung durch unser erfahrenes Team"
          },
          {
            "feature": "Möglichkeit, vom wachsenden Interesse an natürlichen Nahrungsergänzungsmitteln zu profitieren"
          }
        ],
        "lookingForHeading": "Wen suchen wir?",
        "lookingFor": [
          {
            "title": "Etablierte Einzelhändler und Shilajit-Distributoren im Gesundheitssektor"
          },
          {
            "title": "Online-Verkäufer und Großhändler mit Fokus auf natürliche Nahrungsergänzungsmittel"
          },
          {
            "title": "Vertriebspartner für Naturprodukte"
          },
          {
            "title": "Wellness-Center und Gesundheitspraxen"
          }
        ],
        "footerHeading": "Interesse an einer Vitadote<sup>®</sup> Wiederverkäuferschaft?",
        "footerDescription": "Kontaktieren Sie uns noch heute und erfahren Sie, wie Sie über unser einzigartiges Wiederverkäuferprogramm Shilajit-Distributor oder exklusiver Wiederverkäufer werden können. Werden Sie Teil des Vitadote®-Netzwerks und profitieren Sie von der wachsenden Nachfrage nach Premium-Shilajit in Europa."
      },
      "products": {
        "badges": {
          "topSelling": "Bestseller",
          "save8": "Spare €8",
          "save27": "Spare €27"
        },
        "images": [
          "/images/products-de/04. shilajit fulvinsäure gehalt vergleich.jpg",
          "/images/products-de/05. shilajit für anti-aging und hautgesundheit.jpg",
          "/images/products-de/11. Vitadote Shilajit Harz Zutaten.jpg",
          "/images/products-de/06. bio-shilajit kapseln vs harz wirksamkeit.jpg",
          "/images/product-picture-3.jpg",
          "/images/products-de/10. shilajit nebenwirkungen und sicherheitsvorkehrungen.jpg",
          "/images/products-de/07. shilajit für testosteronschub und muskelwachstum.jpg",
          "/images/products-de/08. shilajit vorteile für hormonelles gleichgewicht bei frauen.jpg",
          "/images/products-de/09. shilajit für kognitive funktion und hirngesundheit.jpg",
        ],
        "productName1": "1x reines shilajit harz",
        "productName2": "2x reines shilajit harz",
        "productName3": "3x reines shilajit harz",
        "ourProducts": "Unsere Produkte",
        "relatedProducts": "Verwandte Produkte",
        "seo": {
          "metaTitle": "Reines Shilajit-Harz | Altai- & Himalaya-Quelle | Fulvinsäure von Vitadote®",
          "metaDescription": "Entdecken Sie Vitadote® Shilajit-Harz aus dem Altai- und Himalaya-Gebirge. Laborgetestet, UV-geschützt und reich an Fulvinsäure für natürliche Mineralien.",
          "slug1": "1x-reines-shilajit-harz",
          "slug2": "2x-reines-shilajit-harz",
          "slug3": "3x-reines-shilajit-harz",
        },
        "banner": {
          "heading": "Reines Shilajit-Harz | Altai- & Himalaya-Quelle | Fulvinsäure von Vitadote®",
          "keyQuality": "Wichtige Merkmale von Vitadote® Shilajit-Harz",
          "benefits": [
            {
              "title": "Direkt aus dem Altai- und Himalaya-Gebirge",
              "description": "Geerntet in unberührten Bergregionen, wo Shilajit seine reiche Mineralzusammensetzung entwickelt."
            },
            {
              "title": "UV-geschützte Glasverpackung",
              "description": "Verpackt in UV-Glas, das die Potenz bewahrt, indem es schädliche Lichtstrahlen blockiert."
            },
            {
              "title": "Labortests und hohe Qualität",
              "description": "Jede Charge wird streng getestet und ist HACCP-zertifiziert, was ein hochwertiges, reines Shilajit-Produkt garantiert."
            },
            {
              "title": "KOAG/KAG- & NVWA-Zertifikate",
              "description": "Diese Zertifikate bestätigen, dass unser Shilajit-Harz strenge Sicherheits- und Qualitätsstandards erfüllt."
            }
          ]
        },
        "detailsTabs": {
          "description": {
            "title": "Produktbeschreibung",
            "desciption": "<b>Vitadote® Shilajit-Harz</b> ein hochwertiges, natürliches Supplement, das aus den hochgelegenen Regionen des <b>Altai und Himalaya-Gebirges</b> stammt—liefert eine reine und unverfälschte Form von Shilajit. Reich an essenziellen Mineralien wie <b>Eisen, Kalzium, Magnesium, Selen, Mangan, Zink</b>, und <b>Fulvinsäure </b>, bringt dieses sorgfältig gewonnene Harz die Kraft der Natur in Ihre tägliche Wohlfühl-Routine.",
            "features": [
              {
                "title": "Anwendung",
                "description": "Für optimale Ergebnisse nehmen Sie <b>0,3 Gramm</b> (ungefähr die Größe eines Reiskorns bis zu einer Erbse) dreimal täglich. Lösen Sie es unter der Zunge auf oder mischen Sie es in <b>nicht-chloriertem Wasser</b> oder einem <b>Getränk Ihrer Wahl (bis zu 39°C).</b>"
              },
              {
                "title": "Rein und Zuverlässig",
                "description": "<b>Vitadote®</b> steht für authentisches, verantwortungsvoll gewonnenes Shilajit-Harz. Wir stellen sicher, dass jedes Glas unseren hohen Standards für Qualität und Reinheit entspricht, damit Sie Shilajit so naturbelassen wie möglich erleben können."
              },
              {
                "title": "Entdecken Sie die Essenz von Shilajit mit Vitadote®",
                "description": "Wählen Sie <b>Vitadote® Shilajit-Harz</b>, um Ihre Wellness-Reise mit reinem, unverarbeitetem Shilajit direkt aus dem <b>Altai- und Himalaya-Gebirge</b> zu bereichern."
              }
            ]
          },
          "reviews": {
            "title": "REVIEWS",
            "customersReviews": "Customer's Reviews",
            "verifiedPurchases": "All from verified purchases",
            "allReviewsWithDetails": "All reviews with details",
            customerReviews: [
              {
                "clientName": "Hassan Iqbal Hashmi",
                "date": "2023-05-16",
                "profile_image": "https://lh3.googleusercontent.com/a-/ACB-R5Tsv5u4-Z7qiRmBTw5JSsm7JolJNfZNeVWlzZIefiY=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Finally find pure Shilajit Resins. It has done what was expected. Thank you."
              },
              {
                "clientName": "Rebecca Sperry",
                "date": "2023-05-03",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxZgq-bSMicabswNP3HG1r1k8p7M6ekBDc-DYVx_=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Shilajit is een super resin, ik neem elke ochtend op mij werk, en ja hoor het helpt met alles, mij houd en haar is veel beter al binnen 3 weken!"
              },
              {
                "clientName": "Doeke Leerink",
                "date": "2023-03-09",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxbqo1czKEDR9BiUlb0FgAUH2ekolUQruV6U-Kod=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Na veel zoeken hier het echte product gevonden. De website geeft uitgebreide informatie en er wordt snel geleverd. Sterk aan te raden."
              },
              {
                "clientName": "Alex Berkov",
                "date": "2023-03-07",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxYgIJuXgqYb5Hg505DuNAp9quCSFrs-AgkNJ34l=s120-c-c-rp-w64-h64-mo-ba3-br100",
                "stars": 5,
                "review": "Folosesc de aprox 1 luna shilajit de la Vitadote pot spune ca in prima faza am acoperit gustul shilajitului cu miere de albine 🙂 Efectele sale nu încetează sa tot apara, de la lipsa balonării pana la mai multa energie si focus/atentie. Mulțumesc."
              },
              {
                "clientName": "Maysoon Kelani",
                "date": "2023-02-21",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxaR9AJ46lfbFL_DYEXp8BiUns9E1HRaLVCpdJ6q=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Best product ever thanks alot!"
              },
              {
                "clientName": "Heba Khatib",
                "date": "2023-02-21",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxZksHg6l3jwZQAh6QZv4nkl7cZnzZqr3aRMka4y=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Super team and best product ever ❤️."
              },
              {
                "clientName": "Adrian Rosu Savinoiu",
                "date": "2023-02-21",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxbd7wCzON2KNosyp0gTka5yPGTdlh28C4hWJhmQ=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "L-am luat pentru sotia mea, prima imbunatatire pe care a simtit-o este ca nu mai are picioarele reci. E primul remediu incercat de ea care a reusit acest lucru. Gustul nu e atat de rau pe cat se spune mai ales daca e pus in ceai. Cutia si modul de prezentare i-au placut enorm. Produsul vine cu posta romana, deci nu ajunge chiar in 3 zile (nu din vina postei ci din cauza vamii). Vitadote a fost foarte profesionista din punctul de vedere al livrarii, link cu AWB atat posta din Olanda, am comunicat pe WhatsApp fara probleme."
              },
              {
                "clientName": "Daniela Răducănescu",
                "date": "2023-02-10",
                "profile_image": "https://lh3.googleusercontent.com/a-/ACB-R5RuEdC9WO1A6Y0OUlXUKO7s4z8jReTVqZekkR_2M1Q=s120-c-c-rp-w64-h64-mo-ba4-br100",
                "stars": 5,
                "review": "#shilajit @Vitadote Shilajitul este excelent! L-am și testat pentru autenticitate, iar la toate 3 testări rezultatele au arătat că este autentic. Am luat aseară un pahar de ceai cald in care am dizolvat shilajitul primit de la Vitadote. Nu am reușit să mai dorm până pe la ora 2 de noapte. Deci energizarea corpului este ca și cum aș fi băut o cană de ness. Comunicarea excelentă! Imediat ce au primit comanda, mi-au scris pe WhatsApp! Au grijă de client și s-au asigurat că am primit coletul. Minunat! Exact ce mi-am dorit! Mulțumesc, Vitadote!"
              },
              {
                "clientName": "dragos negrau",
                "date": "2023-02-08",
                "profile_image": "https://lh3.googleusercontent.com/a-/ACB-R5RKX3q3g1OFHa_YAA-D6Oa9M9cVSnW13vSDUABr=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Your product it’s life changer! Super team! Fast shipping..."
              }
            ]
          }
        }
      },
      "privacyPolicy": {
        "heading": "Datenschutzrichtlinie",
        "seo": {
          "metaDescription": "Vitadote® Datenschutzrichtlinie: Wie wir Ihre Daten erfassen, nutzen und schützen. Infos zu Ihren Rechten und unserer DSGVO-Konformität."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Einleitung",
            "description1": "Bei Vitadote® verpflichten wir uns, Ihre Privatsphäre zu schützen und Ihre personenbezogenen Daten zu sichern. Diese Datenschutzrichtlinie erklärt, wie wir Ihre personenbezogenen Daten erheben, verwenden, offenlegen und schützen, wenn Sie unsere Website vitadote.com besuchen oder unsere Dienstleistungen nutzen.",
            "description2": "eCom IT BV (\"eCom IT\", \"wir\", \"uns\" oder \"unser\"), handelnd unter dem Markennamen Vitadote®, ist der für die Verarbeitung Ihrer personenbezogenen Daten verantwortliche Datencontroller, wie in dieser Datenschutzrichtlinie beschrieben."
          },
          "informationWeCollect": {
            "title": "2. Informationen, die wir erheben",
            "description": "Wir können folgende Arten von personenbezogenen Informationen erheben:",
            "list": [
              "Identitäts- und Kontaktinformationen (z.B. Name, E-Mail-Adresse, Telefonnummer, Postanschrift)",
              "Kontoinformationen (z.B. Benutzername, Passwort)",
              "Bestell- und Transaktionsdaten",
              "Zahlungsinformationen",
              "Kommunikation mit uns (z.B. Kundendienstanfragen)",
              "Nutzungsdaten (z.B. wie Sie unsere Website nutzen)",
              "Technische Daten (z.B. IP-Adresse, Browsertyp)"
            ]
          },
          "howWeUse": {
            "title": "3. Wie wir Ihre Informationen verwenden",
            "description": "Wir verwenden Ihre personenbezogenen Informationen für folgende Zwecke:",
            "list": [
              "Um Ihre Bestellungen zu bearbeiten und zu liefern",
              "Um Ihr Konto bei uns zu verwalten",
              "Um Kundensupport zu bieten",
              "Um Sie über wichtige Änderungen unserer Dienstleistungen zu informieren",
              "Um unsere Website und Dienstleistungen zu verbessern",
              "Um Betrug zu verhindern und aufzudecken",
              "Um unseren rechtlichen Verpflichtungen nachzukommen"
            ]
          },
          "legalBases": {
            "title": "4. Rechtsgrundlagen für die Verarbeitung",
            "description": "Wir verarbeiten Ihre personenbezogenen Daten auf Grundlage einer oder mehrerer der folgenden Rechtsgrundlagen:",
            "list": [
              "Erfüllung eines Vertrags mit Ihnen",
              "Unsere berechtigten Geschäftsinteressen",
              "Einhaltung einer rechtlichen Verpflichtung",
              "Ihre Einwilligung (wo erforderlich)"
            ],
            "extraInfo": "Vitadote® entspricht der Datenschutz-Grundverordnung (DSGVO) der Europäischen Union. Wir nehmen Ihre Privatsphäre ernst und stellen sicher, dass alle unsere Datenverarbeitungsaktivitäten im Einklang mit der DSGVO stehen. Dies beinhaltet die Erhebung nur notwendiger Daten, die Implementierung angemessener Sicherheitsmaßnahmen, die Achtung Ihrer Rechte als betroffene Person und Transparenz bezüglich unserer Datenverarbeitungspraktiken."
          },
          "sharingInfo": {
            "title": "5. Weitergabe Ihrer Informationen",
            "description": "Wir können Ihre personenbezogenen Informationen weitergeben an:",
            "list": [
              "Unsere Dienstleister (z.B. Hosting-Anbieter, Zahlungsabwickler)",
              "Lieferdienste",
              "Strafverfolgungsbehörden oder andere Regierungsbehörden (wenn gesetzlich erforderlich)"
            ],
            "note": "Wir verkaufen Ihre personenbezogenen Informationen nicht an Dritte."
          },
          "dataRetention": {
            "title": "6. Datenspeicherung",
            "description": "Wir speichern Ihre personenbezogenen Informationen so lange, wie es zur Erfüllung der Zwecke, für die sie erhoben wurden, erforderlich ist oder um gesetzlichen Anforderungen zu entsprechen."
          },
          "dataSecurity": {
            "title": "7. Datensicherheit",
            "description": "Wir haben angemessene technische und organisatorische Maßnahmen implementiert, um Ihre personenbezogenen Informationen vor unbefugtem Zugriff, Verlust, Missbrauch oder Zerstörung zu schützen."
          },
          "yourRights": {
            "title": "8. Ihre Rechte",
            "description": "Gemäß der DSGVO und anderen geltenden Datenschutzgesetzen haben Sie bestimmte Rechte in Bezug auf Ihre personenbezogenen Daten, einschließlich:",
            "list": [
              "Recht auf Auskunft",
              "Recht auf Berichtigung",
              "Recht auf Löschung",
              "Recht auf Einschränkung der Verarbeitung",
              "Recht auf Datenübertragbarkeit",
              "Recht auf Widerspruch gegen die Verarbeitung"
            ],
            "extraInfo": "Um Ihre Rechte auszuüben, kontaktieren Sie uns bitte unter den am Ende dieser Richtlinie angegebenen Kontaktdaten."
          },
          "cookies": {
            "title": "9. Cookies und ähnliche Technologien",
            "description": "Wir verwenden Cookies und ähnliche Technologien, um Ihr Erlebnis auf unserer Website zu verbessern und zu verstehen, wie unsere Website genutzt wird. Weitere Informationen über unsere Verwendung von Cookies finden Sie in unserer "
          },
          "internationalTransfers": {
            "title": "10. Internationale Datenübertragungen",
            "description": "Wir können Ihre personenbezogenen Informationen in Länder außerhalb des Europäischen Wirtschaftsraums (EWR) übertragen und dort verarbeiten."
          },
          "policyChanges": {
            "title": "11. Änderungen dieser Datenschutzrichtlinie",
            "description": "Wir können diese Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Die aktuellste Version wird immer auf unserer Website verfügbar sein."
          },
          "contactUs": {
            "title": "12. Kontaktieren Sie uns",
            "text1": "Wenn Sie Fragen, Kommentare oder Anfragen bezüglich dieser Datenschutzrichtlinie oder unserer Datenschutzpraktiken haben, kontaktieren Sie uns bitte unter:",
            "text2": "E-Mail: ",
            "text3": "Post: Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Niederlande",
            "text4": "Sie haben auch das Recht, eine Beschwerde bei der niederländischen Datenschutzbehörde (Autoriteit Persoonsgegevens) einzureichen.",
            "lastUpdated": "Zuletzt aktualisiert: 19. August 2024"
          }
        }
      },
      "cookiePolicy": {
        "heading": "Cookie-Richtlinie",
        "seo": {
          "metaDescription": "Vitadote® Cookie-Richtlinie: Nutzung von Cookies, deren Zweck und wie Sie Ihre Einstellungen verwalten können. Aktualisiert am 19. August 2024."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Einleitung",
            "description": "Diese Cookie-Richtlinie erläutert, wie Vitadote® (\"wir\", \"uns\" oder \"unser\"), betrieben von eCom IT BV, Cookies und ähnliche Technologien auf unserer Website vitadote.com verwendet. Diese Richtlinie bietet Ihnen klare und umfassende Informationen über die von uns verwendeten Cookies und die Zwecke ihrer Verwendung."
          },
          "whatAreCookies": {
            "title": "2. Was sind Cookies?",
            "description": "Cookies sind kleine Textdateien, die auf Ihrem Gerät platziert werden, wenn Sie eine Website besuchen. Sie werden häufig verwendet, um Websites effizienter funktionieren zu lassen und den Betreibern der Website Informationen zu liefern. Cookies können \"persistente\" oder \"Sitzungs\"-Cookies sein."
          },
          "howWeUseCookies": {
            "title": "3. Wie wir Cookies verwenden",
            "description": "Wir verwenden Cookies für folgende Zwecke:",
            "list": [
              {
                "name": "Essentielle Cookies",
                "purpose": "Dies sind Cookies, die für den Betrieb unserer Website erforderlich sind. Dazu gehören beispielsweise Cookies, die es Ihnen ermöglichen, sich in sichere Bereiche unserer Website einzuloggen oder einen Einkaufswagen zu benutzen."
              },
              {
                "name": "Analytische/Performance-Cookies",
                "purpose": "Diese ermöglichen es uns, Besucher zu erkennen und zu zählen sowie zu sehen, wie sich Besucher auf unserer Website bewegen, wenn sie diese nutzen."
              },
              {
                "name": "Funktionalitäts-Cookies",
                "purpose": "Diese werden verwendet, um Sie zu erkennen, wenn Sie auf unsere Website zurückkehren. Dies ermöglicht es uns, unsere Inhalte für Sie zu personalisieren und Ihre Präferenzen zu speichern."
              },
              {
                "name": "Targeting-Cookies",
                "purpose": "Diese Cookies zeichnen Ihren Besuch auf unserer Website, die von Ihnen besuchten Seiten und die von Ihnen verfolgten Links auf."
              }
            ]
          },
          "thirdPartyCookies": {
            "title": "4. Cookies von Drittanbietern",
            "description": "Zusätzlich zu unseren eigenen Cookies können wir auch Cookies verschiedener Drittanbieter verwenden, um Nutzungsstatistiken des Dienstes zu melden, Werbung auf und über den Dienst zu liefern und so weiter."
          },
          "specificCookies": {
            "title": "5. Welche spezifischen Cookies verwenden wir?",
            "description": "Hier ist eine Liste der wichtigsten Cookies, die wir auf vitadote.com verwenden:",
            "table": [
              { "type": "Sitzungs-Cookie", "name": "PHPSESSID", "purpose": "Bewahrt den Sitzungsstatus des Benutzers über Seitenanfragen hinweg", "duration": "Sitzung" },
              { "type": "Authentifizierungs-Cookie", "name": "auth_token", "purpose": "Hält den Benutzer eingeloggt", "duration": "30 Tage" },
              { "type": "Warenkorb-Cookie", "name": "cart_items", "purpose": "Speichert Artikel im Warenkorb des Benutzers", "duration": "30 Tage" },
              { "type": "Sprachpräferenz-Cookie", "name": "site_language", "purpose": "Speichert die Sprachpräferenz des Benutzers", "duration": "1 Jahr" },
              { "type": "Google Analytics Cookies", "name": "_ga, _gid, _gat", "purpose": "Verwendet, um Benutzer zu unterscheiden und die Anfragerate zu drosseln", "duration": "_ga: 2 Jahre, _gid: 24 Stunden, _gat: 1 Minute" },
              { "type": "Facebook Pixel Cookie", "name": "_fbp", "purpose": "Von Facebook verwendet, um eine Reihe von Werbeprodukten zu liefern", "duration": "3 Monate" },
              { "type": "Einwilligungs-Cookie", "name": "cookie_consent", "purpose": "Speichert, ob der Benutzer den Cookie-Hinweis akzeptiert hat", "duration": "1 Jahr" },
              { "type": "Produktempfehlungs-Cookie", "name": "recent_views", "purpose": "Speichert kürzlich angesehene Produkte für Empfehlungen", "duration": "30 Tage" },
              { "type": "Performance-Cookie", "name": "page_load_time", "purpose": "Misst Seitenladezeiten zur Verbesserung der Website-Performance", "duration": "Sitzung" },
              { "type": "A/B-Test-Cookie", "name": "ab_test_variant", "purpose": "Speichert, welche Version eines Split-Tests der Benutzer gesehen hat", "duration": "30 Tage" }
            ],
            "description2": "Bitte beachten Sie, dass Dienste von Drittanbietern wie Google Analytics oder Social-Media-Plattformen möglicherweise zusätzliche Cookies setzen. Für weitere Informationen über diese Cookies verweisen wir Sie auf die jeweiligen Datenschutzrichtlinien dieser Dienste.",
          },
          "choicesRegardingCookies": {
            "title": "6. Ihre Wahlmöglichkeiten bezüglich Cookies",
            "description": [
              "Wenn Sie Cookies löschen oder Ihren Webbrowser anweisen möchten, Cookies zu löschen oder abzulehnen, besuchen Sie bitte die Hilfeseiten Ihres Webbrowsers.",
              "Bitte beachten Sie jedoch, dass Sie, wenn Sie Cookies löschen oder sich weigern, sie zu akzeptieren, möglicherweise nicht alle von uns angebotenen Funktionen nutzen können."
            ]
          },
          "changesToPolicy": {
            "title": "7. Änderungen unserer Cookie-Richtlinie",
            "description": "Wir können unsere Cookie-Richtlinie von Zeit zu Zeit aktualisieren. Wir werden Sie über alle Änderungen informieren, indem wir die neue Cookie-Richtlinie auf dieser Seite veröffentlichen und das Datum der \"Letzten Aktualisierung\" am Anfang dieser Richtlinie aktualisieren."
          },
          "contactUs": {
            "title": "8. Kontaktieren Sie uns",
            "description": "Wenn Sie Fragen zu unserer Verwendung von Cookies oder dieser Cookie-Richtlinie haben, kontaktieren Sie uns bitte unter:",
            "email": "info@vitadote.com",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Niederlande",
            "lastUpdated": "Zuletzt aktualisiert: 19. August 2024"
          }
        }
      },
      "termsAndConditions": {
        "heading": "Allgemeine Geschäftsbedingungen",
        "seo": {
          "metaDescription": "Vitadote® AGB: Ihre Rechte und Pflichten bei der Nutzung unserer Website und Dienste. Aktualisiert am 19. August 2024."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Einleitung",
            "description": "Willkommen bei Vitadote.com. Diese Allgemeinen Geschäftsbedingungen (\"AGB\") regeln Ihre Nutzung der Vitadote® Website und Dienstleistungen (zusammen der \"Dienst\"), die von eCom IT BV (\"eCom IT\", \"wir\", \"uns\" oder \"unser\") betrieben werden. Durch den Zugriff auf oder die Nutzung des Dienstes erklären Sie sich mit diesen AGB einverstanden. Wenn Sie mit einem Teil der AGB nicht einverstanden sind, dürfen Sie den Dienst nicht nutzen."
          },
          "definitions": {
            "title": "2. Definitionen",
            "description": "\"Sie\" bezieht sich auf die Person, die auf den Dienst zugreift oder ihn nutzt, oder das Unternehmen oder eine andere juristische Person, in deren Namen diese Person auf den Dienst zugreift oder ihn nutzt."
          },
          "accountRegistration": {
            "title": "3. Kontoregistrierung",
            "description": "Bei der Erstellung eines Kontos bei uns müssen Sie genaue, vollständige und aktuelle Informationen angeben. Andernfalls stellt dies einen Verstoß gegen die AGB dar, was zur sofortigen Kündigung Ihres Kontos für unseren Dienst führen kann."
          },
          "useOfService": {
            "title": "4. Nutzung des Dienstes",
            "description": "Sie stimmen zu, den Dienst nur für rechtmäßige Zwecke und in Übereinstimmung mit diesen AGB zu nutzen. Sie stimmen zu, den Dienst nicht zu nutzen:",
            "list": [
              "A. In einer Weise, die gegen geltende nationale oder internationale Gesetze oder Vorschriften verstößt.",
              "B. Zur Übermittlung oder Veranlassung der Übermittlung von Werbe- oder Werbematerial, einschließlich \"Junk-Mail\", \"Kettenbriefen\", \"Spam\" oder anderen ähnlichen Aufforderungen.",
              "C. Um sich als eCom IT, einen eCom IT-Mitarbeiter, einen anderen Benutzer oder eine andere Person oder Einrichtung auszugeben oder dies zu versuchen.",
              "D. In einer Weise, die die Rechte anderer verletzt oder in irgendeiner Weise illegal, bedrohlich, betrügerisch oder schädlich ist, oder in Verbindung mit einem rechtswidrigen, illegalen, betrügerischen oder schädlichen Zweck oder einer solchen Aktivität."
            ]
          },
          "intellectualProperty": {
            "title": "5. Geistiges Eigentum",
            "description": "Der Dienst und seine ursprünglichen Inhalte, Funktionen und Funktionalität sind und bleiben das ausschließliche Eigentum von eCom IT und seinen Lizenzgebern. Der Dienst ist durch Urheberrechte, Marken und andere Gesetze sowohl der Niederlande als auch anderer Länder geschützt. Unsere Marken und Handelsaufmachung dürfen nicht in Verbindung mit einem Produkt oder einer Dienstleistung ohne vorherige schriftliche Zustimmung von eCom IT verwendet werden."
          },
          "userContent": {
            "title": "6. Benutzerinhalte",
            "description": "Unser Dienst kann es Ihnen ermöglichen, bestimmte Informationen, Texte, Grafiken, Videos oder anderes Material (\"Inhalt\") zu posten, zu verlinken, zu speichern, zu teilen und anderweitig verfügbar zu machen. Sie sind verantwortlich für den Inhalt, den Sie auf oder über den Dienst posten, einschließlich seiner Rechtmäßigkeit, Zuverlässigkeit und Angemessenheit."
          },
          "linksToOtherWebsites": {
            "title": "7. Links zu anderen Websites",
            "description": "Unser Dienst kann Links zu Websites oder Diensten Dritter enthalten, die nicht im Besitz oder unter der Kontrolle von eCom IT sind. eCom IT hat keine Kontrolle über und übernimmt keine Verantwortung für den Inhalt, die Datenschutzrichtlinien oder Praktiken von Websites oder Diensten Dritter. Sie erkennen ferner an und stimmen zu, dass eCom IT weder direkt noch indirekt verantwortlich oder haftbar ist für Schäden oder Verluste, die durch oder in Verbindung mit der Nutzung oder dem Vertrauen auf solche Inhalte, Waren oder Dienste, die auf oder über solche Websites oder Dienste verfügbar sind, verursacht werden oder angeblich verursacht werden."
          },
          "termination": {
            "title": "8. Kündigung",
            "description": "Wir können Ihr Konto sofort kündigen oder aussetzen, ohne vorherige Ankündigung oder Haftung, aus beliebigem Grund, einschließlich, ohne Einschränkung, wenn Sie gegen die AGB verstoßen. Mit der Kündigung erlischt Ihr Recht zur Nutzung des Dienstes sofort."
          },
          "limitationOfLiability": {
            "title": "9. Haftungsbeschränkung",
            "description": "In keinem Fall haften eCom IT oder seine Direktoren, Mitarbeiter, Partner, Vertreter, Lieferanten oder verbundenen Unternehmen für indirekte, zufällige, besondere, Folge- oder Strafschäden, einschließlich, ohne Einschränkung, Verlust von Gewinnen, Daten, Nutzung, Goodwill oder anderen immateriellen Verlusten, die sich aus Ihrem Zugriff auf oder der Nutzung oder der Unfähigkeit des Zugriffs auf oder der Nutzung des Dienstes ergeben."
          },
          "disclaimer": {
            "title": "10. Haftungsausschluss",
            "description": "Ihre Nutzung des Dienstes erfolgt auf Ihr eigenes Risiko. Der Dienst wird auf einer \"WIE BESEHEN\" und \"WIE VERFÜGBAR\" Basis bereitgestellt. Der Dienst wird ohne Gewährleistungen jeglicher Art bereitgestellt, weder ausdrücklich noch stillschweigend, einschließlich, aber nicht beschränkt auf stillschweigende Gewährleistungen der Marktgängigkeit, Eignung für einen bestimmten Zweck, Nichtverletzung von Rechten Dritter oder Leistungsfähigkeit."
          },
          "governingLaw": {
            "title": "11. Anwendbares Recht",
            "description": "Diese AGB unterliegen den Gesetzen der Niederlande und werden in Übereinstimmung mit diesen ausgelegt, ohne Berücksichtigung von Kollisionsnormen."
          },
          "changes": {
            "title": "12. Änderungen",
            "description": "Wir behalten uns das Recht vor, diese AGB nach eigenem Ermessen jederzeit zu ändern oder zu ersetzen. Wenn eine Überarbeitung wesentlich ist, werden wir versuchen, mindestens 30 Tage vor Inkrafttreten neuer Bedingungen eine Mitteilung zu machen. Was eine wesentliche Änderung darstellt, wird nach unserem alleinigen Ermessen bestimmt."
          },
          "detailedDisclaimer": {
            "title": "13. Haftungsausschluss",
            "list": [
              "<b>Informationsgenauigkeit:</b> Während wir uns bemühen, genaue und aktuelle Informationen auf Vitadote.com bereitzustellen, machen wir keine Zusicherungen oder Garantien jeglicher Art, weder ausdrücklich noch stillschweigend, über die Vollständigkeit, Genauigkeit, Zuverlässigkeit, Eignung oder Verfügbarkeit der Informationen, Produkte, Dienstleistungen oder zugehörigen Grafiken, die auf der Website für irgendeinen Zweck enthalten sind.",
              "<b>Nutzung auf eigenes Risiko:</b> Jegliches Vertrauen, das Sie in solche Informationen setzen, erfolgt daher streng auf eigenes Risiko. In keinem Fall haften wir für Verluste oder Schäden, einschließlich, ohne Einschränkung, indirekter oder Folgeschäden oder Verluste oder Schäden, die sich aus dem Verlust von Daten oder Gewinnen ergeben, die aus oder in Verbindung mit der Nutzung dieser Website entstehen.",
              "<b>Medizinischer Haftungsausschluss:</b> Die auf Vitadote.com bereitgestellten Informationen, einschließlich Details über Shilajit und seine potenziellen Vorteile, dienen nur zu Informationszwecken und sind nicht als Ersatz für den Rat Ihres Arztes oder anderer medizinischer Fachkräfte gedacht. Sie sollten die Informationen auf dieser Website nicht zur Diagnose oder Behandlung eines Gesundheitsproblems oder einer Krankheit verwenden oder um Medikamente oder andere Behandlungen zu verschreiben.",
              "<b>Produktverwendung:</b> Unsere Shilajit-Produkte sind Nahrungsergänzungsmittel und nicht zur Diagnose, Behandlung, Heilung oder Vorbeugung von Krankheiten bestimmt. Konsultieren Sie immer einen qualifizierten Gesundheitsexperten, bevor Sie mit einem Diät-, Bewegungs- oder Nahrungsergänzungsprogramm beginnen, bevor Sie Medikamente einnehmen oder wenn Sie ein Gesundheitsproblem haben oder vermuten.",
              "<b>Ergebnisse können variieren:</b> Die auf dieser Website beschriebenen Ergebnisse bezüglich der Verwendung von Shilajit sind individuelle Erfahrungen, die die realen Erfahrungen derjenigen widerspiegeln, die unsere Produkte verwendet haben. Individuelle Ergebnisse können jedoch variieren. Wir behaupten nicht, dass dies typische Ergebnisse sind, die Verbraucher im Allgemeinen erzielen werden.",
              "<b>Externe Links:</b> Über diese Website können Sie möglicherweise auf andere Websites zugreifen, die nicht unter der Kontrolle von Vitadote.com stehen. Wir haben keine Kontrolle über die Natur, den Inhalt und die Verfügbarkeit dieser Websites. Die Aufnahme von Links impliziert nicht notwendigerweise eine Empfehlung oder Befürwortung der darin geäußerten Ansichten.",
              "<b>Website-Verfügbarkeit:</b> Es werden alle Anstrengungen unternommen, um die Website reibungslos am Laufen zu halten. Vitadote.com übernimmt jedoch keine Verantwortung für und haftet nicht für die vorübergehende Nichtverfügbarkeit der Website aufgrund technischer Probleme, die außerhalb unserer Kontrolle liegen.",
              "<b>Änderungen des Haftungsausschlusses:</b> Wir behalten uns das Recht vor, diesen Haftungsausschluss jederzeit zu ändern. Alle Änderungen werden auf dieser Seite mit einem aktualisierten Revisionsdatum veröffentlicht."
            ],
            "note": "Durch die Nutzung von Vitadote.com erklären Sie sich mit diesem Haftungsausschluss einverstanden. Wenn Sie mit diesem Haftungsausschluss nicht einverstanden sind, verwenden Sie bitte unsere Website nicht."
          },
          "copyrightNotice": {
            "title": "14. Urheberrechtsvermerk",
            "description1": "© 2024 Vitadote® / eCom IT BV. Alle Rechte vorbehalten.",
            "description2": "Der Inhalt, das Layout, das Design, die Daten, Datenbanken und Grafiken auf dieser Website sind durch niederländische und andere internationale Gesetze zum Schutz des geistigen Eigentums geschützt und sind Eigentum von eCom IT BV oder deren Lizenzgebern. Kein Teil dieser Website darf ohne vorherige schriftliche Genehmigung von eCom IT BV in irgendeiner Form oder mit irgendwelchen Mitteln reproduziert, verteilt oder übertragen werden, einschließlich Fotokopieren, Aufzeichnen oder anderen elektronischen oder mechanischen Methoden, mit Ausnahme kurzer Zitate in kritischen Rezensionen und bestimmten anderen nicht-kommerziellen Verwendungen, die durch das Urheberrecht erlaubt sind."
          },
          "contactUs": {
            "title": "15. Kontakt",
            "description": "Wenn Sie Fragen zu diesen AGB haben, kontaktieren Sie uns bitte unter:",
            "email": "info@vitadote.com",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Niederlande",
            "lastUpdated": "Zuletzt aktualisiert: 19. August 2024"
          }
        }
      },

      "paymentPolicy": {
        "heading": "Zahlungsrichtlinie",
        "seo": {
          "metaDescription": "Vitadote® Zahlungsrichtlinie: Sichere Methoden, Währungsinfo, Rückerstattungen und wiederkehrende Zahlungen. Aktualisiert am 19.08.2024."
        },
        "policyContent": {
          "paymentMethods": {
            "title": "Zahlungsmethoden",
            "description": "Sie können Ihre Bestellung mit den folgenden Zahlungsmethoden bezahlen:",
            "list": [
              "American Express",
              "Apple Pay",
              "Google Pay",
              "iDEAL / Sofort",
              "Mastercard / Visa",
              "PayPal",
              "Shop Pay",
              "Union Pay"
            ],
            "description2": "Obwohl wir andere Kreditkarten nicht direkt akzeptieren, bietet PayPal die Möglichkeit, die gängigsten Kreditkarten zu verknüpfen, sodass Sie mit diesen Karten über PayPal auf unserer Website bezahlen können."
          },
          "paymentSecurity": {
            "title": "Zahlungssicherheit",
            "description": "Alle Transaktionen auf unserer Website werden sicher über unseren Zahlungsdienstleister Mollie abgewickelt. Alle Prozesse über Mollie entsprechen dem ISAE 3402-Prinzip und -Kriterien und sind nach dem PCI DSS 3.2 Level 1 Service Provider-Standard zertifiziert. Diese Zertifizierung ist ein international anerkannter Sicherheitsstandard."
          },
          "orderVerification": {
            "title": "Bestellüberprüfung",
            "description": "Alle Zahlungsmethoden sind gesichert und validiert. Zum Schutz unserer Kunden können einige Bestellungen einem Zahlungsüberprüfungsprozess unterzogen werden. Wenn Ihre Bestellung auf Eis gelegt wird, werden wir Sie umgehend kontaktieren, um mögliche Verzögerungen Ihrer Bestellung zu minimieren."
          },
          "currency": {
            "title": "Währung",
            "description": "Beim Einkauf auf unserer Website Vitadote.com werden alle Zahlungen in Euro (EUR) abgewickelt."
          },
          "refundsAndCancellations": {
            "title": "Rückerstattungen und Stornierungen",
            "description": "Informationen zu unseren Rückerstattungs- und Stornierungsrichtlinien finden Sie auf unserer Seite "
          },
          "recurringPayments": {
            "title": "Wiederkehrende Zahlungen",
            "description": "Wenn Sie einen unserer Dienste mit wiederkehrenden Zahlungen abonniert haben, werden Sie automatisch gemäß der von Ihnen gewählten Häufigkeit (z.B. monatlich, jährlich) belastet. Sie können Ihr Abonnement jederzeit kündigen, indem Sie sich in Ihren Account einloggen oder unser Kundendienstteam kontaktieren."
          },
          "pricingAndTax": {
            "title": "Preise und Steuern",
            "description": "Alle auf unserer Website angezeigten Preise sind in Euro und beinhalten die geltenden Steuern. Etwaige zusätzliche Zoll- oder Einfuhrgebühren für internationale Bestellungen liegen in der Verantwortung des Kunden."
          },
          "paymentIssues": {
            "title": "Zahlungsprobleme",
            "description": "Wenn Sie Probleme mit Ihrer Zahlung haben, überprüfen Sie bitte Folgendes:",
            "list": [
              "Stellen Sie sicher, dass Ihre Zahlungsdaten korrekt eingegeben wurden.",
              "Überprüfen Sie, ob Ihre Zahlungsmethode über ausreichende Mittel verfügt.",
              "Prüfen Sie, ob Ihre Bank die Transaktion aus Sicherheitsgründen blockiert hat."
            ],
            "followUp": "Wenn Sie Probleme weiterhin haben, kontaktieren Sie bitte unser Kundendienstteam."
          },
          "dataProtection": {
            "title": "Datenschutz",
            "description": "Wir nehmen die Sicherheit Ihrer Finanzdaten sehr ernst. Alle Zahlungsinformationen werden verschlüsselt und sicher verarbeitet. Wir speichern Ihre vollständigen Kreditkartendaten nicht auf unseren Servern. Weitere Informationen finden Sie in unserer "
          },
          "changesToThisPolicy": {
            "title": "Änderungen dieser Richtlinie",
            "description": "Wir können dieses Zahlungsrichtlinie von Zeit zu Zeit aktualisieren. Wir werden Sie über etwaige Änderungen informieren, indem wir die neue Zahlungsrichtlinie auf dieser Seite veröffentlichen und die Datum von \"Letzten Aktualisierung\" unten aktualisieren."
          },
          "contactUs": {
            "title": "Kontakt",
            "description": "Bei Fragen zu Zahlungen kontaktieren Sie uns bitte unter:",
            "email": "info@vitadote.com",
            "phone": "+31 20 244 3918",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Niederlande",
            "lastUpdated": "Zuletzt aktualisiert: 19. August 2024"
          }
        }
      },
      "refundPolicy": {
        "heading": "Rückerstattungsrichtlinie",
        "seo": {
          "metaDescription": "Entdecken Sie Vitadote®s einzigartige \"Behalte-Es\" Rückerstattungsgarantie. 30 Tage Zufriedenheitsgarantie auf alle Käufe. Lesen Sie unsere Richtlinie."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Einleitung",
            "description": "Bei Vitadote® hat Ihre Zufriedenheit oberste Priorität. Wir sind so überzeugt von der Qualität unserer Produkte, dass wir eine einzigartige und kundenfreundliche Rückerstattungsrichtlinie anbieten. Diese Rückerstattungsrichtlinie beschreibt die Bedingungen für Rückerstattungen von Produkten, die über unsere Website vitadote.com gekauft wurden."
          },
          "keepItRefundGuarantee": {
            "title": "2. Unsere \"Behalten Sie es\"-Rückerstattungsgarantie",
            "description": "Wenn Sie mit Ihrem Kauf nicht vollständig zufrieden sind, können Sie innerhalb von 30 Tagen nach Lieferung eine Rückerstattung beantragen - und Sie dürfen das Produkt behalten. Wir vertrauen unseren Kunden und schätzen Ihr Feedback, das uns hilft, unsere Produkte zu verbessern."
          },
          "refundPeriod": {
            "title": "3. Rückerstattungszeitraum",
            "description": "Sie haben 30 Tage ab dem Lieferdatum Zeit, eine Rückerstattung zu beantragen, wenn Sie mit Ihrem Kauf nicht zufrieden sind."
          },
          "refundEligibility": {
            "title": "4. Voraussetzungen für eine Rückerstattung",
            "description": "Um in Anspruch zu kommen für unsere \"Behalten Sie es\"-Rückerstattung:",
            "list": [
              "Müssen Sie die Rückerstattung innerhalb von 30 Tagen nach Lieferung beantragen",
              "Müssen Sie eine kurze Erklärung geben, warum Sie mit dem Produkt nicht zufrieden sind"
            ]
          },
          "refundProcess": {
            "title": "5. Rückerstattungsprozess",
            "description": "Um eine Rückerstattung zu initiieren, folgen Sie bitte diesen Schritten:",
            "list": [
              "Kontaktieren Sie unser Kundenservice-Team innerhalb von 30 Tagen nach Lieferung unter <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a>.",
              "Geben Sie Ihre Bestellnummer und eine kurze Erklärung an, warum Sie mit dem Produkt nicht zufrieden sind.",
              "Unser Team wird Ihre Anfrage prüfen und Ihre Rückerstattung bearbeiten."
            ]
          },
          "refundMethod": {
            "title": "6. Rückerstattungsmethode",
            "description": "Sobald Ihre Rückerstattung genehmigt wurde, wird innerhalb von 10 Werktagen automatisch eine Gutschrift auf Ihre ursprüngliche Zahlungsmethode angewendet."
          },
          "excessiveClaims": {
            "title": "7. Übermäßige Ansprüche",
            "description": "Obwohl wir unseren Kunden vertrauen, behalten wir uns das Recht vor, Rückerstattungen in Fällen von übermäßigen oder missbräuchlichen Ansprüchen zu verweigern."
          },
          "damagedOrDefectiveItems": {
            "title": "8. Beschädigte oder defekte Artikel",
            "description": "Wenn Sie einen beschädigten oder defekten Artikel erhalten, kontaktieren Sie uns bitte sofort unter <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a>. Wir senden Ihnen kostenlos einen Ersatz zu."
          },
          "lateOrMissingRefunds": {
            "title": "9. Verspätete oder fehlende Rückerstattungen",
            "description": "Wenn Sie noch keine Rückerstattung erhalten haben, überprüfen Sie bitte zunächst erneut Ihr Bankkonto. Kontaktieren Sie dann Ihr Kreditkartenunternehmen, es kann einige Zeit dauern, bis Ihre Rückerstattung offiziell verbucht wird. Wenn Sie dies alles getan haben und Ihre Rückerstattung immer noch nicht erhalten haben, kontaktieren Sie uns bitte unter <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a>."
          },
          "changesToThisPolicy": {
            "title": "10. Änderungen dieser Richtlinie",
            "description": "Wir behalten uns das Recht vor, diese Rückerstattungsrichtlinie jederzeit zu ändern. Änderungen und Klarstellungen werden sofort nach ihrer Veröffentlichung auf der Website wirksam."
          },
          "contactUs": {
            "title": "11. Kontaktieren Sie uns",
            "description": "Wenn Sie Fragen zu unserer Rückerstattungsrichtlinie haben, kontaktieren Sie uns bitte:",
            "email": "info@vitadote.com",
            "phone": "+31 20 244 3918",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Niederlande",
            "lastUpdated": "Zuletzt aktualisiert: 19. August 2024"
          }
        }
      },
      "shippingPolicy": {
        "heading": "Versandrichtlinie",
        "seo": {
          "metaDescription": "Entdecken Sie Vitadote®s Versandrichtlinie. Kostenloser weltweiter Versand, schnelle Lieferung und klare Lieferzeiten. Erfahren Sie mehr über unsere Versandpraktiken."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Einleitung",
            "description": "Bei Vitadote.com versenden wir unsere hochwertigen Produkte aus den Niederlanden mit sicheren Versandmethoden und Sendungsverfolgung. Diese Richtlinie beschreibt unsere Versandpraktiken, Lieferzeiten und damit verbundene Informationen."
          },
          "freeShipping": {
            "title": "2. Kostenloser Versand",
            "description": "Wir bieten kostenlosen Versand für alle Bestellungen! Bitte beachten Sie, dass der kostenlose Versand keine eventuellen Zollgebühren beinhaltet."
          },
          "shippingAddress": {
            "title": "3. Lieferadresse",
            "description": "<strong>Wichtig:</strong> Wir liefern nicht an Postfächer. Bitte geben Sie eine physische Lieferadresse an."
          },
          "orderProcessing": {
            "title": "4. Auftragsbearbeitung",
            "description": "Bestellungen werden innerhalb von 1-2 Werktagen bearbeitet, bevor sie versandt werden. Die unten angegebenen Lieferzeiten sind Schätzungen ab dem Versanddatum."
          },
          "deliveryTimes": {
            "title": "5. Lieferzeiten",
            "description": "",
            "list": [
              "<strong>Niederlande und Belgien:</strong> Bei Bestellungen an Werktagen vor 16:00 Uhr wird Ihr Vitadote-Produkt am nächsten Werktag geliefert.",
              "<strong>Deutschland:</strong> Bei Bestellungen an Werktagen vor 16:00 Uhr erfolgt die Lieferung in der Regel am nächsten Werktag. In einigen Regionen kann es aufgrund logistischer Gegebenheiten 1-2 Tage länger dauern.",
              "<strong>Andere EU-Länder:</strong> Bei Bestellungen an Werktagen vor 16:00 Uhr erfolgt die Lieferung innerhalb von 2-4 Werktagen.",
              "<strong>Außerhalb der EU:</strong> Bei Bestellungen an Werktagen vor 16:00 Uhr erfolgt die Lieferung je nach Flugverbindung innerhalb von 5-14 Tagen.",
              "<strong>Asien:</strong> Lieferung in Kürze verfügbar."
            ],
            "note": "<em>Bitte beachten Sie:</em> Wir versenden nur an Werktagen (Montag bis Freitag, ausgenommen Feiertage). Bestellungen, die am Wochenende eingehen, werden am darauffolgenden Werktag bearbeitet."
          },
          "damagedDeliveries": {
            "title": "6. Beschädigte Lieferungen",
            "description": "Falls Ihr Vitadote-Paket beschädigt ankommt, informieren Sie uns bitte umgehend per E-Mail an <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a>."
          },
          "shippingCostsAndDeliveryTimes": {
            "title": "7. Versandkosten und Lieferzeiten nach Land",
            "description": "",
            "table": {
              "headers": ["Land/Region", "Versandkosten", "Lieferzeit"],
              "rows": [
                ["Niederlande", "KOSTENLOS", "2 Tage"],
                ["Belgien", "KOSTENLOS", "2-5 Tage"],
                ["Deutschland", "KOSTENLOS", "2-5 Tage"],
                ["Vereinigtes Königreich", "KOSTENLOS", "2-5 Tage"],
                ["Frankreich", "KOSTENLOS", "2-5 Tage"],
                ["Spanien", "KOSTENLOS", "7-10 Tage"],
                ["Italien", "KOSTENLOS", "7-10 Tage"],
                ["Österreich", "KOSTENLOS", "2-5 Tage"],
                ["Polen", "KOSTENLOS", "2-5 Tage"],
                ["Dänemark", "KOSTENLOS", "2-5 Tage"],
                ["Schweden", "KOSTENLOS", "2-5 Tage"],
                ["Finnland", "KOSTENLOS", "2-5 Tage"],
                ["Irland", "KOSTENLOS", "2-5 Tage"],
                ["Portugal", "KOSTENLOS", "5-7 Tage"],
                ["Griechenland", "KOSTENLOS", "7-10 Tage"],
                ["Tschechische Republik", "KOSTENLOS", "2-5 Tage"],
                ["Ungarn", "KOSTENLOS", "2-5 Tage"],
                ["Rumänien", "KOSTENLOS", "4-7 Tage"],
                ["Bulgarien", "KOSTENLOS", "8-10 Tage"],
                ["Schweiz", "KOSTENLOS", "2-5 Tage"],
                ["Norwegen", "KOSTENLOS", "2-5 Tage"]
              ]
            },
            "additionalInfo": "Die Lieferzeiten in andere Länder als die oben genannten betragen in der Regel 20-30 Tage. Alle Sendungen sind kostenfrei."
          },
          "customs": {
            "title": "8. Zoll",
            "description": "Zollgebühren, Steuern oder sonstige Abgaben sind nicht im Preis enthalten.\n\nBei Bestellungen in Nicht-EU-Länder können bei der Ankunft zusätzliche Gebühren anfallen. Leider können wir die Höhe dieser Kosten nicht vorhersagen. Bitte kontaktieren Sie Ihr örtliches Zollamt für genauere Informationen."
          },
          "brexitNotice": {
            "title": "9. Brexit-Hinweis für UK-Bestellungen",
            "description": "Aufgrund des Brexits werden für alle UK-Bestellungen ab einem Gesamtwert von 150 € (abhängig vom aktuellen Wechselkurs) zusätzliche Zollgebühren und Abfertigungskosten berechnet. Bitte beachten Sie, dass der vom britischen Zoll bewertete Warenwert höher sein kann als unser Verkaufspreis, da der Originalwert jedes Produkts zugrunde gelegt wird."
          },
          "contactUs": {
            "title": "10. Kontaktieren Sie uns",
            "description": "Falls Sie Fragen zu unserer Versandrichtlinie haben, kontaktieren Sie uns bitte unter:",
            "email": "info@vitadote.com",
            "phone": "+31 20 244 3918",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Niederlande",
            "lastUpdated": "Zuletzt aktualisiert: 19. August 2024"
          }
        },
      },
    },
  },
  fr: {
    translation: {
      header: {
        home: "Accueil",
        about: "À propos de nous",
        contact: "Contact",
        reseller: "Devenez revendeur",
      },
      common: {
        buyNow: "Acheter maintenant",
        contactUs: "Contactez-nous",
        products: "produits"
      },
      homePage: {
        seo: {
          pageTitle: "Bienvenue chez Vitadote®",
          metaTitle: "Découvrez la résine Shilajit Vitadote® – vitadote",
          metaDescription:
            "Découvrez la résine Shilajit Vitadote® : puissance pure de l'Altaï et de l'Himalaya. Riche en minéraux, un complément naturel pour votre mode de vie sain.",
        },
        hero: {
          accentHeading:
            "Découvrez la Pureté des Montagnes avec la Résine Shilajit Vitadote<sup>®</sup>",
          mainHeading:
            "Découvrez la Puissance de la Nature avec la Résine Shilajit Vitadote<sup>®</sup>.",
          details:
            "Explorez les Éléments Primordiaux de la Nature avec la Résine Shilajit Vitadote<sup>®</sup>. Votre lien avec le Shilajit authentique, soigneusement extrait des imposantes formations rocheuses des montagnes de l'Altaï et de l'Himalaya. Notre Shilajit contient naturellement des minéraux tels que le fer, le calcium, le magnésium, le sélénium et le manganèse, ainsi que de l'acide fulvique, et est traité dans le respect de la nature et des traditions séculaires.",
        },
        nutrition: {
          heading: "La Valeur Nutritionnelle du Shilajit",
          accentHeading:
            "Contribution à l'AJR à partir de 3 portions de 0,3 gramme chacune",
          details:
            "La Résine Shilajit Vitadote® contient également une composition naturelle de minéraux. Grâce à une sélection minutieuse de notre Shilajit, nous vous assurons de bénéficier, entre autres de:",
          cards: {
            iron: {
              title: "Fer",
              description:
                "Contribue à la formation normale des globules rouges et de l'hémoglobine. Notre Shilajit fournit 30,87% de l'Apport Journalier Recommandé (AJR) en fer par dose quotidienne de 0,9 gramme",
            },
            calcium: {
              title: "Calcium",
              description:
                "Important pour la fonction musculaire normale et joue un rôle dans le processus de division et de spécialisation cellulaire. Notre Résine Shilajit contient 72,06% de l'AJR en calcium par dose quotidienne de 0,9 gramme",
            },
            magnesium: {
              title: "Magnésium",
              description:
                "Important pour le maintien d'os et de dents normaux, et contribue à une synthèse protéique normale. Notre produit fournit 108,24% de l'AJR en magnésium par dose quotidienne de 0,9 gramme",
            },
            selenium: {
              title: "Sélénium",
              description:
                "Contribue au fonctionnement normal du système immunitaire et à la protection des cellules contre le stress oxydatif. Avec notre Résine Shilajit, vous obtenez 22,92% de l'AJR en sélénium par dose quotidienne de 0,9 gramme",
            },
            manganese: {
              title: "Manganèse",
              description:
                "Contribue à la formation normale du tissu conjonctif et à la protection des cellules contre le stress oxydatif. Notre Résine Shilajit contient 33,75% de l'AJR en manganèse par dose quotidienne de 0,9 gramme",
            },
          },
        },
        whatisShilajit: {
          heading: "DÉCOUVREZ L'ORIGINE",
          paragraph1:
            "Laissez la Résine Shilajit Vitadote<sup>®</sup> être un complément à votre bonne routine. Une bonne routine consiste en un régime alimentaire sain et un modèle d'exercice. Vitadote<sup>®</sup> Shilajit peut compléter cela, directement de la nature vierge, vous reliant à une tradition qui a résisté à l'épreuve du temps.",
          paragraph2: {
            bold: "Le Shilajit comme Complément à Votre Régime Alimentaire",
            text: "Vitadote<sup>®</sup> vise à compléter vos choix alimentaires avec le Shilajit, un produit naturel traditionnellement apprécié. En cas de doute, consultez un médecin et suivez toujours les conseils sur l'emballage.",
          },
          paragraph3: {
            bold: "Acide fulvique dans la Résine Shilajit Vitadote<sup>®</sup>",
            text1:
              "La Résine Shilajit Vitadote<sup>®</sup> contient principalement de l'acide fulvique, en plus de l'acide humique et des minéraux.",
            text2:
              "pour des informations spécifiques sur la quantité d'acide fulvique, d'acide humique et de minéraux dans notre produit ou consultez notre ",
            productPage: "page produit",
          },
        },
        instructions: {
          heading: "Mode d'emploi",
          details:
            "Nous conseillons de prendre trois fois par jour une petite quantité de 0,3 gramme de Résine Shilajit Vitadote<sup>®</sup>. Dissolvez-la directement sous la langue ou dans un verre d'eau non chlorée pour une absorption optimale.",
          step1:
            "Utilisez la spatule pour prélever la dose recommandée du pot.",
          step2:
            "Dissolvez dans de l’eau non chlorée, une tisane ou du lait tiède (ne dépassant pas 39°C), et buvez. Il peut également être dissous sous la langue.",
          step3:
            "Pour des résultats optimaux, nous recommandons d’utiliser régulièrement la résine de Shilajit.",
        },
        FAQS: {
          heading: "Questions Fréquemment Posées",
          heading1: "Responsable et Axé sur la Qualité",
          paragraph1:
            "Chez Vitadote<sup>®</sup>, nous visons les normes de qualité les plus élevées pour notre Shilajit. Nous suivons méticuleusement la réglementation applicable aux compléments alimentaires aux Pays-Bas. Nos produits sont soumis à des contrôles de qualité approfondis pour répondre aux normes de sécurité alimentaire requises. Nous sommes fiers de pouvoir étayer notre approche soignée avec une documentation pertinente. Nous nous efforçons d'assurer la pureté et la qualité de chaque lot de notre produit. Des informations détaillées sur la composition et les caractéristiques de qualité de notre produit sont disponibles sur notre site web dans les spécifications du produit.",
          heading3:
            "Pour plus d'informations et la liste complète des questions fréquemment posées, visitez notre ",
          faqsPage: "Page FAQ",
          faqs: {
            question1:
              "Qu'est-ce que la Résine Shilajit Vitadote<sup>®</sup> ?",
            answer1:
              "La Résine Shilajit Vitadote<sup>®</sup> est une substance résineuse naturelle récoltée dans les formations rocheuses des montagnes de l'Altaï et de l'Himalaya. Elle contient une composition unique de minéraux naturellement présents : fer, calcium, magnésium, sélénium et manganèse, ainsi que des composés organiques comme l'acide fulvique et l'acide humique. Le Shilajit est utilisé depuis des siècles dans les pratiques traditionnelles de diverses cultures.",
            question2:
              "Comment intégrer et utiliser la Résine Shilajit Vitadote<sup>®</sup> en complément de ma routine quotidienne ?",
            answer2:
              "Une routine quotidienne saine consiste en une alimentation équilibrée et un exercice régulier. La Résine Shilajit Vitadote<sup>®</sup> peut compléter cela. Si vous choisissez d'utiliser le Shilajit, nous recommandons de prendre une portion de 0,3 gramme (environ la taille d'un grain de riz à un petit pois) trois fois par jour. Vous pouvez dissoudre la Résine Shilajit Vitadote<sup>®</sup> sous votre langue ou la mélanger dans un verre d'eau non chlorée ou une autre boisson de votre choix (jusqu'à 39°C).",
            question3:
              "Comment conserver la Résine Shilajit Vitadote<sup>®</sup> ?",
            answer3:
              "Conservez-la dans un endroit frais et sec, à l'abri de la lumière directe du soleil.",
            question4:
              "D'où provient la Résine Shilajit Vitadote<sup>®</sup> ?",
            answer4:
              "Notre Shilajit est récolté dans les formations rocheuses des montagnes de l'Altaï et de l'Himalaya.",
            question5:
              "Peut-on combiner la Résine Shilajit Vitadote<sup>®</sup> avec d'autres suppléments ?",
            answer5:
              "En cas de doute, consultez un médecin et suivez toujours les conseils sur l'emballage.",
          },
          readMore: "LIRE PLUS FAQ",
        },
        subscription: {
          heading: "Rejoignez le Mouvement",
          details:
            "Découvrez par vous-même les propriétés distinctives de la Résine Shilajit Vitadote<sup>®</sup> et faites partie de notre communauté qui reconnaît la valeur des ingrédients naturels.",
          joinNow: "Inscrivez-vous maintenant",
          placeholder: "Entrez votre email",
        },
      },
      aboutPage: {
        seo: {
          metaDescription:
            "Découvrez Vitadote® : dédiée au Shilajit pur de l'Altaï et de l'Himalaya. Notre mission et nos pratiques de récolte durables.",
        },
        banner: {
          mainHeading:
            "Vitadote<sup>®</sup>: Pionniers de la Résine Shilajit Pure",
          subHeading: "Notre Mission: La Pureté des Montagnes jusqu'à Vous",
          details:
            "Chez Vitadote<sup>®</sup>, nous nous consacrons à fournir la Résine Shilajit sous sa forme la plus authentique. Inspiré par des traditions séculaires, chaque pot de Résine Shilajit Vitadote<sup>®</sup> offre un lien avec l'origine naturelle de cette substance unique.",
        },
        infoSection: {
          heading: "De la Montagne à Votre Routine Quotidienne",
          details:
            "Une bonne routine consiste en un régime alimentaire sain et un modèle d'exercice. Vitadote<sup>®</sup> peut compléter cela. Notre Shilajit provient des régions de haute altitude des montagnes de l'Altaï et de l'Himalaya, des zones connues pour leur nature vierge. Nous apportons soigneusement cette substance naturelle, avec sa composition unique de minéraux et de composés organiques comme l'acide fulvique, chez vous.",
          commitmentHeading: "Engagement envers la Qualité et le Soin",
          commitments1: "Chez Vitadote<sup>®</sup>, nous accordons une grande importance à la qualité et à la pureté de notre Shilajit. Nos produits sont soigneusement contrôlés pour répondre aux normes de sécurité alimentaire en vigueur. Nous nous efforçons d'être transparents sur nos processus et nos contrôles de qualité.",
          commitments2: "Bien que tous les compléments alimentaires doivent se conformer aux normes légales, nous nous engageons à démontrer notre approche soignée. Cela reflète notre dévouement à l'authenticité et à la transparence.",
          commitments3: "Pour des informations détaillées sur les propriétés spécifiques de notre produit, nous vous invitons à consulter les spécifications du produit sur notre site web.",
        },
        featuresSection: {
          feature1: {
            icon: "GppGoodIcon",
            heading: "Pratiques Durables, Récolte Responsable",
            details:
              "La durabilité et le respect de l'environnement sont au cœur de nos méthodes de récolte. C'est notre engagement, non seulement envers le produit, mais aussi envers la terre et ses habitants.",
          },
          feature2: {
            icon: "fa-handshake",
            heading: "Notre Équipe",
            details:
              "Nous sommes heureux de partager avec vous nos connaissances sur les caractéristiques naturelles du Shilajit et d'offrir des conseils pour son intégration dans un régime quotidien équilibré. Un bon régime consiste en un modèle alimentaire et d'exercice sain. L'utilisation de la Résine Shilajit Vitadote<sup>®</sup> peut le compléter.",
          },
          feature3: {
            icon: "GroupsIcon",
            heading: "La Promesse Vitadote<sup>®</sup>",
            details:
              "Chaque emballage de Résine Shilajit Vitadote<sup>®</sup> représente notre promesse de qualité et de clarté. Nous visons à vous rapprocher de l'origine naturelle de nos ingrédients soigneusement.",
          },
        },
      },

      faqsPage: {
        seo: {
          metaDescription:
            "Questions fréquentes sur la résine Shilajit Vitadote®. Informations sur l'utilisation, les avantages et la sécurité de notre complément naturel.",
        },
        banner: {
          heading:
            "Bienvenue sur la page FAQ de Vitadote<sup>®</sup> Shilajit Resin",
          description1:
            "Vous souhaitez en savoir plus sur Vitadote<sup>®</sup> Shilajit Resin et comment il peut compléter votre routine quotidienne ? Cette page est dédiée à répondre à toutes vos questions concernant cet exceptionnel complément naturel. De sa composition unique en minéraux et composés organiques aux conseils de stockage et à la compatibilité avec d'autres compléments, tout est abordé.",
          description2:
            "Découvrez comment Vitadote<sup>®</sup> Shilajit Resin est soigneusement récolté, testé en laboratoire et conçu pour répondre à vos besoins en matière de bien-être. Que vous recherchiez des instructions d'utilisation détaillées, des informations sur la sécurité ou des caractéristiques du produit, notre page FAQ vous apporte clarté et confiance.",
        },
        faqSection: {
          title:
            "Questions Fréquemment Posées sur la Résine Shilajit Vitadote®",
          faqs: [
            {
              question: "Qu'est-ce que le Shilajit ?",
              answer:
                "Le Shilajit est une substance résineuse naturelle connue pour sa teneur en Fer, Calcium, Magnésium, Sélénium, Manganèse, Acide Fulvique et Acide Humique. Il est apprécié depuis des siècles dans diverses cultures pour sa composition unique et provient des formations rocheuses des montagnes de l'Altaï et de l'Himalaya.",
            },
            {
              question: "Comment utiliser la Résine Shilajit Vitadote®?",
              answer:
                "Nous recommandons de prendre une quantité de 0,3 gramme (une portion de la taille d'un grain de riz à un petit pois) trois fois par jour. Vous pouvez dissoudre la Résine Shilajit Vitadote® sous la langue ou dans un verre d'eau non chlorée ou une autre boisson de votre choix (jusqu'à 39°C maximum).",
            },
            {
              question:
                "Puis-je combiner la Résine Shilajit Vitadote® avec d'autres suppléments?",
              answer:
                "Bien que le Shilajit soit souvent combiné avec d'autres suppléments, en cas de doute, consultez un médecin et suivez toujours les conseils sur l'emballage.",
            },
            {
              question:
                "Y a-t-il des effets secondaires connus de la Résine Shilajit Vitadote®?",
              answer:
                "La Résine Shilajit Vitadote® est généralement bien tolérée lorsqu'elle est utilisée selon la posologie recommandée. La sensibilité individuelle peut varier. En cas de doute, consultez un médecin et suivez toujours les conseils sur l'emballage.",
            },
            {
              question: "Comment conserver la Résine Shilajit Vitadote®?",
              answer:
                "Elle doit être conservée dans un endroit frais et sec, à l'abri de la lumière directe du soleil. Tenir hors de portée des enfants.",
            },
            {
              question:
                "Puis-je utiliser la Résine Shilajit Vitadote® pendant la grossesse ou l'allaitement?",
              answer:
                "Si vous êtes enceinte ou que vous allaitez, il est important d'être prudente avec les compléments alimentaires. Nous vous conseillons vivement de consulter un professionnel de santé avant de commencer à utiliser la Résine Shilajit Vitadote®.",
            },
            {
              question:
                "La Résine Shilajit Vitadote® convient-elle aux végétariens et aux végétaliens?",
              answer:
                "Oui, la Résine Shilajit Vitadote® convient aux végétariens et aux végétaliens.",
            },
            {
              question:
                "Combien de temps puis-je utiliser la Résine Shilajit Vitadote® après ouverture?",
              answer:
                "Tant qu'elle est correctement conservée, la Résine Shilajit Vitadote® peut être utilisée pendant une longue période après ouverture. Vérifiez toujours la date de péremption sur l'emballage.",
            },
            {
              question: "La Résine Shilajit Vitadote® est-elle Halal?",
              answer:
                "La Résine Shilajit Vitadote® est un produit végétal et ne contient pas de produits animaux, ce qui devrait la rendre appropriée pour les personnes suivant un régime halal.",
            },
            {
              question:
                "La Résine Shilajit Vitadote® contient-elle des allergènes ou du gluten?",
              answer:
                "La Résine Shilajit Vitadote® est exempte d'allergènes connus et de gluten. Le processus de production garantit que notre Shilajit est exempt de la plupart des allergènes courants, y compris les noix, le gluten, le lactose et le soja. Pour les informations les plus récentes sur les allergènes, nous recommandons de vérifier l'étiquette ou de nous contacter directement.",
            },
            {
              question:
                "La Résine Shilajit Vitadote® peut-elle provoquer une réaction allergique chez les personnes allergiques à la noix de coco?",
              answer:
                "Bien que la Résine Shilajit Vitadote® ne contienne pas d'ingrédients généralement reconnus comme allergènes, elle contient une petite quantité d'huile de coco, qui peut provoquer une réaction allergique chez les personnes ayant une allergie spécifique à la noix de coco. Si vous avez une allergie à la noix de coco, il est conseillé d'être prudent et de consulter votre professionnel de santé avant d'utiliser la Résine Shilajit Vitadote®.",
            },
            {
              question:
                "La Résine Shilajit Vitadote® peut-elle être utilisée en combinaison avec des médicaments?",
              answer:
                "Nous recommandons de consulter votre médecin ou pharmacien avant de combiner la Résine Shilajit Vitadote® avec des médicaments.",
            },
          ],
          contact: "Si vous avez d'autres questions,",
          contactLink: "n'hésitez pas à nous contacter.",
        },
      },
      contactPage: {
        seo: {
          metaDescription:
            "Contactez Vitadote® pour des questions ou un support concernant nos produits. Accédez-nous via formulaire, e-mail ou téléphone. Nous sommes là pour vous aider!",
        },
        banner: {
          heading: "CONTACTEZ-NOUS",
          subHeading: "Nous Sommes à Votre Écoute !",
          description:
            "Vous avez des questions, besoin d’aide ou souhaitez en savoir plus sur Vitadote® et nos produits ? Contactez-nous dès aujourd’hui ! Vous pouvez nous joindre par e-mail, téléphone ou en remplissant le formulaire de contact ci-dessous. Notre équipe est à votre disposition pour répondre à vos demandes.",
        },
        infoSection: {
          infoHeading: "INFORMATIONS",
          addressHeading: "Adresse",
          address: "Albert Huismanpad 166, 1502EK Zaandam, Pays-Bas",
          phoneHeading: "Téléphone",
          emailHeading: "E-mail",
          formFields: {
            nameLabel: "Nom",
            emailLabel: "E-mail",
            phoneLabel: "Téléphone",
            messageLabel: "Message",
            submitButton: "ENVOYER MAINTENANT",
          },
        },
      },
      "resellerPage": {
        "seo": {
          "metaDescription": "Devenez revendeur exclusif de Vitadote® Shilajit, complément naturel premium. Obtenez droits de vente, marges élevées et support marketing."
        },
        "banner": {
          "heading": "DEVENEZ REVENDEUR VITADOTE<sup>®</sup> SHILAJIT EN EUROPE",
          "subHeading": "Vous recherchez un produit unique et de haute qualité pour élargir votre gamme ?",
          "description": "Vitadote®, producteur leader et propriétaire de la marque de Shilajit premium, recherche des revendeurs et distributeurs de Shilajit enthousiastes dans toute l'Europe. Notre programme exclusif de revendeur offre des opportunités pour la vente en gros de suppléments naturels."
        },
        "whysellHeading": "POURQUOI VENDRE VITADOTE<sup>®</sup> SHILAJIT ?",
        "whySell": [
          {
            "icon": "/icons/authentication.svg",
            "feature": "Produit authentique",
            "description": "Récolté directement dans les montagnes de l'Altaï et de l'Himalaya"
          },
          {
            "icon": "/icons/high-quality.svg",
            "feature": "Haute qualité",
            "description": "Contrôles qualité stricts et conformité aux réglementations de l'UE"
          },
          {
            "icon": "/icons/growing-business.svg",
            "feature": "Marché en croissance",
            "description": "Demande croissante de compléments alimentaires naturels"
          },
          {
            "icon": "/icons/proposition.svg",
            "feature": "Offre distinctive",
            "description": "Composition unique de minéraux et d'acide fulvique"
          },
          {
            "icon": "/icons/brand-identity.svg",
            "feature": "Identité de marque forte",
            "description": "Image professionnelle et support marketing"
          }
        ],
        "benefitsHeading": "Avantages de devenir revendeur Vitadote<sup>®</sup>",
        "benefits": [
          {
            "feature": "Droits de vente exclusifs en tant que distributeur Shilajit dans votre région"
          },
          {
            "feature": "Marges bénéficiaires attractives pour les revendeurs et grossistes"
          },
          {
            "feature": "Accès à notre vaste programme de revendeur avec matériel marketing et formation sur les produits"
          },
          {
            "feature": "Soutien continu de notre équipe expérimentée"
          },
          {
            "feature": "Possibilité de capitaliser sur l'intérêt croissant pour les suppléments naturels"
          }
        ],
        "lookingForHeading": "Qui recherchons-nous?",
        "lookingFor": [
          {
            "title": "Détaillants établis et distributeurs de Shilajit dans le secteur de la santé"
          },
          {
            "title": "Vendeurs en ligne et grossistes spécialisés dans les compléments alimentaires naturels"
          },
          {
            "title": "Distributeurs de produits naturels"
          },
          {
            "title": "Centres de bien-être et cabinets de santé"
          }
        ],
        "footerHeading": "Intéressé par un partenariat de revente Vitadote<sup>®</sup>?",
        "footerDescription": "Contactez-nous dès aujourd'hui et découvrez comment devenir distributeur Shilajit ou revendeur exclusif grâce à notre programme unique de revendeur. Rejoignez le réseau Vitadote® et profitez de la demande croissante de Shilajit premium en Europe."
      },
      "products": {
        "badges": {
          "topSelling": "Meilleure vente",
          "save8": "Économisez €8",
          "save27": "Économisez €27"
        },
        "images": [
          "/images/products-fr/04. comparaison de la teneur en acide fulvique du shilajit.jpg",
          "/images/products-fr/05. shilajit pour l'anti-âge et la santé de la peau.jpg",
          "/images/products-fr/11. Ingrédients de la résine Vitadote Shilajit.jpg",
          "/images/products-fr/06. efficacité des capsules de shilajit bio vs résine.jpg",
          "/images/product-picture-3.jpg",
          "/images/products-fr/10. effets secondaires du shilajit et précautions de sécurité.jpg",
          "/images/products-fr/07. shilajit pour la stimulation de la testostérone et la croissance musculaire.jpg",
          "/images/products-fr/08. bienfaits du shilajit pour l'équilibre hormonal des femmes.jpg",
          "/images/products-fr/09. shilajit pour la fonction cognitive et la santé cérébrale.jpg",
        ],
        "ourProducts": "Nos produits",
        "productName1": "1x resine de shilajit pure",
        "productName2": "2x resine de shilajit pure",
        "productName3": "3x resine de shilajit pure",
        "relatedProducts": "Produits connexes",
        "seo": {
          "metaTitle": "Résine de Shilajit Pure | Source de l'Altai et de l'Himalaya | Acide Fulvique par Vitadote®",
          "metaDescription": "Découvrez la résine de Shilajit Vitadote® provenant des montagnes de l'Altai et de l'Himalaya. Testée en laboratoire, protégée par UV, riche en acide fulvique et en minéraux naturels.",

        },
        "banner": {
          "heading": "Résine de Shilajit Pure | Source de l'Altai et de l'Himalaya | Acide Fulvique par Vitadote®",
          "keyQuality": "Qualités Principales de la Résine de Shilajit Vitadote®",
          "benefits": [
            {
              "title": "Directement issue des Montagnes de l'Altai et de l'Himalaya",
              "description": "Récoltée dans des régions montagneuses vierges où le Shilajit accumule sa riche composition minérale."
            },
            {
              "title": "Emballée dans un Verre Protégé UV",
              "description": "Conditionnée dans un verre ultraviolet pour préserver la puissance du produit en bloquant les rayons lumineux nocifs."
            },
            {
              "title": "Qualité Testée en Laboratoire",
              "description": "Chaque lot est rigoureusement testé et certifié HACCP, garantissant un Shilajit de haute qualité et pure."
            },
            {
              "title": "Références KOAG/KAG et Certificat NVWA",
              "description": "Ces références confirment que notre résine de Shilajit respecte des normes strictes de sécurité et de qualité."
            }
          ]
        },
        "detailsTabs": {
          "description": {
            "title": "Description du produit",
              "desciption": "La <b>Résine de Shilajit Vitadote® </b> est un complément naturel haut de gamme, provenant des régions élevées des <b>montagnes de l'Altai et de l'Himalaya</b> Elle offre une forme pure et authentique de Shilajit, riche en minéraux essentiels tels que <b>le fer, le calcium, le magnésium, le sélénium, le manganèse, le zinc</b>, et <b>l’acide fulvique</b>, apportant la puissance de la nature dans votre routine de bien-être quotidienne.",
            "features": [
              {
                "title": "Mode d'Emploi",
                "description": "Pour des résultats optimaux, prenez <b>0,3 gramme</b> (environ de la taille d'un grain de riz à un pois) trois fois par jour. Dissolvez-la sous la langue ou mélangez-la dans de l'<b>eau non chlorée</b> ou une <b>boisson de votre choix (jusqu'à 39°C).</b>"
              },
              {
                "title": "Pur et Fiable",
                "description": "<b>Vitadote®</b> s’engage à offrir une résine de Shilajit authentique et issue d'une récolte responsable. Nous veillons à ce que chaque pot réponde à nos normes élevées de qualité et de pureté, afin que vous puissiez profiter de Shilajit dans sa forme la plus naturelle possible."
              },
              {
                "title": "Découvrez l’Essence du Shilajit avec Vitadote®",
                "description": "Choisissez <b>la Résine de Shilajit Vitadote®</b> pour enrichir votre parcours de bien-être avec un Shilajit pur et non transformé, directement issu <b>des montagnes de l'Altai et de l'Himalaya.</b>"
              }
            ]
          },
          "reviews": {
            "title": "REVIEWS",
            "customersReviews": "Commentaires des clients",
            "verifiedPurchases": "Tout à partir d'achats vérifiés",
            "allReviewsWithDetails": "Tous les avis avec détails",
            customerReviews: [
              {
                "clientName": "Hassan Iqbal Hashmi",
                "date": "2023-05-16",
                "profile_image": "https://lh3.googleusercontent.com/a-/ACB-R5Tsv5u4-Z7qiRmBTw5JSsm7JolJNfZNeVWlzZIefiY=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Finally find pure Shilajit Resins. It has done what was expected. Thank you."
              },
              {
                "clientName": "Rebecca Sperry",
                "date": "2023-05-03",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxZgq-bSMicabswNP3HG1r1k8p7M6ekBDc-DYVx_=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Shilajit is een super resin, ik neem elke ochtend op mij werk, en ja hoor het helpt met alles, mij houd en haar is veel beter al binnen 3 weken!"
              },
              {
                "clientName": "Doeke Leerink",
                "date": "2023-03-09",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxbqo1czKEDR9BiUlb0FgAUH2ekolUQruV6U-Kod=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Na veel zoeken hier het echte product gevonden. De website geeft uitgebreide informatie en er wordt snel geleverd. Sterk aan te raden."
              },
              {
                "clientName": "Alex Berkov",
                "date": "2023-03-07",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxYgIJuXgqYb5Hg505DuNAp9quCSFrs-AgkNJ34l=s120-c-c-rp-w64-h64-mo-ba3-br100",
                "stars": 5,
                "review": "Folosesc de aprox 1 luna shilajit de la Vitadote pot spune ca in prima faza am acoperit gustul shilajitului cu miere de albine 🙂 Efectele sale nu încetează sa tot apara, de la lipsa balonării pana la mai multa energie si focus/atentie. Mulțumesc."
              },
              {
                "clientName": "Maysoon Kelani",
                "date": "2023-02-21",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxaR9AJ46lfbFL_DYEXp8BiUns9E1HRaLVCpdJ6q=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Best product ever thanks alot!"
              },
              {
                "clientName": "Heba Khatib",
                "date": "2023-02-21",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxZksHg6l3jwZQAh6QZv4nkl7cZnzZqr3aRMka4y=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Super team and best product ever ❤️."
              },
              {
                "clientName": "Adrian Rosu Savinoiu",
                "date": "2023-02-21",
                "profile_image": "https://lh3.googleusercontent.com/a/AGNmyxbd7wCzON2KNosyp0gTka5yPGTdlh28C4hWJhmQ=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "L-am luat pentru sotia mea, prima imbunatatire pe care a simtit-o este ca nu mai are picioarele reci. E primul remediu incercat de ea care a reusit acest lucru. Gustul nu e atat de rau pe cat se spune mai ales daca e pus in ceai. Cutia si modul de prezentare i-au placut enorm. Produsul vine cu posta romana, deci nu ajunge chiar in 3 zile (nu din vina postei ci din cauza vamii). Vitadote a fost foarte profesionista din punctul de vedere al livrarii, link cu AWB atat posta din Olanda, am comunicat pe WhatsApp fara probleme."
              },
              {
                "clientName": "Daniela Răducănescu",
                "date": "2023-02-10",
                "profile_image": "https://lh3.googleusercontent.com/a-/ACB-R5RuEdC9WO1A6Y0OUlXUKO7s4z8jReTVqZekkR_2M1Q=s120-c-c-rp-w64-h64-mo-ba4-br100",
                "stars": 5,
                "review": "#shilajit @Vitadote Shilajitul este excelent! L-am și testat pentru autenticitate, iar la toate 3 testări rezultatele au arătat că este autentic. Am luat aseară un pahar de ceai cald in care am dizolvat shilajitul primit de la Vitadote. Nu am reușit să mai dorm până pe la ora 2 de noapte. Deci energizarea corpului este ca și cum aș fi băut o cană de ness. Comunicarea excelentă! Imediat ce au primit comanda, mi-au scris pe WhatsApp! Au grijă de client și s-au asigurat că am primit coletul. Minunat! Exact ce mi-am dorit! Mulțumesc, Vitadote!"
              },
              {
                "clientName": "dragos negrau",
                "date": "2023-02-08",
                "profile_image": "https://lh3.googleusercontent.com/a-/ACB-R5RKX3q3g1OFHa_YAA-D6Oa9M9cVSnW13vSDUABr=s120-c-c-rp-w64-h64-mo-br100",
                "stars": 5,
                "review": "Your product it’s life changer! Super team! Fast shipping..."
              }
            ]
          }
        }
      },
      "privacyPolicy": {
        "heading": "Politique de Confidentialité",
        "seo": {
          "metaDescription": "Politique de confidentialité Vitadote® : collecte, utilisation et protection de vos données. Vos droits et notre conformité au RGPD."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Introduction",
            "description1": "Chez Vitadote®, nous nous engageons à protéger votre vie privée et à sauvegarder vos données personnelles. Cette Politique de Confidentialité explique comment nous collectons, utilisons, divulguons et protégeons vos informations personnelles lorsque vous visitez notre site web vitadote.com ou utilisez nos services.",
            "description2": "eCom IT BV (\"eCom IT\", \"nous\", \"notre\" ou \"nos\"), opérant sous le nom commercial Vitadote®, est le responsable du traitement des données personnelles décrites dans cette Politique de Confidentialité."
          },
          "informationWeCollect": {
            "title": "2. Informations que nous collectons",
            "description": "Nous pouvons collecter les types d'informations personnelles suivants :",
            "list": [
              "Informations d'identité et de contact (par exemple, nom, adresse e-mail, numéro de téléphone, adresse postale)",
              "Informations de compte (par exemple, nom d'utilisateur, mot de passe)",
              "Données de commande et de transaction",
              "Informations de paiement",
              "Communications avec nous (par exemple, demandes de service client)",
              "Données d'utilisation (par exemple, comment vous utilisez notre site web)",
              "Données techniques (par exemple, adresse IP, type de navigateur)"
            ]
          },
          "howWeUse": {
            "title": "3. Comment nous utilisons vos informations",
            "description": "Nous utilisons vos informations personnelles aux fins suivantes :",
            "list": [
              "Pour traiter et livrer vos commandes",
              "Pour gérer votre compte chez nous",
              "Pour fournir un service client",
              "Pour vous informer des changements importants de nos services",
              "Pour améliorer notre site web et nos services",
              "Pour prévenir et détecter la fraude",
              "Pour nous conformer à nos obligations légales"
            ]
          },
          "legalBases": {
            "title": "4. Bases légales du traitement",
            "description": "Nous traitons vos données personnelles sur la base d'un ou plusieurs des fondements juridiques suivants :",
            "list": [
              "L'exécution d'un contrat avec vous",
              "Nos intérêts commerciaux légitimes",
              "Le respect d'une obligation légale",
              "Votre consentement (le cas échéant)"
            ],
            "extraInfo": "Vitadote® se conforme au Règlement Général sur la Protection des Données (RGPD) de l'Union européenne. Nous prenons votre vie privée au sérieux et veillons à ce que toutes nos activités de traitement des données soient conformes au RGPD. Cela inclut la collecte uniquement des données nécessaires, la mise en œuvre de mesures de sécurité appropriées, le respect de vos droits en tant que personne concernée et la transparence concernant nos pratiques de traitement des données."
          },
          "sharingInfo": {
            "title": "5. Partage de vos informations",
            "description": "Nous pouvons partager vos informations personnelles avec :",
            "list": [
              "Nos prestataires de services (par exemple, fournisseurs d'hébergement, processeurs de paiement)",
              "Services de livraison",
              "Les forces de l'ordre ou d'autres agences gouvernementales (si la loi l'exige)"
            ],
            "note": "Nous ne vendons pas vos informations personnelles à des tiers."
          },
          "dataRetention": {
            "title": "6. Conservation des données",
            "description": "Nous conservons vos informations personnelles aussi longtemps que nécessaire pour atteindre les objectifs pour lesquels elles ont été collectées, ou pour nous conformer aux exigences légales."
          },
          "dataSecurity": {
            "title": "7. Sécurité des données",
            "description": "Nous avons mis en place des mesures techniques et organisationnelles appropriées pour protéger vos informations personnelles contre l'accès non autorisé, la perte, l'utilisation abusive ou la destruction."
          },
          "yourRights": {
            "title": "8. Vos droits",
            "description": "En vertu du RGPD et d'autres lois applicables sur la protection des données, vous disposez de certains droits concernant vos données personnelles, notamment :",
            "list": [
              "Droit d'accès",
              "Droit de rectification",
              "Droit à l'effacement",
              "Droit à la limitation du traitement",
              "Droit à la portabilité des données",
              "Droit d'opposition au traitement"
            ],
            "extraInfo": "Pour exercer vos droits, veuillez nous contacter en utilisant les coordonnées fournies à la fin de cette politique."
          },
          "cookies": {
            "title": "9. Cookies et technologies similaires",
            "description": "Nous utilisons des cookies et des technologies similaires pour améliorer votre expérience sur notre site web et pour comprendre comment notre site web est utilisé. Pour plus d'informations sur notre utilisation des cookies, veuillez consulter notre Politique relative aux "
          },
          "internationalTransfers": {
            "title": "10. Transferts internationaux de données",
            "description": "Nous pouvons transférer et traiter vos informations personnelles dans des pays situés en dehors de l'Espace économique européen (EEE)."
          },
          "policyChanges": {
            "title": "11. Modifications de cette Politique de Confidentialité",
            "description": "Nous pouvons mettre à jour cette Politique de Confidentialité de temps à autre. La version la plus récente sera toujours disponible sur notre site web."
          },
          "contactUs": {
            "title": "12. Nous contacter",
            "text1": "Si vous avez des questions, des commentaires ou des demandes concernant cette Politique de Confidentialité ou nos pratiques en matière de confidentialité, veuillez nous contacter à :",
            "text2": "Email : ",
            "text3": "Adresse postale : Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Pays-Bas",
            "text4": "Vous avez également le droit de déposer une plainte auprès de l'Autorité néerlandaise de protection des données.",
            "lastUpdated": "Dernière mise à jour : 19 août 2024"
          }
        }
      },
      "cookiePolicy": {
        "heading": "Politique des Cookies",
        "seo": {
          "metaDescription": "Politique de cookies Vitadote® : utilisation, finalité et gestion de vos préférences. Mise à jour le 19 août 2024."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Introduction",
            "description": "Cette Politique des Cookies explique comment Vitadote® (\"nous\", \"notre\" ou \"nos\"), exploité par eCom IT BV, utilise les cookies et les technologies similaires sur notre site web vitadote.com. Cette politique vous fournit des informations claires et complètes sur les cookies que nous utilisons et les finalités pour lesquelles nous les utilisons."
          },
          "whatAreCookies": {
            "title": "2. Qu'est-ce que les cookies ?",
            "description": "Les cookies sont de petits fichiers texte placés sur votre appareil lorsque vous visitez un site web. Ils sont largement utilisés pour faire fonctionner les sites web de manière plus efficace, ainsi que pour fournir des informations aux propriétaires du site. Les cookies peuvent être \"persistants\" ou \"de session\"."
          },
          "howWeUseCookies": {
            "title": "3. Comment nous utilisons les cookies",
            "description": "Nous utilisons les cookies aux fins suivantes :",
            "list": [
              {
                "name": "Cookies essentiels",
                "purpose": "Il s'agit de cookies nécessaires au fonctionnement de notre site web. Ils comprennent, par exemple, les cookies qui vous permettent de vous connecter à des zones sécurisées de notre site web ou d'utiliser un panier d'achat."
              },
              {
                "name": "Cookies analytiques/de performance",
                "purpose": "Ils nous permettent de reconnaître et de compter le nombre de visiteurs et de voir comment les visiteurs se déplacent sur notre site web lorsqu'ils l'utilisent."
              },
              {
                "name": "Cookies de fonctionnalité",
                "purpose": "Ils sont utilisés pour vous reconnaître lorsque vous revenez sur notre site web. Cela nous permet de personnaliser notre contenu pour vous et de mémoriser vos préférences."
              },
              {
                "name": "Cookies de ciblage",
                "purpose": "Ces cookies enregistrent votre visite sur notre site web, les pages que vous avez visitées et les liens que vous avez suivis."
              }
            ]
          },
          "thirdPartyCookies": {
            "title": "4. Cookies tiers",
            "description": "En plus de nos propres cookies, nous pouvons également utiliser divers cookies tiers pour signaler les statistiques d'utilisation du Service, diffuser des publicités sur et via le Service, et ainsi de suite."
          },
          "specificCookies": {
            "title": "5. Quels cookies spécifiques utilisons-nous ?",
            "description": "Voici une liste des principaux cookies que nous utilisons sur vitadote.com :",
            "table": [
              { "type": "Cookie de session", "name": "PHPSESSID", "purpose": "Préserve l'état de session de l'utilisateur à travers les requêtes de pages", "duration": "Session" },
              { "type": "Cookie d'authentification", "name": "auth_token", "purpose": "Maintient l'utilisateur connecté", "duration": "30 jours" },
              { "type": "Cookie de panier d'achat", "name": "cart_items", "purpose": "Mémorise les articles dans le panier d'achat de l'utilisateur", "duration": "30 jours" },
              { "type": "Cookie de préférence de langue", "name": "site_language", "purpose": "Mémorise la préférence de langue de l'utilisateur", "duration": "1 an" },
              { "type": "Cookies Google Analytics", "name": "_ga, _gid, _gat", "purpose": "Utilisés pour distinguer les utilisateurs et limiter le taux de requêtes", "duration": "_ga : 2 ans, _gid : 24 heures, _gat : 1 minute" },
              { "type": "Cookie Facebook Pixel", "name": "_fbp", "purpose": "Utilisé par Facebook pour fournir une série de produits publicitaires", "duration": "3 mois" },
              { "type": "Cookie de consentement", "name": "cookie_consent", "purpose": "Mémorise si l'utilisateur a accepté la notification sur les cookies", "duration": "1 an" },
              { "type": "Cookie de recommandation de produits", "name": "recent_views", "purpose": "Stocke les produits récemment consultés pour les recommandations", "duration": "30 jours" },
              { "type": "Cookie de performance", "name": "page_load_time", "purpose": "Mesure les temps de chargement des pages pour aider à améliorer les performances du site web", "duration": "Session" },
              { "type": "Cookie de test A/B", "name": "ab_test_variant", "purpose": "Mémorise quelle version d'un test fractionné l'utilisateur a vue", "duration": "30 jours" }
            ],
            "description2": "Veuillez noter que les services tiers, tels que Google Analytics ou les plateformes de médias sociaux, peuvent définir des cookies supplémentaires. Pour plus d'informations sur ces cookies, veuillez consulter les politiques de confidentialité respectives de ces services.",
          },
          "choicesRegardingCookies": {
            "title": "6. Vos choix concernant les cookies",
            "description": [
              "Si vous souhaitez supprimer les cookies ou demander à votre navigateur web de les supprimer ou de les refuser, veuillez consulter les pages d'aide de votre navigateur web.",
              "Veuillez noter, cependant, que si vous supprimez les cookies ou refusez de les accepter, vous pourriez ne pas être en mesure d'utiliser toutes les fonctionnalités que nous offrons."
            ]
          },
          "changesToPolicy": {
            "title": "7. Modifications de notre Politique des Cookies",
            "description": "Nous pouvons mettre à jour notre Politique des Cookies de temps à autre. Nous vous informerons de tout changement en publiant la nouvelle Politique des Cookies sur cette page et en mettant à jour la date de \"Dernière mise à jour\" en haut de cette politique."
          },
          "contactUs": {
            "title": "8. Contactez-nous",
            "description": "Si vous avez des questions concernant notre utilisation des cookies ou cette Politique des Cookies, veuillez nous contacter à :",
            "email": "info@vitadote.com",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Pays-Bas",
            "lastUpdated": "Dernière mise à jour : 19 août 2024"
          }
        }
      },
      "termsAndConditions": {
        "heading": "Conditions Générales",
        "seo": {
          "metaDescription": "Conditions générales Vitadote® : vos droits et obligations lors de l'utilisation de notre site et services. Mis à jour le 19 août 2024."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Introduction",
            "description": "Bienvenue sur Vitadote.com. Les présentes Conditions Générales d'Utilisation (\"Conditions\") régissent votre utilisation du site web et des services Vitadote® (collectivement, le \"Service\") exploités par eCom IT BV (\"eCom IT\", \"nous\", \"notre\" ou \"nos\"). En accédant ou en utilisant le Service, vous acceptez d'être lié par ces Conditions. Si vous n'êtes pas d'accord avec une partie des Conditions, vous ne pouvez pas accéder au Service."
          },
          "definitions": {
            "title": "2. Définitions",
            "description": "\"Vous\" désigne la personne qui accède au Service ou l'utilise, ou la société ou autre entité juridique au nom de laquelle cette personne accède au Service ou l'utilise, selon le cas."
          },
          "accountRegistration": {
            "title": "3. Enregistrement du compte",
            "description": "Lorsque vous créez un compte chez nous, vous devez fournir des informations exactes, complètes et à jour. Le non-respect de cette obligation constitue une violation des Conditions, ce qui peut entraîner la résiliation immédiate de votre compte sur notre Service."
          },
          "useOfService": {
            "title": "4. Utilisation du Service",
            "description": "Vous acceptez d'utiliser le Service uniquement à des fins légales et conformément aux présentes Conditions. Vous acceptez de ne pas utiliser le Service :",
            "list": [
              "A. D'une manière qui viole toute loi ou réglementation nationale ou internationale applicable.",
              "B. Pour transmettre ou faire envoyer tout matériel publicitaire ou promotionnel, y compris tout \"courrier indésirable\", \"chaîne de lettres\", \"spam\" ou toute autre sollicitation similaire.",
              "C. Pour usurper l'identité ou tenter d'usurper l'identité d'eCom IT, d'un employé d'eCom IT, d'un autre utilisateur ou de toute autre personne ou entité.",
              "D. D'une manière qui porte atteinte aux droits d'autrui, ou qui est illégale, menaçante, frauduleuse ou nuisible de quelque manière que ce soit, ou en relation avec tout but ou activité illégal, illicite, frauduleux ou nuisible."
            ]
          },
          "intellectualProperty": {
            "title": "5. Propriété intellectuelle",
            "description": "Le Service et son contenu original, ses caractéristiques et ses fonctionnalités sont et resteront la propriété exclusive d'eCom IT et de ses concédants. Le Service est protégé par le droit d'auteur, les marques et d'autres lois des Pays-Bas et des pays étrangers. Nos marques et notre présentation commerciale ne peuvent pas être utilisées en relation avec un produit ou un service sans l'accord écrit préalable d'eCom IT."
          },
          "userContent": {
            "title": "6. Contenu de l'utilisateur",
            "description": "Notre Service peut vous permettre de publier, de lier, de stocker, de partager et de mettre à disposition d'une autre manière certaines informations, textes, graphiques, vidéos ou autres matériels (\"Contenu\"). Vous êtes responsable du Contenu que vous publiez sur ou via le Service, y compris de sa légalité, de sa fiabilité et de son caractère approprié."
          },
          "linksToOtherWebsites": {
            "title": "7. Liens vers d'autres sites web",
            "description": "Notre Service peut contenir des liens vers des sites web ou des services tiers qui ne sont pas détenus ou contrôlés par eCom IT. eCom IT n'a aucun contrôle sur le contenu, les politiques de confidentialité ou les pratiques des sites web ou services tiers et n'assume aucune responsabilité à cet égard. Vous reconnaissez et acceptez en outre qu'eCom IT ne sera pas responsable, directement ou indirectement, de tout dommage ou perte causé ou présumé avoir été causé par ou en relation avec l'utilisation ou la confiance accordée à tout contenu, bien ou service disponible sur ou via ces sites web ou services."
          },
          "termination": {
            "title": "8. Résiliation",
            "description": "Nous pouvons résilier ou suspendre votre compte immédiatement, sans préavis ni responsabilité, pour quelque raison que ce soit, y compris, sans limitation, si vous violez les Conditions. En cas de résiliation, votre droit d'utiliser le Service cessera immédiatement."
          },
          "limitationOfLiability": {
            "title": "9. Limitation de responsabilité",
            "description": "En aucun cas, eCom IT, ni ses directeurs, employés, partenaires, agents, fournisseurs ou affiliés, ne seront responsables de tout dommage indirect, accessoire, spécial, consécutif ou punitif, y compris, sans limitation, la perte de profits, de données, d'utilisation, de goodwill ou d'autres pertes immatérielles, résultant de votre accès ou de votre utilisation ou de votre incapacité à accéder ou à utiliser le Service."
          },
          "disclaimer": {
            "title": "10. Clause de non-responsabilité",
            "description": "Votre utilisation du Service est à vos propres risques. Le Service est fourni \"TEL QUEL\" et \"TEL QUE DISPONIBLE\". Le Service est fourni sans garantie d'aucune sorte, expresse ou implicite, y compris, mais sans s'y limiter, les garanties implicites de qualité marchande, d'adéquation à un usage particulier, de non-contrefaçon ou de performance."
          },
          "governingLaw": {
            "title": "11. Droit applicable",
            "description": "Les présentes Conditions sont régies et interprétées conformément aux lois des Pays-Bas, sans tenir compte de leurs dispositions en matière de conflit de lois."
          },
          "changes": {
            "title": "12. Modifications",
            "description": "Nous nous réservons le droit, à notre seule discrétion, de modifier ou de remplacer ces Conditions à tout moment. Si une révision est importante, nous essaierons de donner un préavis d'au moins 30 jours avant l'entrée en vigueur de nouvelles conditions. Ce qui constitue un changement important sera déterminé à notre seule discrétion."
          },
          "detailedDisclaimer": {
            "title": "13. Clause de non-responsabilité",
            "list": [
              "<b>Exactitude des informations :</b> Bien que nous nous efforcions de fournir des informations précises et à jour sur Vitadote.com, nous ne faisons aucune déclaration ou garantie d'aucune sorte, expresse ou implicite, quant à l'exhaustivité, l'exactitude, la fiabilité, la pertinence ou la disponibilité des informations, produits, services ou graphiques connexes contenus sur le site Web à quelque fin que ce soit.",
              "<b>Utilisation à vos propres risques :</b> Toute confiance que vous accordez à ces informations est donc strictement à vos propres risques. En aucun cas, nous ne serons responsables de toute perte ou dommage, y compris, sans limitation, les pertes ou dommages indirects ou consécutifs, ou toute perte ou dommage résultant de la perte de données ou de profits découlant de, ou en relation avec l'utilisation de ce site Web.",
              "<b>Clause de non-responsabilité médicale :</b> Les informations fournies sur Vitadote.com, y compris les détails sur le Shilajit et ses avantages potentiels, sont uniquement à titre informatif et ne sont pas destinées à remplacer les conseils de votre médecin ou d'un autre professionnel de santé. Vous ne devez pas utiliser les informations de ce site Web pour diagnostiquer ou traiter un problème de santé ou une maladie, ni pour prescrire un médicament ou un autre traitement.",
              "<b>Utilisation du produit :</b> Nos produits Shilajit sont des compléments alimentaires et ne sont pas destinés à diagnostiquer, traiter, guérir ou prévenir une maladie. Consultez toujours un professionnel de santé qualifié avant de commencer tout programme de régime, d'exercice ou de supplémentation, avant de prendre tout médicament, ou si vous avez ou pensez avoir un problème de santé.",
              "<b>Les résultats peuvent varier :</b> Les résultats décrits sur ce site Web concernant l'utilisation du Shilajit sont des expériences individuelles, reflétant les expériences réelles de ceux qui ont utilisé nos produits. Cependant, les résultats individuels peuvent varier. Nous ne prétendons pas qu'il s'agit de résultats typiques que les consommateurs obtiendront généralement.",
              "<b>Liens externes :</b> Par le biais de ce site Web, vous pouvez être en mesure de vous connecter à d'autres sites Web qui ne sont pas sous le contrôle de Vitadote.com. Nous n'avons aucun contrôle sur la nature, le contenu et la disponibilité de ces sites. L'inclusion de tout lien n'implique pas nécessairement une recommandation ou une approbation des opinions exprimées sur ces sites.",
              "<b>Disponibilité du site Web :</b> Tous les efforts sont faits pour maintenir le site Web en bon état de fonctionnement. Cependant, Vitadote.com n'assume aucune responsabilité et ne sera pas responsable de l'indisponibilité temporaire du site Web en raison de problèmes techniques indépendants de notre volonté.",
              "<b>Modifications de la clause de non-responsabilité :</b> Nous nous réservons le droit d'apporter des modifications à cette clause de non-responsabilité à tout moment. Toute modification sera publiée sur cette page avec une date de révision mise à jour."
            ],
            "note": "En utilisant Vitadote.com, vous signifiez votre accord avec cette clause de non-responsabilité. Si vous n'êtes pas d'accord avec cette clause de non-responsabilité, veuillez ne pas utiliser notre site Web."
          },
          "copyrightNotice": {
            "title": "14. Avis de droit d'auteur",
            "description1": "© 2024 Vitadote® / eCom IT BV. Tous droits réservés.",
            "description2": "Le contenu, la mise en page, la conception, les données, les bases de données et les graphiques de ce site Web sont protégés par les lois néerlandaises et autres lois internationales sur la propriété intellectuelle et appartiennent à eCom IT BV ou à ses concédants. Aucune partie de ce site Web ne peut être reproduite, distribuée ou transmise sous quelque forme ou par quelque moyen que ce soit, y compris la photocopie, l'enregistrement ou d'autres méthodes électroniques ou mécaniques, sans l'autorisation écrite préalable d'eCom IT BV, sauf dans le cas de brèves citations incorporées dans des critiques et certaines autres utilisations non commerciales autorisées par la loi sur le droit d'auteur."
          },
          "contactUs": {
            "title": "15. Nous contacter",
            "description": "Si vous avez des questions concernant ces Conditions, veuillez nous contacter à :",
            "email": "info@vitadote.com",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Pays-Bas",
            "lastUpdated": "Dernière mise à jour : 19 août 2024"
          }
        }
      },
      "paymentPolicy": {
        "heading": "Politique de Paiement",
        "seo": {
          "metaDescription": "Politique de paiement Vitadote® : méthodes sécurisées, devises, remboursements et paiements récurrents. Mise à jour le 19/08/2024."
        },
        "policyContent": {
          "paymentMethods": {
            "title": "Méthodes de paiement",
            "description": "Vous pouvez payer votre commande en utilisant les méthodes de paiement suivantes :",
            "list": [
              "American Express",
              "Apple Pay",
              "Google Pay",
              "iDEAL / Sofort",
              "Mastercard / Visa",
              "PayPal",
              "Shop Pay",
              "Union Pay"
            ],
            "description2": "Bien que nous n'acceptions pas directement d'autres cartes de crédit, PayPal offre la possibilité de lier la plupart des cartes de crédit courantes, vous permettant de payer avec ces cartes via PayPal sur notre site web."
          },
          "paymentSecurity": {
            "title": "Sécurité des paiements",
            "description": "Toutes les transactions sur notre site web sont traitées de manière sécurisée par notre prestataire de services de paiement, Mollie. Tous les processus via Mollie respectent le principe et les critères ISAE 3402 et sont certifiés selon les normes PCI DSS 3.2 Level 1 Service Provider. Cette certification est une norme de sécurité reconnue internationalement."
          },
          "orderVerification": {
            "title": "Vérification des commandes",
            "description": "Toutes les méthodes de paiement sont sécurisées et validées. Pour protéger nos clients, certaines commandes peuvent faire l'objet d'un processus de vérification de paiement. Si votre commande est mise en attente, nous vous contacterons rapidement pour minimiser tout retard potentiel dans votre commande."
          },
          "currency": {
            "title": "Devise",
            "description": "Lors de vos achats sur notre site web Vitadote.com, tous les paiements sont traités en euros (EUR)."
          },
          "refundsAndCancellations": {
            "title": "Remboursements et annulations",
            "description": "Pour obtenir des informations sur nos politiques de remboursement et d'annulation, veuillez consulter notre page "
          },
          "recurringPayments": {
            "title": "Paiements récurrents",
            "description": "Si vous avez souscrit à l'un de nos services impliquant des paiements récurrents, vous serez facturé automatiquement selon la fréquence que vous avez choisie (par exemple, mensuelle, annuelle). Vous pouvez annuler votre abonnement à tout moment en vous connectant à votre compte ou en contactant notre équipe de support client."
          },
          "pricingAndTax": {
            "title": "Prix et taxes",
            "description": "Tous les prix affichés sur notre site web sont en euros et incluent les taxes applicables. Tous les frais de douane ou d'importation supplémentaires pour les commandes internationales sont à la charge du client."
          },
          "paymentIssues": {
            "title": "Problèmes de paiement",
            "description": "Si vous rencontrez des problèmes avec votre paiement, veuillez vérifier les points suivants :",
            "list": [
              "Assurez-vous que vos informations de paiement sont correctement saisies.",
              "Vérifiez que votre mode de paiement dispose de fonds suffisants.",
              "Vérifiez si votre banque a bloqué la transaction pour des raisons de sécurité."
            ],
            "followUp": "Si vous continuez à rencontrer des problèmes, veuillez contacter notre équipe de support client."
          },
          "dataProtection": {
            "title": "Protection des données",
            "description": "Nous prenons très au sérieux la sécurité de vos données financières. Toutes les informations de paiement sont cryptées et traitées de manière sécurisée. Nous ne stockons pas les détails complets de votre carte de crédit sur nos serveurs. Pour plus d'informations, veuillez consulter notre "
          },
          "changesToThisPolicy": {
            "title": "Modifications de cette politique",
            "description": "Nous pouvons mettre à jour cette Politique de Paiement de temps en temps. Nous vous informerons de tout changement en publiant la nouvelle Politique de Paiement sur cette page et en mettant à jour la date de \"Dernière mise à jour\" ci-dessous."
          },
          "contactUs": {
            "title": "Contactez-nous",
            "description": "Si vous avez des questions concernant les paiements, veuillez nous contacter à :",
            "email": "info@vitadote.com",
            "phone": "+31 20 244 3918",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Pays-Bas",
            "lastUpdated": "Dernière mise à jour : 19 août 2024"
          }
        }
      },
      "refundPolicy": {
        "heading": "Politique de Remboursement",
        "seo": {
          "metaDescription": "Découvrez Vitadote®s unique \"Behalte-Es\" retour garantie. 30 jours de garantie de satisfaction sur tous les achats. Lisez notre politique client."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Introduction",
            "description": "Chez Vitadote®, votre satisfaction est notre priorité absolue. Nous sommes tellement confiants dans la qualité de nos produits que nous offrons une politique de remboursement unique et favorable au client. Cette politique de remboursement décrit les conditions de remboursement des produits achetés sur notre site web vitadote.com."
          },
          "keepItRefundGuarantee": {
            "title": "2. Notre Garantie de Remboursement \"Gardez-le\"",
            "description": "Si vous n'êtes pas entièrement satisfait de votre achat, vous pouvez demander un remboursement dans les 30 jours suivant la livraison - et vous gardez le produit. Nous faisons confiance à nos clients et apprécions vos commentaires, qui nous aident à améliorer nos produits."
          },
          "refundPeriod": {
            "title": "3. Période de Remboursement",
            "description": "Vous avez 30 jours à compter de la date de livraison pour demander un remboursement si vous n'êtes pas satisfait de votre achat."
          },
          "refundEligibility": {
            "title": "4. Éligibilité au Remboursement",
            "description": "Pour être éligible à notre remboursement \"Gardez-le\" :",
            "list": [
              "Vous devez demander le remboursement dans les 30 jours suivant la livraison.",
              "Vous devez fournir une brève explication de la raison pour laquelle vous n'êtes pas satisfait du produit."
            ]
          },
          "refundProcess": {
            "title": "5. Processus de Remboursement",
            "description": "Pour initier un remboursement, veuillez suivre ces étapes :",
            "list": [
              "Contactez notre équipe du service client à <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a> dans les 30 jours suivant la livraison.",
              "Fournissez votre numéro de commande et une brève explication de la raison pour laquelle vous n'êtes pas satisfait du produit.",
              "Notre équipe examinera votre demande et traitera votre remboursement."
            ]
          },
          "refundMethod": {
            "title": "6. Méthode de Remboursement",
            "description": "Une fois votre remboursement approuvé, un crédit sera automatiquement appliqué à votre mode de paiement d'origine dans un délai de 10 jours ouvrables."
          },
          "excessiveClaims": {
            "title": "7. Réclamations Excessives",
            "description": "Bien que nous fassions confiance à nos clients, nous nous réservons le droit de refuser les remboursements en cas de réclamations excessives ou abusives."
          },
          "damagedOrDefectiveItems": {
            "title": "8. Articles Endommagés ou Défectueux",
            "description": "Si vous recevez un article endommagé ou défectueux, veuillez nous contacter immédiatement à <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a>. Nous vous enverrons un remplacement sans frais supplémentaires."
          },
          "lateOrMissingRefunds": {
            "title": "9. Remboursements Tardifs ou Manquants",
            "description": "Si vous n'avez pas encore reçu votre remboursement, vérifiez d'abord à nouveau votre compte bancaire. Ensuite, contactez votre société de carte de crédit, il peut s'écouler un certain temps avant que votre remboursement ne soit officiellement affiché. Si vous avez fait tout cela et que vous n'avez toujours pas reçu votre remboursement, veuillez nous contacter à <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a>."
          },
          "changesToThisPolicy": {
            "title": "10. Modifications de cette Politique",
            "description": "Nous nous réservons le droit de modifier cette politique de remboursement à tout moment. Les changements et clarifications prendront effet immédiatement après leur publication sur le site web."
          },
          "contactUs": {
            "title": "11. Contactez-nous",
            "description": "Si vous avez des questions concernant notre politique de remboursement, veuillez nous contacter :",
            "email": "info@vitadote.com",
            "phone": "+31 20 244 3918",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Pays-Bas",
            "lastUpdated": "Dernière mise à jour : 19 août 2024"
          }
        }
      },
      "shippingPolicy": {
        "heading": "Politique d'Expédition",
        "seo": {
          "metaDescription": "Découvrez la politique d'expédition de Vitadote®. Livraison gratuite mondiale, rapide et délais clairs. Informez-vous sur nos pratiques d'expédition et douane."
        },
        "policyContent": {
          "introduction": {
            "title": "1. Introduction",
            "description": "Chez Vitadote.com, nous expédions nos produits de haute qualité depuis les Pays-Bas en utilisant des méthodes d'expédition sécurisées avec suivi. Cette politique décrit nos pratiques d'expédition, les délais de livraison et les informations connexes."
          },
          "freeShipping": {
            "title": "2. Livraison gratuite",
            "description": "Nous offrons la livraison gratuite sur toutes les commandes ! Veuillez noter que la livraison gratuite n'inclut pas les éventuels frais de douane."
          },
          "shippingAddress": {
            "title": "3. Adresse de livraison",
            "description": "<strong>Important :</strong> Nous ne livrons pas aux boîtes postales. Veuillez fournir une adresse de livraison physique."
          },
          "orderProcessing": {
            "title": "4. Traitement des commandes",
            "description": "Les commandes sont traitées dans un délai de 1 à 2 jours ouvrables avant d'être expédiées. Les délais de livraison indiqués ci-dessous sont des estimations à partir de la date d'expédition."
          },
          "deliveryTimes": {
            "title": "5. Délais de livraison",
            "description": "",
            "list": [
              "<strong>Pays-Bas et Belgique :</strong> Pour les commandes passées les jours ouvrables avant 16h00, votre produit Vitadote sera livré le jour ouvrable suivant.",
              "<strong>Allemagne :</strong> Pour les commandes passées les jours ouvrables avant 16h00, la livraison a généralement lieu le jour ouvrable suivant. Dans certaines régions, cela peut prendre 1 à 2 jours de plus en raison des circonstances logistiques.",
              "<strong>Autres pays de l'UE :</strong> Pour les commandes passées les jours ouvrables avant 16h00, la livraison a lieu dans les 2 à 4 jours ouvrables.",
              "<strong>Hors UE :</strong> Pour les commandes passées les jours ouvrables avant 16h00, la livraison a lieu dans les 5 à 14 jours, selon les connexions aériennes.",
              "<strong>Asie :</strong> Livraison bientôt disponible."
            ],
            "note": "<em>Veuillez noter :</em> Nous n'expédions que les jours ouvrables (du lundi au vendredi, hors jours fériés). Les commandes reçues pendant le week-end seront traitées le jour ouvrable suivant."
          },
          "damagedDeliveries": {
            "title": "6. Livraisons endommagées",
            "description": "Si votre colis Vitadote arrive endommagé, veuillez nous en informer immédiatement par e-mail à <a href=\"mailto:info@vitadote.com\">info@vitadote.com</a>."
          },
          "shippingCostsAndDeliveryTimes": {
            "title": "7. Frais d'expédition et délais de livraison par pays",
            "description": "",
            "table": {
              "headers": ["Pays/Région", "Frais d'expédition", "Délai de livraison"],
              "rows": [
                ["Pays-Bas", "GRATUIT", "2 jours"],
                ["Belgique", "GRATUIT", "2-5 jours"],
                ["Allemagne", "GRATUIT", "2-5 jours"],
                ["Royaume-Uni", "GRATUIT", "2-5 jours"],
                ["France", "GRATUIT", "2-5 jours"],
                ["Espagne", "GRATUIT", "7-10 jours"],
                ["Italie", "GRATUIT", "7-10 jours"],
                ["Autriche", "GRATUIT", "2-5 jours"],
                ["Pologne", "GRATUIT", "2-5 jours"],
                ["Danemark", "GRATUIT", "2-5 jours"],
                ["Suède", "GRATUIT", "2-5 jours"],
                ["Finlande", "GRATUIT", "2-5 jours"],
                ["Irlande", "GRATUIT", "2-5 jours"],
                ["Portugal", "GRATUIT", "5-7 jours"],
                ["Grèce", "GRATUIT", "7-10 jours"],
                ["République tchèque", "GRATUIT", "2-5 jours"],
                ["Hongrie", "GRATUIT", "2-5 jours"],
                ["Roumanie", "GRATUIT", "4-7 jours"],
                ["Bulgarie", "GRATUIT", "8-10 jours"],
                ["Suisse", "GRATUIT", "2-5 jours"],
                ["Norvège", "GRATUIT", "2-5 jours"]
              ]
            },
            "additionalInfo": "Les délais de livraison vers d'autres pays non listés ci-dessus sont généralement de 20 à 30 jours. Toutes les expéditions sont gratuites."
          },
          "customs": {
            "title": "8. Douanes",
            "description": "Les frais de douane, taxes ou autres charges ne sont pas inclus dans le prix.\n\nPour les commandes vers des pays hors UE, des frais supplémentaires peuvent s'appliquer à l'arrivée. Malheureusement, nous ne pouvons pas prédire le montant de ces coûts. Veuillez contacter votre bureau de douane local pour des informations plus précises."
          },
          "brexitNotice": {
            "title": "9. Avis Brexit pour les commandes au Royaume-Uni",
            "description": "En raison du Brexit, des droits de douane et des frais de dédouanement supplémentaires seront facturés pour toutes les commandes au Royaume-Uni d'une valeur totale de 150 € ou plus (selon le taux de change en vigueur). Veuillez noter que la valeur des marchandises évaluée par les douanes britanniques peut être supérieure à notre prix de vente, car la valeur originale de chaque produit est utilisée comme base."
          },
          "contactUs": {
            "title": "10. Contactez-nous",
            "description": "Si vous avez des questions concernant notre politique d'expédition, veuillez nous contacter à :",
            "email": "info@vitadote.com",
            "phone": "+31 20 244 3918",
            "address": "Vitadote® / eCom IT BV, Albert Huismanpad 166, 1502EK Zaandam, Pays-Bas",
            "lastUpdated": "Dernière mise à jour : 19 août 2024"
          }
        }
      }

    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
