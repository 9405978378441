const routes = {
  en: {
    home: '/',
    about: '/about-us/',
    contact: '/contact',
    products1: '/products/1x-pure-shilajit-resin/',
    products2: '/products/2x-pure-shilajit-resin/',
    products3: '/products/3x-pure-shilajit-resin/',
    privacyPolicy: '/privacy-policy',
    termAndConditions: '/terms-and-conditions',
    cookiePolicy: '/cookie-policy',
    paymentPolicy: '/payment-policy',
    refundPolicy: '/refund-policy',
    shippingPolicy: '/shipping-policy',
    becomeReseller : '/become-reseller/',
    faq: '/faq'
  },
  nl: {
    home: '/nl',
    about: '/nl/over-ons/',
    contact: '/nl/contact',
    products1: '/nl/producten/1x-pure-shilajit-resin/',
    products2: '/nl/producten/2x-pure-shilajit-resin/',
    products3: '/nl/producten/3x-pure-shilajit-resin/',
    cookiePolicy: '/nl/cookiebeleid',
    privacyPolicy: '/nl/privacybeleid',
    termAndConditions: '/nl/algemene-voorwaarden',
    paymentPolicy: '/nl/betalingsbeleid',
    refundPolicy: '/nl/terugbetalingsbeleid',
    shippingPolicy: '/nl/verzendbeleid',
    becomeReseller : '/nl/word-reseller/',
    faq: '/nl/veelgestelde-vragen'

  },
  es: {
    home: '/es',
    about: '/es/sobre-nosotros/',
    contact: '/es/contacto',
    products1: '/es/productos/1x-resina-pura-de-shilajit/',
    products2: '/es/productos/2x-resina-pura-de-shilajit/',
    products3: '/es/productos/3x-resina-pura-de-shilajit/',
    cookiePolicy: '/es/politica-de-cookies',
    privacyPolicy: '/es/politica-de-privacidad',
    termAndConditions: '/es/terminos-y-condiciones',
    paymentPolicy: '/es/politica-de-pagos',
    refundPolicy: '/es/politica-de-reembolso',
    shippingPolicy: '/es/politica-de-envio',
    becomeReseller : '/es/conviertase-en-revendedor/',
    faq: '/es/preguntas-frecuentes'
  },
  ptbr: {
    home: '/pt-br',
    about: '/pt-br/sobre-nos/',
    contact: '/pt-br/contato',
    products1: '/pt-br/produtos/1x-resina-pura-de-shilajit/',
    products2: '/pt-br/produtos/2x-resina-pura-de-shilajit/',
    products3: '/pt-br/produtos/3x-resina-pura-de-shilajit/',
    cookiePolicy: '/pt-br/politica-de-cookies',
    privacyPolicy: '/pt-br/politica-de-privacidade',
    termAndConditions: '/pt-br/termos-e-condicoes',
    paymentPolicy: '/pt-br/politica-de-pagamento',
    refundPolicy: '/pt-br/politica-de-reembolso',
    shippingPolicy: '/pt-br/politica-de-envio',
    becomeReseller : '/pt-br/torne-se-revendedor/',
    faq: '/pt-br/perguntas-frequentes'
  },
  de: {
    home: '/de',
    about: '/de/ueber-uns/',
    contact: '/de/kontakt',
    products1: '/de/produkte/1x-reines-shilajit-harz/',
    products2: '/de/produkte/2x-reines-shilajit-harz/',
    products3: '/de/produkte/3x-reines-shilajit-harz/',
    cookiePolicy: '/de/cookie-richtlinie',
    privacyPolicy: '/de/datenschutzrichtlinie',
    termAndConditions: '/de/allgemeine-geschaeftsbedingungen',
    paymentPolicy: '/de/zahlungsrichtlinieleid',
    refundPolicy: '/de/rueckerstattungsrichtlinie',
    shippingPolicy: '/de/versandrichtlinie',
    becomeReseller : '/de/wiederverkaeufer-werden/',
    faq: '/de/haeufige-fragen'

  },
  fr: {
    home: '/fr',
    about: '/fr/a-propos/',
    contact: '/fr/contact',
    products1: '/fr/produits/1x-resine-de-shilajit-pure/',
    products2: '/fr/produits/2x-resine-de-shilajit-pure/',
    products3: '/fr/produits/3x-resine-de-shilajit-pure/',
    cookiePolicy: '/fr/politique-de-cookies',
    privacyPolicy: '/fr/politique-de-confidentialite',
    termAndConditions: '/fr/conditions-generales',
    paymentPolicy: '/fr/politique-de-paiement',
    refundPolicy: '/fr/politique-de-remboursement',
    shippingPolicy: '/fr/politique-expedition',
    becomeReseller : '/fr/devenir-revendeur/',
    faq: '/fr/faq'
  },
};

export default routes;
